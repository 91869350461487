import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Col,
  Divider,
  Image,
  Input,
  message,
  Popover,
  Row,
  Skeleton,
  Typography,
} from "antd";
import React, { useState } from "react";
import { PlusIcon, primaryColor } from "../../../../../../../../../../config";
import { MakeApiCall } from "../../../../../../../../../../apis";
const { Paragraph } = Typography;
const SearchItem = (props) => {
  const {
    marketplaceId,
    productList,
    setProductList,
    selectedNegativeProductList,
    setSelectedNegativeProductList,
  } = props;
  const [searchByAsin, setSearchByAsin] = useState("");
  const [productLoading, setProductLoading] = useState(false);

  const GetAutoNegativeProduct = async (data) => {
    const response = await MakeApiCall(
      `advertising/check-product-targeting-by-asin`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      setProductLoading(false);
      setProductList(response?.data?.records);
    } else {
      setProductLoading(false);
      message.destroy();
      message.error(response?.message);
    }
  };

  return (
    <Row>
      <Col span={24} className="d-flex">
        <Input
          value={searchByAsin}
          onChange={(e) => {
            setSearchByAsin(e.target.value);
          }}
          onPressEnter={(e) => {
            const data = {
              marketplace_id: marketplaceId,
              product_asins: [],
              asins: [searchByAsin?.trim()],
            };
            setProductLoading(true);
            GetAutoNegativeProduct(data);
          }}
        />
      </Col>
      <Col span={24} className="mt-3">
        <Row style={{ height: "200px", overflow: "auto" }} gutter={16}>
          {productLoading
            ? [...Array(9)]?.map((d) => {
                return (
                  <Col span={24} className="mb-2">
                    <Skeleton.Button block active />
                  </Col>
                );
              })
            : productList?.map((d) => {
                const isProductAdded = selectedNegativeProductList?.filter(
                  (f) => f?.expression_value === d?.asin
                );
                console.log(
                  isProductAdded,
                  selectedNegativeProductList,
                  "-isProductAdded"
                );
                return (
                  <Col span={24}>
                    <div className="d-flex justify-content-between">
                      {" "}
                      <div className="d-flex align-items-center">
                        <Image src={d?.image_url} style={{ width: "45px" }} />
                        <div className="ms-2">
                          <Popover
                            content={
                              <div
                                style={{
                                  height: "max-content",
                                  maxWidth: "400px",
                                }}
                              >
                                {d?.product_name}
                              </div>
                            }
                            placement="bottomLeft"
                          >
                            <div className="singleLineEllipsis">
                              <Paragraph className="mb-0">
                                {d?.product_name}
                              </Paragraph>
                            </div>
                          </Popover>

                          <div className="mt-1">
                            <label>
                              ASIN:{" "}
                              <span className="text-primary">{d?.asin} </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        {isProductAdded?.length == 0 && (
                          <div
                            onClick={() => {
                              setSelectedNegativeProductList([
                                ...selectedNegativeProductList,
                                {
                                  id: d?.id,
                                  expression_type: "ASIN_SAME_AS",
                                  state: "ENABLED",
                                  expression_value: d?.asin,
                                  name: d?.product_name,
                                  image_url: d?.image_url,
                                  type: "products",
                                },
                              ]);
                            }}
                          >
                            <PlusIcon />
                          </div>
                        )}
                      </div>
                    </div>

                    <Divider variant="dashed" className="my-2" />
                  </Col>
                );
              })}
        </Row>
      </Col>
    </Row>
  );
};

export default SearchItem;
