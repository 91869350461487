import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Col,
  DatePicker,
  Image,
  Input,
  message,
  Popover,
  Row,
  Select,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import Table2 from "../../../../components/tables/table2";
import {
  AddCommas,
  ConvertParams,
  DateFormate,
  DefaultPerPage,
  DownloadFileUsingApi,
  ENDPOINT,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  PaginationConfig,
  primaryColor,
} from "../../../../config";
import CampaignModal from "./lib/campaign-modal";

const AdvertisementProductAnalytics = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [date_range, set_date_range] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });

  const getList = async (data) => {
    const response = await MakeApiCall(
      `product-analytics/get-product-list?page=${
        data?.page || page || 1
      }&per-page=${data?.pageSize || pageSize}&marketplace_id=${
        data?.marketplace_id
      }&search=${data?.searchKey}&start_date=${data?.start_date}&end_date=${
        data?.end_date
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      getList({
        page: page,
        pageSize: pageSize,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        searchKey: searchKey,
        start_date: dayjs(date_range?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: dayjs(date_range?.end_date?.$d).format("YYYY-MM-DD"),
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    GetMarketplace();

    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      width: 80,
      fixed: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Product",
      dataIndex: "item_name",
      key: "item_name",
      width: 400,
      render: (text, record) => {
        return (
          <div
            style={{
              border: "1px dashed #d9d9d9",
              borderRadius: "8px",
              padding: "5px",
              maxWidth: "400px",
              background: "#fff",
            }}
          >
            <Row align="middle">
              {/* Image Section */}
              <Col span={5}>
                <Image
                  width={50}
                  height={50}
                  src={record?.image_url}
                  alt="Product"
                  style={{
                    borderRadius: "4px",
                  }}
                  preview={false} // Disable the preview functionality
                />
              </Col>

              {/* Text Section */}
              <Col span={18}>
                <Popover
                  content={
                    <div
                      style={{
                        height: "max-content",
                        maxWidth: "300px",
                      }}
                    >
                      {record?.item_name}
                    </div>
                  }
                  placement="bottom"
                >
                  <div>
                    <span
                      className="singleLineEllipsis"
                      style={{ width: "270px" }}
                    >
                      {record?.item_name}
                    </span>
                  </div>
                </Popover>

                <div style={{ marginTop: "8px" }}>
                  <strong>ASIN:</strong>{" "}
                  <span className="text-primary">{record?.asin1}</span> <br />
                  {/* <Divider type="vertical" /> */}
                  <strong>SKU:</strong>{" "}
                  <span className="text-primary">{record?.seller_sku}</span>
                </div>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={
            status === "Active"
              ? "success"
              : status === "Inactive"
              ? "error"
              : "orange"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Fulfillment Channel",
      dataIndex: "fulfillment_channel",
      key: "fulfillment_channel",

      render: (text) => {
        return (
          <Tag
            className="fw-bold"
            color={text === "DEFAULT" ? "orange" : "purple"}
          >
            {text === "DEFAULT" ? "FBM" : "FBA"}
          </Tag>
        );
      },
    },
    {
      title: "Product Id",
      dataIndex: "product_id",
      key: "product_id",
    },
    {
      title: "Sales Price",
      dataIndex: "price",
      key: "price",

      render: (price) => (
        <div>
          <span className="fw-bold">
            {selected_currency_sign}
            {AddCommas(price)}
          </span>
        </div>
      ),
    },
    {
      title: "Stock Quantity",
      dataIndex: "stock_quantity",
      key: "stock_quantity",

      render: (text) => (
        <div>
          <span className="fw-bold">{text}</span>
        </div>
      ),
    },
    {
      title: "Sales Rank",
      dataIndex: "sales_rank",
      key: "sales_rank",
      //   sorter: (a, b) => a.salesRank - b.salesRank, // Optional: sorting by sales rank
    },
    // {
    //   title: "Organic Sales",
    //   dataIndex: "organicSales",
    //   key: "organicSales",
    //
    //   //   render: (text) => `$${text.toLocaleString()}`, // Format as currency
    // },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (data) => (
        <div>
          <span className="">
            {selected_currency_sign}
            {AddCommas(data)}
          </span>
        </div>
      ),
      //   render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
    {
      title: "Impression",
      dataIndex: "impressions",
      key: "impressions",
    },
    {
      title: "Ads Unit Sold",
      dataIndex: "ads_unit_sold",
      key: "ads_unit_sold",
    },
    {
      title: "Ads sales ",
      dataIndex: "ads_sales",
      key: "ads_sales",
      render: (data) => (
        <div>
          <span className="">
            {selected_currency_sign}
            {AddCommas(data)}
          </span>
        </div>
      ),
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (text) => {
        return <span> {text || "0"}</span>;
      },
      //   render: (text) => `$${text.toFixed(2)}`, // Format CPC to two decimal places
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
      //   render: (text) => `${text}%`, // Format as percentage
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      render: (data) => (
        <div>
          <span className="">{AddCommas(data)}%</span>
        </div>
      ),
      //   render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Unit Sold",
      dataIndex: "units_sold",
      key: "units_sold",
      render: (data) => (
        <div>
          <span className="">{AddCommas(data)}</span>
        </div>
      ),
    },
    {
      title: "Organic Sales",
      dataIndex: "organic_sales",
      key: "organic_sales",
      render: (data) => (
        <div>
          <span className="">
            {selected_currency_sign}
            {AddCommas(data)}
          </span>
        </div>
      ),
    },
    {
      title: "TACoS",
      dataIndex: "TACoS",
      key: "TACoS",
      render: (data) => (
        <div>
          <span className="">{AddCommas(data)}%</span>
        </div>
      ),
    },
    // {
    //   title: "Ratings",
    //   dataIndex: "ratings",
    //   key: "ratings",
    // },
    // {
    //   title: "Reviews",
    //   dataIndex: "reviews",
    //   key: "reviews",
    // },

    {
      title: "Campaigns",
      render: (text, record) => {
        return (
          <div
          // style={{
          //   border: "1px dashed #d9d9d9",
          //   borderRadius: "8px",
          //   padding: "5px",
          //   maxWidth: "400px",
          //   background: "#fff",
          // }}
          >
            <Row align="middle">
              <Col span={24}>
                <div className="d-flex align-items-center">
                  {" "}
                  {text?.campaign_name?.length > 0 && (
                    <Icon
                      icon="solar:eye-broken"
                      style={{ color: primaryColor }}
                      width={24}
                      className="me-2"
                      onClick={() => {
                        setSelectedCampaign(text?.campaign_name || []);
                        setCampaignView(true);
                      }}
                    />
                  )}{" "}
                  <Popover
                    content={
                      <div
                        style={{
                          height: "max-content",
                          maxWidth: "200px",
                        }}
                      >
                        {record?.campaign_name?.[0]?.campaign_name}
                      </div>
                    }
                    placement="bottom"
                  >
                    <div>
                      <span
                        className="singleLineEllipsis"
                        style={{ width: "170px" }}
                      >
                        {record?.campaign_name?.[0]?.campaign_name}
                      </span>
                    </div>
                  </Popover>
                </div>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },

    {
      title: "Description",
      dataIndex: "item_description",
      key: "item_description",
      render: (text) => {
        return !text ? (
          "-"
        ) : (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "600px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span
              className="text-dark mb-1 fw-bolder singleLineEllipsis fs-6"
              style={{ width: "150px" }}
            >
              {text || "-"}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => (a.created_at || 0) - (b.created_at || 0),
      render: (e) => {
        return (
          <>{dayjs(new Date(e * 1000)).format(`${DateFormate} hh:mm A`)}</>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at || 0 - b.updated_at || 0,
      render: (e) => {
        return (
          <>{dayjs(new Date(e * 1000)).format(`${DateFormate} hh:mm A`)}</>
        );
      },
    },
  ];

  const ClearSearch = async () => {
    setLoading(true);
    setSearchKey("");
    setPage(1);
    setPageSize(DefaultPerPage);
    getList({
      page: 1,
      pageSize: DefaultPerPage,
      marketplace_id: selected_marketplace,
      searchKey: "",
      start_date: dayjs(date_range?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: dayjs(date_range?.end_date?.$d).format("YYYY-MM-DD"),
    });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <div className="d-flex flex-wrap gap-4 align-items-center mb-3 justify-content-end">
            <div className="position-relative">
              <Input
                onChange={(e) => {
                  setSearchKey((prev) => {
                    if (!e.target.value && prev) {
                      ClearSearch();
                    }
                    return e.target.value;
                  });
                }}
                onPressEnter={(e) => {
                  setLoading(true);
                  setPage(1);
                  setPageSize(DefaultPerPage);
                  getList({
                    page: 1,
                    "per-page": DefaultPerPage,
                    searchKey: e.target.value,
                    marketplace_id: selected_marketplace,
                    start_date: dayjs(date_range?.start_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(date_range?.end_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                  });
                }}
                value={searchKey}
                placeholder="Search by name"
                prefix={<Icon icon="clarity:search-line" />}
              />
            </div>
            <div className="position-relative me-2">
              <DatePicker.RangePicker
                className="ant_common_input w-250px"
                id="aalacart_date_picker"
                size="middle"
                allowClear={false}
                format={DateFormate}
                value={[date_range?.start_date, date_range?.end_date]}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(e) => {
                  const filters_ = date_range;
                  filters_.start_date = e?.[0];
                  filters_.end_date = e?.[1];
                  set_date_range({ ...filters_ });
                  setPage(1);
                  setPageSize(DefaultPerPage);
                  const apiObj_ = {
                    page: 1,
                    "per-page": DefaultPerPage,
                    searchKey: searchKey,
                    marketplace_id: selected_marketplace,
                    start_date: dayjs(e?.[0]?.$d).format("YYYY-MM-DD"),
                    end_date: dayjs(e?.[1]?.$d).format("YYYY-MM-DD"),
                  };
                  setLoading(true);
                  getList(apiObj_);
                }}
              />
            </div>{" "}
            <div className="position-relative me-2">
              <Select
                className="w-200px"
                placeholder="Select Marketplace"
                options={GetMarketplaceList(marketplace_list)}
                value={selected_marketplace}
                onChange={(e) => {
                  set_selected_marketplace(e);
                  set_selected_currency_sign(currency_list[e]);
                  setPage(1);
                  setPageSize(DefaultPerPage);
                  const apiObj_ = {
                    page: 1,
                    "per-page": DefaultPerPage,
                    searchKey: searchKey,
                    marketplace_id: e,
                    start_date: dayjs(date_range?.start_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(date_range?.end_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                  };
                  setLoading(true);
                  getList(apiObj_);
                }}
              />
            </div>
            <div className="position-relative ">
              <Button
                type="primary"
                onClick={() => {
                  const data = {
                    start_date: dayjs(date_range?.start_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(date_range?.end_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    marketplace_id: selected_marketplace,
                  };
                  message.loading("Loading...", 0);
                  DownloadFileUsingApi(
                    `${ENDPOINT}product-analytics/export-product${ConvertParams(
                      data
                    )}`,
                    "product_analytics"
                  );
                }}
              >
                Export
              </Button>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="card card-shadow  mb-5 mb-xl-8">
            <div className="card-body py-2 px-4 main-box">
              <Table2
                columns={columns}
                pagination={PaginationConfig({
                  pageSize,
                  page,
                  totalPage,
                  onChange: (e, f) => {
                    setLoading(true);
                    setPageSize(f);
                    setPage(e);
                    getList({
                      page: e,
                      pageSize: f,
                      searchKey: searchKey,
                      marketplace_id: selected_marketplace,
                      start_date: dayjs(date_range?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: dayjs(date_range?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    });
                  },
                })}
                dataSource={list}
                loading={loading}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>
        </Col>
      </Row>

      {campaignView && (
        <CampaignModal
          selectedCamp={selectedCampaign}
          show={campaignView}
          onHide={() => setCampaignView(false)}
        />
      )}
    </>
  );
};

export default AdvertisementProductAnalytics;
