import React, { useState } from "react";
import { Wrapper } from "./style";
import { Col, Row, Tabs } from "antd";

const KeywordTargeting = () => {
  const [selectedTab, setSelectedTab] = useState("searchterm_as_keyword");
  return (
    <Wrapper>
      <Row>
        <Col span={24} className="d-flex justify-content-start">
          <Tabs
            tabBarStyle={{ marginBottom: 0, padding: "0px 15px" }}
            type="card"
            items={[
              {
                key: "searchterm_as_keyword",
                label: "Searchterm as Keyword",
              },
              {
                key: "negative_keyword_targeting",
                label: "Negative Keyword Targeting",
              },
            ]}
            onChange={(key) => {
              setSelectedTab(key);
            }}
            defaultActiveKey="searchterm_as_keyword"
          />
        </Col>
      </Row>
      {selectedTab === "searchterm_as_keyword" && <></>}
      {selectedTab === "negative_keyword_targeting" && <></>}
    </Wrapper>
  );
};

export default KeywordTargeting;
