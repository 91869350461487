import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import Table2 from "../../../../components/tables/table2";

import {
  ConvertParams,
  DefaultPerPage,
  DeleteIcon,
  DisplayNotification,
  EditIcon,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  PaginationConfig,
  PlusIcon,
  ViewIcon,
} from "../../../../config";
import AddManager from "./lib/add-manager";

const ReimbursementManager = (props) => {
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [list, setList] = useState([]);
  const [isOpenManagerModal, setIsOpenManagerModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    name: "",
    marketplace_id: null,
  });

  useEffect(() => {
    // GetMarketplace();
    GetManagerList({
      ...all_filters,
    });
  }, []);

  // const GetMarketplace = async () => {
  //   const marketplace = await GetMarketplaceDropdown();
  //   if (marketplace?.marketplace_list?.length > 0) {
  //     set_marketplace_list(marketplace?.marketplace_list);
  //     set_selected_marketplace(
  //       marketplace?.marketplace_list[0]?.marketplace_id
  //     );
  //     set_currency_list(marketplace?.currency_list);
  //     set_selected_currency_sign(
  //       marketplace?.currency_list[
  //         marketplace?.marketplace_list[0]?.marketplace_id
  //       ]
  //     );
  //     set_all_filters({
  //       ...all_filters,
  //       marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
  //     });
  //     GetCampaignList(marketplace?.marketplace_list[0]?.marketplace_id);
  //     GetManagerList({
  //       ...all_filters,
  //       marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
  //     });
  //   } else {
  //     setLoading(false);
  //   }
  // };

  const GetManagerList = async (filters) => {
    const response = await MakeApiCall(
      `manager${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setList(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const DeleteManagerAction = async (id) => {
    const response = await MakeApiCall(`manager/${id}`, `DELETE`, null, true);
    if (response?.status) {
      setLoading(true);
      message.destroy();
      GetManagerList(all_filters);
      DisplayNotification("success", "Success", response?.message);
    } else {
      setLoading(true);
      message.destroy();
      GetManagerList(all_filters);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const columns = [
    {
      title: "#",
      width: 80,
      fixed: "left",
      render: (_, __, i) => {
        return (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + i}
          </span>
        );
      },
    },

    {
      title: "Rule Name",
      width: 250,
      render: (text) => {
        return <Typography.Text strong>{text?.name || "-"}</Typography.Text>;
      },
    },

    {
      title: "Created at",
      width: 150,
      render: (text) => {
        if (!text?.created_at) {
          return "-";
        }
        return (
          <span>
            {dayjs(parseInt(text?.created_at) * 1000).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Updated at",
      width: 150,
      render: (text) => {
        if (!text?.updated_at) {
          return "-";
        }
        return (
          <span>
            {dayjs(parseInt(text?.updated_at) * 1000).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Action",
      width: 150,
      render: (text) => (
        <>
          <span
            className="me-2"
            onClick={() => {
              setIsOpenManagerModal(true);
              setSelectedRow(text);
            }}
          >
            <EditIcon />
          </span>
          <Popconfirm
            title="Delete Manager"
            description="Are you sure to delete this manager?"
            onConfirm={() => {
              DeleteManagerAction(text?.id);
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <span>
              <DeleteIcon />
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <div className="d-flex flex-wrap gap-4 align-items-center mb-3 justify-content-end">
              <div className="position-relative">
                <Input
                  style={{ width: "250px" }}
                  prefix={
                    <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                  }
                  placeholder="Search by Rule name"
                  value={all_filters?.name}
                  onChange={(e) => {
                    set_all_filters({
                      ...all_filters,
                      name: e.target.value,
                    });
                  }}
                  onPressEnter={(e) => {
                    const apiObj = {
                      ...all_filters,
                      page: 1,
                      "per-page": 20,
                    };
                    setLoading(true);
                    GetManagerList(apiObj);
                  }}
                />
              </div>

              <div className="position-relative ">
                {/* <Select
                  className="w-200px"
                  placeholder="Select Marketplace"
                  options={GetMarketplaceList(marketplace_list)}
                  value={selected_marketplace}
                  onChange={(e) => {
                    set_selected_marketplace(e);
                    set_selected_currency_sign(currency_list[e]);
                    const apiObj = {
                      ...all_filters,
                      marketplace_id: e,
                    };
                    set_all_filters(apiObj);

                    const apiObj_ = {
                      ...apiObj,
                      page: 1,
                      "per-page": 20,
                    };
                    setLoading(true);
                    GetManagerList(apiObj_);
                  }}
                /> */}
              </div>
              {/* <Button
                variant="solid"
                color="primary"
                onClick={() => {
                  setIsOpenRuleBookModal(true);
                }}
              >
                <PlusIcon color={"#fff"} width={20} /> Add Rule
              </Button> */}
              <Button
                variant="solid"
                color="primary"
                onClick={() => {
                  setIsOpenManagerModal(true);
                }}
              >
                <PlusIcon color={"#fff"} width={20} /> Add Rule
              </Button>
            </div>
          </Col>
          <Col span={24}>
            <div className="card card-shadow  mb-5 mb-xl-8">
              <div className="card-body py-2 px-4 main-box">
                <Table2
                  columns={columns}
                  sticky={{
                    offsetHeader: "10px",
                  }}
                  scroll={{
                    x: "max-content",
                    y: "calc(100vh - 320px)",
                  }}
                  fixed={true}
                  dataSource={list}
                  rowKey="key"
                  loading={loading}
                  pagination={PaginationConfig({
                    pageSize: all_filters?.["per-page"],
                    page: all_filters?.page,
                    totalPage,
                    onChange: (e, f) => {
                      set_all_filters({
                        ...all_filters,
                        page: e,
                        "per-page": f,
                      });
                      setLoading(true);
                      const apiObj = {
                        ...all_filters,
                        page: e,
                        "per-page": f,
                      };
                      GetManagerList(apiObj);
                    },
                  })}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {isOpenManagerModal && (
        <AddManager
          show={isOpenManagerModal}
          onHide={() => {
            setIsOpenManagerModal(false);
            setSelectedRow({});
          }}
          data={selectedRow}
          onSuccess={() => {
            setIsOpenManagerModal(false);
            setLoading(true);
            setList([]);
            set_all_filters({
              ...all_filters,
              page: 1,
              "per-page": DefaultPerPage,
            });
            GetManagerList({
              ...all_filters,
              page: 1,
              "per-page": DefaultPerPage,
            });
          }}
        />
      )}
    </>
  );
};

export default ReimbursementManager;
