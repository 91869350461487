import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Flex,
  Input,
  message,
  Popover,
  Select,
  Switch,
  Tag,
  theme,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import Table2 from "../../../../../components/tables/table2";
import {
  ConvertParams,
  DateFormate,
  DisplayNotification,
  DownloadFileUsingApi,
  EditIcon,
  ENDPOINT,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  isNot2000SeriesYear,
  PaginationConfig,
} from "../../../../../config";
import dayjs from "dayjs";

const { useToken } = theme;
const { Panel } = Collapse;
const AdvertisementCampaignList = ({
  selected_marketplace,
  set_selected_marketplace,
  marketplace_list,
  set_marketplace_list,
  currency_list,
  set_currency_list,
  selected_currency_sign,
  set_selected_currency_sign,
}) => {
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "150px",
  };

  const [loading, setLoading] = useState(true);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [isAllCampaign, setIsAllCampaign] = useState(false);
  const [isOpenEditCampaign, setIsOpenEditCampaign] = useState(false);
  const [edit_campaign_data, set_edit_campaign_data] = useState({});
  const [isOpenBulkOperationModal, setIsOpenBulkOperationModal] = useState();
  const [bulkOperationType, setbulkOperationType] = useState();
  const [updatingCampaignStatus, setUpdatingCampaignStatus] = useState(false);
  const [portfolioList, setPortfolioList] = useState([]);
  const [columnConfigLoading, setColumnConfigLoading] = useState(false);
  const [sortFilters, setSortFilters] = useState("");
  const [sortOrderColumns, setSortOrderColumns] = useState("");
  const [isOpenFilterDrawer, setIsOpenFilterDrawer] = useState(false);
  const [isOpenMetricsPopover, setIsOpenMetricsPopover] = useState(false);
  const [selectedFilterCheckbox, setSelectedFilterCheckbox] = useState([
    "ad_type",
    "targeting_settings",
    "state",
    "budget_type",
    "start_date",
    "end_date",
    "budget_amount",
    "impressions",
    "clicks",
    "CTR",
    "spend",
    "CPC",
    "sales",
    "ACoS",
    "ROAS",
    "new_to_brand_orders",
    "unit_ordered",
  ]);

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const [all_filters, set_all_filters] = useState({
    search_key: "",
    ad_type: null,
    campaign_name: "",
    state: null,
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
    no_pagination: true,
    portfolio_id: null,
  });
  const [metrics_filter_obj, set_metrics_filter_obj] = useState([
    {
      attribute: null,
      operator: null,
      value: "",
    },
  ]);

  const getTableData = async (data, type) => {
    try {
      setTableData([]);
      setTableLoading(true);
      const response = await MakeApiCall(
        `advertising/get-all-campaigns-list${ConvertParams(data)}`,
        `get`,
        null,
        true
      );
      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        // setCurrentPage(response?.data?.pagination?.page || 1);
        // setPageSize(response?.data?.pagination?.page_size || 10);
        setTableData(response?.data?.table_data || []);
        const selectedColumns = response?.data?.campaign_filter || [
          "ad_type",
          "targeting_settings",
          "state",
          "budget_type",
          "start_date",
          "end_date",
          "budget_amount",
          "impressions",
          "clicks",
          "CTR",
          "spend",
          "CPC",
          "sales",
          "ACoS",
          "ROAS",
          "new_to_brand_orders",
          "unit_ordered",
        ];
        setSelectedFilterCheckbox(selectedColumns);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setTableData([]);
      setTableLoading(false);
    }
  };

  useEffect(() => {
    GetMarketplace();
    return () => {};
  }, []);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      setTableLoading(true);
      setTableData([]);
      getTableData({
        ...all_filters,
        page: currentPage,
        "per-page": pageSize,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
        order_column: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
      });
      GetPortfolioList(marketplace?.marketplace_list[0]?.marketplace_id);
    } else {
      setTableLoading(false);
    }
  };

  const GetPortfolioList = async (marketplace_id) => {
    const response = await MakeApiCall(
      `advertising/all-portfolios?marketplace_id=${marketplace_id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setPortfolioList(response?.data);
    } else {
      setPortfolioList([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const SaveCampaignStatus = async (state, campaignList) => {
    const response = await MakeApiCall(
      `advertising/update-bulk-campaign`,
      `put`,
      {
        ids: campaignList,
        campaign_status: state,
      },
      true
    );
    if (response?.status) {
      setTableLoading(true);
      setTableData([]);
      getTableData({
        ...all_filters,
        page: currentPage,
        "per-page": pageSize,
        marketplace_id: selected_marketplace,
        order_column: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
      });
    } else {
      setTableLoading(true);
      setTableData([]);
      getTableData({
        ...all_filters,
        page: currentPage,
        "per-page": pageSize,
        marketplace_id: selected_marketplace,
        order_column: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
      });
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <span
          onClick={() => {
            setIsOpenBulkOperationModal(true);
            setbulkOperationType("status");
          }}
        >
          Update Status
        </span>
      ),
    },
  ];
  const defaultColumns = [
    {
      title: () => {
        return (
          <Checkbox
            checked={isAllCampaign}
            size="large"
            onChange={(e) => {
              if (e.target.checked) {
                const allCamp = tableData?.map((d) => d?.campaign_id);
                setSelectedCampaign(allCamp);
              } else {
                setSelectedCampaign([]);
              }
              setIsAllCampaign(e.target.checked);
            }}
          />
        );
      },
      ellipsis: true,
      align: "left",
      fixed: "left",
      width: 80,
      render: (text) => {
        return (
          <>
            <Checkbox
              checked={
                selectedCampaign.filter((a) => a == text?.campaign_id)
                  ?.length == 1
                  ? true
                  : false
              }
              size="large"
              onChange={(e) => {
                const checkboxId = text?.campaign_id;
                setSelectedCampaign((prev) => {
                  if (e?.target?.checked) {
                    return [...prev, checkboxId];
                  } else {
                    return prev.filter(
                      (campaign_id) => campaign_id !== checkboxId
                    );
                  }
                });
              }}
            />
            <Switch
              className="ms-2"
              size="small"
              checked={text?.state === "ENABLED" ? true : false}
              onChange={(e) => {
                setUpdatingCampaignStatus(true);
                SaveCampaignStatus(e ? "ENABLED" : "PAUSED", [
                  text?.campaign_id,
                ]);
                setTableData((prev) => {
                  return prev.map((item) => {
                    if (item?.campaign_id === text?.campaign_id) {
                      return {
                        ...item,
                        state: text?.state === "ENABLED" ? "PAUSED" : "ENABLED",
                      };
                    }
                    return item;
                  });
                });
              }}
            />
          </>
        );
      },
    },
    {
      title: "Sr. No.",
      width: 100,
      ellipsis: true,
      render: (_, __, i) => {
        return (
          <>
            <span>{(currentPage - 1) * pageSize + 1 + i}</span>
          </>
        );
      },
    },
    {
      title: "Name",

      width: 300,
      dataIndex: "campaign_name",
      key: "campaign_name",
      localSort: true,
      render: (text) => {
        return <span className="fw-bolder">{text}</span>;
      },
    },
  ];
  const actionColumns = [
    {
      title: "Action",
      fixed: "right",
      width: 100,
      render: (text) => {
        return (
          <div className="d-flex justify-content-between">
            <div className="mx-1">
              <Button
                color="primary"
                variant="solid"
                className="px-2"
                onClick={() => {
                  set_edit_campaign_data(text);
                  setIsOpenEditCampaign(true);
                }}
              >
                <Icon icon="ri:edit-fill" width={18} />
              </Button>
            </div>
          </div>
        );
      },
    },
  ];
  const columns = [
    {
      title: "Campaign Type",

      dataIndex: "ad_type",
      key: "ad_type",
      width: 110,
      localSort: true,
      render: (text) => {
        return (
          <Tag
            className="fw-bold"
            color={
              text === "SPONSORED_PRODUCTS"
                ? "gold"
                : text === "SPONSORED_DISPLAY"
                ? "green"
                : text === "SPONSORED_BRAND"
                ? "geekblue"
                : "purple"
            }
            style={{ textTransform: "capitalize" }}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Targeting Type",

      dataIndex: "targeting_settings",
      key: "targeting_settings",
      width: 140,
      localSort: true,
      render: (text) => {
        return (
          <Tag
            className="fw-bolder"
            color={
              text === "MANUAL" ? "magenta" : text === "AUTO" ? "blue" : ""
            }
            style={{ textTransform: "capitalize" }}
            bordered={false}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Status",

      dataIndex: "state",
      key: "state",
      width: 110,
      localSort: true,
      render: (text, _) => {
        return (
          <>
            <Tag
              className="fw-bolder"
              color={
                text === "Proposal"
                  ? "blue"
                  : text === "ENABLED"
                  ? "green"
                  : text === "PAUSED"
                  ? "orange"
                  : text === "COMPLETED"
                  ? "purple"
                  : "error"
              }
            >
              {text}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Budget Type",

      dataIndex: "budget_type",
      key: "budget_type",
      width: 130,
      localSort: true,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",

      width: 120,
      localSort: true,
    },
    {
      title: "End Date  ",

      dataIndex: "end_date",
      key: "end_date",
      width: 110,
      localSort: true,
      render: (text) => {
        const year = isNot2000SeriesYear(text);
        return <label>{year ? "No End Date" : text}</label>;
      },
    },
    {
      title: "Total Budget",

      dataIndex: "budget_amount",
      key: "budget_amount",
      width: 120,
      localSort: true,
      render: (text) => {
        return (
          <span className="">
            {selected_currency_sign}
            {text}
          </span>
        );
      },
    },

    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: 115,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      width: 80,
      localSort: true,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },

    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      width: 80,
      localSort: true,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
    },
    {
      title: "RoAS",
      dataIndex: "ROAS",
      key: "ROAS",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "NTB Orders",
      dataIndex: "new_to_brand_orders",
      key: "new_to_brand_orders",
      width: 120,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "Units Sold",
      dataIndex: "unit_ordered",
      key: "unit_ordered",
      width: 110,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
  ];

  const checkoxOptions = columns?.map((d) => ({
    label: d?.title,
    value: d?.dataIndex,
  }));

  function downloadXlsxFile(url) {
    fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        console.log(blob, "-----------------");
        if (validateBlobFile(blob)) {
          // Create a temporary URL for the blob
          const fileURL = URL.createObjectURL(blob);
          console.log(fileURL, "blob");

          // Create a link element
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `campaign_list.xlsx`; // Specify the desired filename

          // Simulate a click on the link to initiate the download
          message.destroy();
          link.click();
          // Clean up the temporary URL
          URL.revokeObjectURL(fileURL);
        } else {
          message.destroy();
          handleBlobError(blob);
        }
      })
      .catch((error) => {
        message.destroy();
        message.error("Error in file");
      });
  }

  function validateBlobFile(blob) {
    if (
      blob.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return false;
    }
    return true;
  }

  const handleBlobError = async (blob) => {
    try {
      console.log(blob, "blob");
      const newBlob = await blob.text();
      message.destroy();
      message.error(newBlob.split(".")?.[0]);
    } catch (error) {
      return "Invalid File";
      // Handle the error accordingly
    }
  };

  const SaveCampaignColumnConfig = async () => {
    message.loading("Saving...", 0);
    const response = await MakeApiCall(
      `advertising/update-campaign-filter`,
      `put`,
      {
        campaign_filter: selectedFilterCheckbox,
      },
      true
    );
    if (response?.status) {
      setColumnConfigLoading(false);
      setTableLoading(true);
      setTableData([]);
      getTableData({
        ...all_filters,
        page: currentPage,
        "per-page": pageSize,
        marketplace_id: selected_marketplace,
        order_column: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
      });
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
    } else {
      message.destroy();
      setColumnConfigLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const [metrics_filter, set_metrics_filter] = useState({
    attributes_dd_data: {
      acos: "ACOS",
      roas: "ROAS",
      spends: "Spends",
      sales: "Total Sales",
      cpc: "CPC",
      cvr: "Conversion Rate",
      unit_ordered: "Orders",
      clicks: "Clicks",
      impressions: "Impressions",
      ctr: "CTR",
      ad_sales_percentage: "Ad Sales Percentage",
      total_sales: "Total Sales",
      total_orders: "Total Orders",
      total_acos: "Total ACOS",
    },
    operators_dd_data: {
      ">=": ">=",
      "<=": "<=",
      ">": ">",
      "<": "<",
      "==": "=",
    },
  });

  const filterApply = (all_filters_) => {
    const filteredMetrics = metrics_filter_obj.filter(
      (item) =>
        item.attribute !== null && item.operator !== null && item.value !== ""
    );
    const apiObj = {
      ...all_filters_,
      page: 1,
      "per-page": pageSize,
      // marketplace_id: selected_marketplace,
      start_date: dayjs(all_filters_?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: dayjs(all_filters_?.end_date?.$d).format("YYYY-MM-DD"),
      metric_filter: JSON.stringify(filteredMetrics) || [],
    };
    setCurrentPage(1);
    setTableLoading(true);
    setTableData([]);
    getTableData(apiObj);
  };

  return (
    <>
      <Collapse
        accordion // Makes only one panel expandable at a time
        bordered={false} // Removes border for cleaner design
        style={{ background: "#FFF" }}
        expandIconPosition="end"
        expandIcon={({ isActive }) =>
          isActive ? (
            <Icon
              icon="teenyicons:down-solid"
              style={{ color: "#1890ff" }}
              width={16}
            />
          ) : (
            <Icon
              icon="teenyicons:right-solid"
              style={{ color: "#1890ff" }}
              width={16}
            />
          )
        }
      >
        <Panel
          header={<Typography.Text strong>Campaign List</Typography.Text>}
          key="1"
        >
          <Divider className="mb-3 mt-0" />
          <div className="d-flex justify-content-end flex-wrap gap-4 align-items-center">
            <div className="position-relative me-2">
              <Input
                style={{ width: "250px" }}
                prefix={
                  <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                }
                placeholder="Search by Campaign name"
                size="middle"
                value={all_filters?.campaign_name}
                onChange={(e) => {
                  const apiObj = {
                    ...all_filters,
                    campaign_name: e.target.value,
                  };
                  set_all_filters(apiObj);
                }}
                onPressEnter={() => {
                  filterApply(all_filters);
                }}
              />
            </div>
            <div className="position-relative me-2">
              <DatePicker.RangePicker
                className="ant_common_input w-250px"
                id="aalacart_date_picker"
                size="middle"
                allowClear={false}
                format={DateFormate}
                value={[all_filters?.start_date, all_filters?.end_date]}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(e) => {
                  const filters_ = all_filters;
                  filters_.start_date = e?.[0];
                  filters_.end_date = e?.[1];
                  set_all_filters({ ...filters_ });
                  filterApply(filters_);
                }}
              />
            </div>{" "}
            <div className="position-relative me-2">
              <Select
                className="w-250px"
                size="middle"
                placeholder="Select Marketplace"
                options={GetMarketplaceList(marketplace_list)}
                value={selected_marketplace}
                onChange={(e) => {
                  set_selected_marketplace(e);
                  set_selected_currency_sign(currency_list[e]);
                  const apiObj = {
                    ...all_filters,
                    marketplace_id: e,
                  };
                  set_all_filters(apiObj);
                  filterApply(apiObj);
                }}
              />
            </div>
            <div className="position-relative me-2">
              <Select
                options={[
                  {
                    label: "Sponsored Product",
                    value: "SPONSORED_PRODUCTS",
                  },
                  {
                    label: "Sponsored Display",
                    value: "SPONSORED_DISPLAY",
                  },
                  { label: "Sponsored Brand", value: "SPONSORED_BRAND" },
                  { label: "Sponsored TV", value: "SPONSORED_TV" },
                ]}
                className="w-250px"
                size="middle"
                allowClear
                value={all_filters?.ad_type}
                onChange={(e) => {
                  const apiObj = {
                    ...all_filters,
                    ad_type: e,
                  };
                  set_all_filters(apiObj);
                  filterApply(apiObj);
                }}
                placeholder="Campaign Type"
              />
            </div>
            <div className="position-relative me-2">
              {" "}
              <Select
                options={[
                  { label: "PAUSED", value: "PAUSED" },
                  { label: "ENABLED", value: "ENABLED" },
                ]}
                className="w-250px"
                size="middle"
                allowClear
                value={all_filters?.state}
                onChange={(e) => {
                  const apiObj = {
                    ...all_filters,
                    state: e,
                  };
                  set_all_filters(apiObj);
                  filterApply(apiObj);
                }}
                placeholder="Campaign Status"
              />
            </div>
            <div className="position-relative me-2">
              <Select
                options={[
                  { label: "AUTO", value: "AUTO" },
                  { label: "MANUAL", value: "MANUAL" },
                ]}
                className="w-250px"
                size="middle"
                allowClear
                value={all_filters?.targeting_settings}
                onChange={(e) => {
                  const apiObj = {
                    ...all_filters,
                    targeting_settings: e,
                  };
                  set_all_filters(apiObj);
                  filterApply(apiObj);
                }}
                placeholder="Targeting Type"
              />
            </div>
            <div className="position-relative me-2">
              <Select
                className="w-250px"
                size="middle"
                placeholder="Select Portfolio"
                options={portfolioList?.map((d) => {
                  return { label: d?.name, value: d?.portfolioId };
                })}
                allowClear
                value={all_filters?.portfolio_id}
                onChange={(e) => {
                  const apiObj = {
                    ...all_filters,
                    portfolio_id: e,
                  };
                  set_all_filters(apiObj);
                  filterApply(apiObj);
                }}
              />
            </div>{" "}
            <div className="position-relative me-2">
              <Popover
                placement="bottom"
                trigger="click"
                open={isOpenMetricsPopover}
                content={
                  <div className="">
                    {metrics_filter_obj?.map((d, index) => {
                      return (
                        <div className="mt-3 d-flex">
                          <Select
                            placeholder="Select Metric"
                            allowClear
                            options={Object.entries(
                              metrics_filter?.attributes_dd_data
                            )?.map((d) => {
                              return {
                                label: d[1],
                                value: d[0],
                              };
                            })}
                            value={d?.attribute}
                            onChange={(e) => {
                              set_metrics_filter_obj((prev) => {
                                return prev?.map((d, mindex) => {
                                  if (mindex === index) {
                                    return {
                                      ...d,
                                      attribute: e,
                                    };
                                  }
                                  return d;
                                });
                              });
                            }}
                            size="middle"
                            style={{ width: "200px" }}
                          />
                          <Select
                            className="ms-1"
                            allowClear
                            options={Object.entries(
                              metrics_filter?.operators_dd_data
                            )?.map((d) => {
                              return {
                                label: d[1],
                                value: d[0],
                              };
                            })}
                            size="middle"
                            value={d?.operator}
                            onChange={(e) => {
                              set_metrics_filter_obj((prev) => {
                                return prev?.map((d, mindex) => {
                                  if (mindex === index) {
                                    return {
                                      ...d,
                                      operator: e,
                                    };
                                  }
                                  return d;
                                });
                              });
                            }}
                            style={{ width: "100px" }}
                            placeholder="Select Condition"
                          />
                          <Input
                            style={{ width: "120px" }}
                            className="ms-1"
                            value={d?.value}
                            size="middle"
                            onChange={(e) => {
                              set_metrics_filter_obj((prev) => {
                                return prev?.map((d, mindex) => {
                                  if (mindex === index) {
                                    return {
                                      ...d,
                                      value: e.target.value,
                                    };
                                  }
                                  return d;
                                });
                              });
                            }}
                            placeholder="Enter Value"
                          />
                          {metrics_filter_obj?.length > 1 && (
                            <span
                              className="btn btn-icon btn-light-primary btn-outline w-30px h-30px position-relative border-0 cursor-pointer ms-1"
                              onClick={() => {
                                const deletedData = metrics_filter_obj?.filter(
                                  (d, i) => i !== index
                                );
                                set_metrics_filter_obj(deletedData);
                              }}
                            >
                              <i className="ki-outline ki-cross fs-2" />
                            </span>
                          )}
                        </div>
                      );
                    })}
                    <div className="text-center">
                      <Button
                        size="small"
                        type="primary"
                        style={{ marginTop: "10px" }}
                        onClick={() => {
                          set_metrics_filter_obj([
                            ...metrics_filter_obj,
                            {
                              attribute: null,
                              operator: null,
                              value: "",
                            },
                          ]);
                        }}
                      >
                        + Add another
                      </Button>
                    </div>

                    <Divider className="my-2" />
                    <div className="d-flex justify-content-between">
                      <Button
                        color="danger"
                        size="small"
                        variant="filled"
                        onClick={() => {
                          set_metrics_filter_obj([
                            {
                              attribute: null,
                              operator: null,
                              value: "",
                            },
                          ]);
                          setIsOpenMetricsPopover(false);
                        }}
                      >
                        {/* <i className="ki-outline ki-cross fs-2" /> */}
                        Clear all
                      </Button>
                      <Button
                        disabled={
                          metrics_filter_obj.filter(
                            (item) =>
                              !item.attribute || !item.operator || !item.value
                          )?.length > 0
                        }
                        size="small"
                        color="primary"
                        variant="solid"
                        onClick={() => {
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": pageSize,
                            marketplace_id: selected_marketplace,
                            start_date: dayjs(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: dayjs(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            metric_filter:
                              JSON.stringify(metrics_filter_obj) || [],
                          };
                          setCurrentPage(1);
                          setTableLoading(true);
                          setTableData([]);
                          getTableData(apiObj);
                          setIsOpenMetricsPopover(false);
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setIsOpenMetricsPopover(true);
                  }}
                >
                  + Metrics{" "}
                </Button>
              </Popover>
            </div>
            <div className="position-relative me-2">
              <Dropdown
                trigger={["click"]}
                disabled={columnConfigLoading || tableLoading}
                dropdownRender={() => (
                  <div style={contentStyle}>
                    <div>
                      <div
                        style={{
                          minHeight: "206px",
                          minWidth: "170px",
                          border: "1px solid #3699ff8a",
                          borderRadius: "7px",
                          overflow: "auto",
                        }}
                      >
                        <div className="my-3" />
                        <Checkbox.Group
                          value={selectedFilterCheckbox}
                          options={checkoxOptions}
                          onChange={(value) => {
                            setSelectedFilterCheckbox(value);
                          }}
                          style={{
                            display: "grid",
                            justifyContent: "center",
                          }}
                        />
                        <div className="separator border-gray-200 mt-3 mb-2" />
                        <Flex justify="end" className="mb-2 pe-2 text-end">
                          {" "}
                          <Button
                            color="primary"
                            size="small"
                            variant="solid"
                            disabled={columnConfigLoading}
                            onClick={() => {
                              setColumnConfigLoading(true);
                              SaveCampaignColumnConfig();
                            }}
                          >
                            Save
                          </Button>
                        </Flex>
                      </div>
                    </div>
                  </div>
                )}
              >
                <Button color="primary" variant="filled">
                  Select Columns
                </Button>
              </Dropdown>
            </div>
            <div className="position-relative me-2">
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
                disabled={selectedCampaign?.length > 0 ? false : true}
              >
                <Button>Update Campaign</Button>
              </Dropdown>
            </div>
            <div className="position-relative me-2">
              <Button
                type="primary"
                onClick={() => {
                  const data = {
                    ...all_filters,
                    start_date: dayjs(all_filters?.start_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(all_filters?.end_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    marketplace_id: selected_marketplace,
                  };
                  message.loading("Loading...", 0);
                  DownloadFileUsingApi(
                    `${ENDPOINT}advertising/all-campaign-export${ConvertParams(
                      data
                    )}`,
                    "campaign_list"
                  );
                }}
              >
                Export{" "}
              </Button>
            </div>
          </div>
        </Panel>
      </Collapse>
      <div className="card  mb-5 mb-xl-8 mt-3">
        {/* <div className="card-header  py-2 px-3">
          <div className="card-title">Campaign List</div>
          <div className="card-toolbar"></div>
        </div> */}

        <div className="card-body  px-3 pt-3">
          <Table2
            columns={[
              ...defaultColumns,
              ...columns?.filter((d) =>
                selectedFilterCheckbox?.includes(d?.dataIndex)
              ),
              ...actionColumns,
            ]}
            scroll={{ x: "max-content" }}
            setSortFilters={setSortFilters}
            sortFilters={sortFilters}
            setSortOrderColumns={setSortOrderColumns}
            pagination={PaginationConfig({
              pageSize,
              currentPage,
              totalPage,
              onChange: (e, f) => {
                setPageSize(f);
                setCurrentPage(e);
              },
            })}
            dataSource={tableData}
            loading={tableLoading}
          />
        </div>
      </div>
    </>
  );
};

export default AdvertisementCampaignList;
