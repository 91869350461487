import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  message,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Table,
  Tabs,
  Typography,
} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { DeleteIcon, primaryColor } from "../../../../../../../../../../config";
import { Icon } from "@iconify/react/dist/iconify.js";
import TextArea from "antd/es/input/TextArea";
import Table2 from "../../../../../../../../../../components/tables/table2";
import SearchItem from "./search-item";
import EnterList from "./enter-list";
import { MakeApiCall } from "../../../../../../../../../../apis";
const { Text } = Typography;

const AutomaticNegativeTargeting = (props) => {
  const {
    marketplaceId,
    selectedNegativeKeywords,
    setSelectedNegativeKeywords,
    selectedNegativeProductList,
    setSelectedNegativeProductList,
  } = props;
  const [selectedTab, setSelectedTab] = useState("search");
  const [matchType, setMatchType] = useState("negative_exact");
  const [keyword, setKeyword] = useState("");
  const [productList, setProductList] = useState([]);

  const items = [
    {
      key: "search",
      label: "Search",
    },
    {
      key: "enter_list",
      label: "Enter List",
    },
    {
      key: "upload",
      label: "Upload",
    },
  ];

  const columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "Match Type",
      dataIndex: "match_type",
      key: "match_type",
      render: (match_type) => (
        <span style={{ textTransform: "capitalize" }}>
          {match_type?.toLowerCase().replaceAll("_", " ")}
        </span>
      ),
    },
    {
      title: "Action",
      render: (record, _, index) => (
        <span
          onClick={() => {
            const updatedList = selectedNegativeKeywords.filter(
              (item, ki) => ki !== index
            );
            setSelectedNegativeKeywords(updatedList);
          }}
        >
          <DeleteIcon />
        </span>
      ), // Customize action with buttons
    },
  ];

  const GetProductListUsingEnterList = async (data) => {
    const response = await MakeApiCall(
      `advertising/check-product-targeting-by-asin`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      const data = response?.data?.records?.map((d) => {
        const newObj = {
          id: d?.id,
          expression_type: "ASIN_SAME_AS",
          state: "ENABLED",
          expression_value: d?.asin,
          name: d?.product_name,
          image_url: d?.image_url,
          type: "products",
        };
        setSelectedNegativeProductList((prev) => {
          const existingASIN = prev?.find(
            (es) => es?.expression_value === d?.asin
          );
          if (existingASIN) {
            return prev.map((sk) =>
              sk?.expression_value === d?.asin
                ? {
                    ...sk,
                    ...newObj,
                  }
                : sk
            );
          } else {
            return [...prev, newObj];
          }
        });
      });
    } else {
      message.destroy();
      message.error(response?.message);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col sm={24} lg={12} className="">
          <Card
            style={{ minHeight: "400px" }}
            title={
              <Title level={5} className="mb-0">
                Negative Keyword Targeting(Optional)
              </Title>
            }
          >
            <Row>
              <Col span={24} className="mt-5">
                <Text strong>Match Type</Text>
                <Radio.Group
                  name="Match Type"
                  className="ms-3"
                  onChange={(e) => {
                    setMatchType(e.target.value);
                  }}
                  value={matchType}
                >
                  <Radio value={"negative_exact"}>Negative Exact</Radio>
                  <Radio value={"negative_phrase"}>Negative Phrase</Radio>
                </Radio.Group>
                <Divider variant="dashed" className="my-2" />
                <TextArea
                  className="mt-2"
                  rows={6}
                  placeholder="Enter Keyword"
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
                <Button
                  type="primary"
                  className="mt-3"
                  disabled={!keyword}
                  onClick={() => {
                    if (keyword) {
                      const check_keyword = selectedNegativeKeywords?.filter(
                        (item) =>
                          item?.keyword === keyword?.trim() &&
                          item?.match_type === matchType
                      );
                      if (check_keyword?.length > 0) {
                        message.destroy();
                        message.info("Keyword Exists");
                        setKeyword("");
                        return false;
                      }
                      setSelectedNegativeKeywords([
                        ...selectedNegativeKeywords,
                        {
                          match_type: matchType,
                          state: "ENABLED",
                          keyword: keyword,
                        },
                      ]);
                      setKeyword("");
                    }
                  }}
                >
                  Add Keyword
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col sm={24} lg={12} className="">
          <Card
            style={{ minHeight: "400px" }}
            title={
              <Title level={5} className="mb-0">
                Selected Keywords
              </Title>
            }
            extra={
              <Button
                type="primary"
                onClick={() => {
                  setSelectedNegativeKeywords([]);
                }}
              >
                Remove All
              </Button>
            }
          >
            <Row>
              <Col span={24}>
                <Table2
                  dataSource={selectedNegativeKeywords}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 260 }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-5">
        <Col sm={24} lg={12} className="">
          <Card
            style={{ minHeight: "350px" }}
            title={
              <Title level={5} className="mb-0">
                Negative Product targeting(Optional)
              </Title>
            }
          >
            <Row className="mb-4">
              <Col span={24}>
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  onChange={(key) => {
                    setSelectedTab(key);
                  }}
                />
              </Col>
            </Row>
            {selectedTab === "search" && (
              <SearchItem
                productList={productList}
                setProductList={setProductList}
                selectedNegativeProductList={selectedNegativeProductList}
                setSelectedNegativeProductList={(data) => {
                  setSelectedNegativeProductList(data);
                }}
                {...props}
              />
            )}
            {selectedTab === "enter_list" && (
              <EnterList
                getAsinList={(asinList) => {
                  const finalAsin = asinList?.split(",");
                  const data = {
                    marketplace_id: marketplaceId,
                    product_asins: selectedNegativeProductList?.map((d) => {
                      return d?.expression_value;
                    }),
                    asins: finalAsin.map((str) => str.trim()),
                  };
                  GetProductListUsingEnterList(data);
                }}
              />
            )}
          </Card>
        </Col>
        <Col sm={24} lg={12} className="">
          <Card
            style={{ minHeight: "350px" }}
            title={
              <Title level={5} className="mb-0">
                Selected Products
              </Title>
            }
            extra={
              <Button
                type="primary"
                onClick={() => {
                  setSelectedNegativeProductList([]);
                }}
              >
                Remove All
              </Button>
            }
          >
            <div style={{ height: "300px", overflowY: "auto" }}>
              <Row gutter={16} className="px-0 mx-0">
                {selectedNegativeProductList?.map((d) => {
                  return (
                    <Col span={24} className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <Image src={d?.image_url} style={{ width: "45px" }} />
                          <div className="ms-2">
                            <Popover
                              content={
                                <div
                                  style={{
                                    height: "max-content",
                                    maxWidth: "400px",
                                  }}
                                >
                                  {d?.name}
                                </div>
                              }
                              placement="bottomLeft"
                            >
                              <div className="singleLineEllipsis">
                                <Paragraph className="mb-0">
                                  {d?.name}
                                </Paragraph>
                              </div>
                            </Popover>

                            <div className="mt-1">
                              <label>
                                ASIN:
                                <span className="text-primary">
                                  {d?.expression_value}{" "}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            onClick={() => {
                              const isDeleted =
                                selectedNegativeProductList?.filter(
                                  (f) =>
                                    f?.expression_value !== d?.expression_value
                                );
                              console.log(isDeleted, "-isDeleted");
                              setSelectedNegativeProductList(isDeleted);
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      </div>

                      <Divider variant="dashed" className="my-3" />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AutomaticNegativeTargeting;
