import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Button, Col, Flex, message, Row } from "antd";
import { MakeApiCall } from "../../../../apis";
import LostWhs from "./details-tab/lost-whs";
import DamagedWhs from "./details-tab/damaged_whs";
import Disposed from "./details-tab/disposed";
import LostInbound from "./details-tab/lost-inbound";
import LostOutbound from "./details-tab/lost-outbound";
import DamagedReturns from "./details-tab/damaged-return";
import WrongFnsku from "./details-tab/wrong_fnsku";
const ReimbursementDetails = () => {
  const [selected_tab, set_selected_tab] = useState("lost_whs");
  const [case_status_list, set_case_status_list] = useState([]);
  const [all_manager_list, set_all_manager_list] = useState([]);

  const GetCaseStatusList = async () => {
    const response = await MakeApiCall(
      `rms/get-case-status-list`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      set_case_status_list(response?.data?.records);
    } else {
      message.destroy();
      message.error(response?.message);
    }
  };
  const GetAllManager = async () => {
    const response = await MakeApiCall(`get-all-manager`, `get`, null, true);
    if (response?.status) {
      set_all_manager_list(response?.data?.records);
    } else {
      message.destroy();
      message.error(response?.message);
    }
  };

  useEffect(() => {
    GetCaseStatusList();
    GetAllManager();
  }, []);

  return (
    <Wrapper>
      <Row className="mb-5">
        <Col span={24} className="py-2">
          {/* <Flex align="center" style={{ overflowX: "auto", width: "100%" }}> </Flex>*/}
          <Button
            color={selected_tab === "lost_whs" ? "primary" : "default"}
            variant={selected_tab === "lost_whs" ? "solid" : "filled"}
            className="me-3 my-2"
            onClick={() => {
              set_selected_tab("lost_whs");
            }}
          >
            Lost WHS
          </Button>
          <Button
            color={selected_tab === "damaged_whs" ? "primary" : "default"}
            variant={selected_tab === "damaged_whs" ? "solid" : "filled"}
            className="me-3 my-2"
            onClick={() => {
              set_selected_tab("damaged_whs");
            }}
          >
            Damaged WHS
          </Button>
          <Button
            color={selected_tab === "disposed" ? "primary" : "default"}
            variant={selected_tab === "disposed" ? "solid" : "filled"}
            className="me-3 my-2"
            onClick={() => {
              set_selected_tab("disposed");
            }}
          >
            Disposed
          </Button>
          <Button
            color={selected_tab === "lost_inbound" ? "primary" : "default"}
            variant={selected_tab === "lost_inbound" ? "solid" : "filled"}
            className="me-3 my-2"
            onClick={() => {
              set_selected_tab("lost_inbound");
            }}
          >
            Lost Inbound
          </Button>
          <Button
            color={selected_tab === "lost_outbound" ? "primary" : "default"}
            variant={selected_tab === "lost_outbound" ? "solid" : "filled"}
            className="me-3 my-2"
            onClick={() => {
              set_selected_tab("lost_outbound");
            }}
          >
            Lost Outbound
          </Button>
          <Button
            color={selected_tab === "damaged_return" ? "primary" : "default"}
            variant={selected_tab === "damaged_return" ? "solid" : "filled"}
            className="me-3 my-2"
            onClick={() => {
              set_selected_tab("damaged_return");
            }}
          >
            Damaged Return
          </Button>
          <Button
            color={selected_tab === "wrong_fnsku" ? "primary" : "default"}
            variant={selected_tab === "wrong_fnsku" ? "solid" : "filled"}
            className="me-3 my-2"
            onClick={() => {
              set_selected_tab("wrong_fnsku");
            }}
          >
            Wrong FNSKU
          </Button>
        </Col>
      </Row>
      {selected_tab === "lost_whs" && (
        <LostWhs
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "damaged_whs" && (
        <DamagedWhs
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "disposed" && (
        <Disposed
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "lost_inbound" && (
        <LostInbound
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "lost_outbound" && (
        <LostOutbound
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "damaged_return" && (
        <DamagedReturns
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "wrong_fnsku" && (
        <WrongFnsku
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
    </Wrapper>
  );
};

export default ReimbursementDetails;
