import { Button, Col, Divider, Modal, Row, Typography } from "antd";
import React from "react";

export default function (props) {
  const { show, onHide, selectedCamp } = props;

  return (
    <>
      <Modal
        title="Campaigns List"
        open={show}
        onCancel={onHide}
        footer={
          <Button onClick={onHide} type="primary" danger>
            Cancel
          </Button>
        }
        centered
        maskClosable={false}
      >
        <div
          style={{
            height: "350px",
            overflow: "auto",
          }}
          className="mt-3"
        >
          <Row>
            {selectedCamp?.map((d, i) => (
              <>
                <Col span={24}>
                  <Typography.Text strong>{d?.campaign_name}</Typography.Text>
                </Col>
                <Divider className="my-3" />
              </>
            ))}
          </Row>
        </div>
      </Modal>
    </>
  );
}
