import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import { DisplayNotification } from "../../../../../config";

export default function (props) {
  const { show, onHide, data, onSuccess } = props;

  const [form] = Form.useForm();
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (show) {
      form.resetFields(); // Reset form fields when the modal is opened
      form.setFieldsValue(data); // Set initial values or default to user_type = 2
    }
  }, [show, form, data]);

  const SaveManagerData = async (body) => {
    setUpdating(true);
    const response = await MakeApiCall(
      data?.id ? `manager/${data?.id}` : "manager",
      data?.id ? "put" : "post",
      body,
      true
    );
    if (response?.status) {
      setUpdating(false);
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
      onSuccess();
    } else {
      message.destroy();
      setUpdating(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setUpdating(true);
        SaveManagerData(values);
        // console.log(values, "values");

        // onSubmit(values);
        // setTimeout(() => {
        //   setLoading(false);
        //   form.resetFields();
        //   onCancel();
        // }, 2000); // Simulate API call
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <>
      <Modal
        title={`Add Manager`}
        open={show}
        onCancel={onHide}
        centered
        width="500px"
        maskClosable={false}
        footer={[
          <Button key="back" onClick={onHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={updating}
            onClick={handleOk}
          >
            {data ? "Update" : "Submit"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Row className="row mt-3 px-3">
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="contact_no"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter your contact number",
                  },
                  {
                    pattern: /^[0-9]{10}$/, // Validates a 10-digit number
                    message: "Contact number must be exactly 10 digits",
                  },
                ]}
              >
                <Input placeholder="Enter your contact number" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
