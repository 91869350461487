import { Button, Col, Flex, Row } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { DateFormate } from "../../../../../../../config";
import AddAdgroup from "./lib/add-adgroup";
import AddItems from "./lib/add-items/add-items";
import CreateCampaign from "./lib/create-campaign";
import ManualTargeting from "./lib/manual-targeting/manual-targeting";
import NegativeTargeting from "./lib/negative-targeting/negative-targeting";
import SponsoredProductSteps from "./lib/steps";
import { Wrapper } from "./style";

const SponsoredProduct = (props) => {
  const { marketplaceId } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [subTargetingType, setSubTargetingType] = useState("keyword_targeting");
  const [campaign_data_list, set_campaign_data_list] = useState({
    campaign_ad_type: "SP",
    name: "",
    targeting_type: "AUTO",
    start_date: dayjs()?.format(DateFormate),
    end_date: null,
    daily_budget: "",
    portfolio_id: null,
    bidding_strategy: "LEGACY_FOR_SALES",
    top_of_search_placement: 0,
    product_pages_placement: 0,
    rest_of_search_placement: 0,
    state: "ENABLED",
  });
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [selectedAdgroupList, setSelectedAdgroupList] = useState([]);
  const [defaultBidData, setDefaultBidData] = useState({});
  const [defaultSuggestedBid, setDefaultSuggestedBid] = useState(0);
  const [defaultSuggestedBidInput, setDefaultSuggestedBidInput] = useState(0);
  const [bidsByTargetingGroup, setBidsByTargetingGroup] = useState([]);
  const [allRadioValue, setAllRadioValue] = useState({
    auto_target_radio: "default_bid",
  });
  const [selectedNegativeKeywords, setSelectedNegativeKeywords] = useState([]);
  const [selectedNegativeProductList, setSelectedNegativeProductList] =
    useState([]);
  const [selectedManualKeywordList, setSelectedManualKeywordList] = useState(
    []
  );
  const [selectedManualProductList, setSelectedManualProductList] = useState(
    []
  );
  const [selectedManualCategoryList, setSelectedManualCategoryList] = useState(
    []
  );
  const [productTargetingSubType, setProductTargetingSubType] =
    useState("category");
  // const [selectedTargetingType, setSelectedTargetingType] = useState("keyword");

  const SetAutoCampaignJson = () => {
    const productList = selectedProductList?.map((d) => {
      return { sku: d?.seller_sku, asin: d?.asin1, state: d?.state };
    });
    const campaignData = {
      ...campaign_data_list,
      start_date:
        dayjs(campaign_data_list?.start_date).format("YYYY-MM-DD") || null,
      end_date:
        dayjs(campaign_data_list?.end_date).format("YYYY-MM-DD") || null,
    };
    const newJson = {
      marketplace_id: marketplaceId,
      campaign_data: campaignData,
      ad_groups_data: [
        {
          name: selectedAdgroupList?.[0],
          default_bid: defaultSuggestedBidInput,
          state: "ENABLED",
          product_ads: productList,
          product_targeting: bidsByTargetingGroup?.map((d) => {
            return {
              match_type: d?.targeting_expression_type,
              bid: d?.suggested_bid,
            };
          }),
          keyword_targeting: [],
          negative_keyword_targeting: selectedNegativeKeywords,
          negative_product_targeting: selectedNegativeProductList?.map((d) => {
            return {
              expression_type: d?.expression_type,
              state: d?.state,
              expression_value: d?.expression_value,
            };
          }),
        },
      ],
    };
    if (allRadioValue?.auto_target_radio === "default_bid") {
      delete newJson?.ad_groups_data[0]?.product_targeting;
    } else {
      newJson.ad_groups_data[0].default_bid = 0;
    }
    console.log(newJson, "-newJson");
    // set_loading(true);
    // CreateCampaign(newJson);
  };
  const SetManualCampaignJson = () => {
    const productList = selectedProductList?.map((d) => {
      return { sku: d?.seller_sku, asin: d?.asin1, state: d?.state };
    });
    const keywordTargetingData = selectedManualKeywordList.flatMap((item) => {
      const matchTypes = ["exact", "phrase", "broad"];
      return matchTypes
        .filter((type) => item[type])
        .map((type) => ({
          keyword: item[type].keyword_name,
          bid: item[type].sugg_bid,
          match_type: item[type].name,
          state: item[type].state,
        }));
    });

    const categoryTargetingData = selectedManualCategoryList?.map((d) => {
      return {
        expression_type: "ASIN_CATEGORY_SAME_AS",
        expression_value: d?.category_id,
        bid: d?.new_suggested_bid,
        state: "ENABLED",
      };
    });
    const productTargetingData = selectedManualProductList.flatMap((item) => {
      const result = [];
      if (item?.exact) {
        result.push({
          expression_type: item?.exact?.expression_type,
          expression_value: item?.exact?.expression_value,
          bid: item?.exact?.suggested_bid,
          state: item?.exact?.state,
        });
      }
      if (item?.expanded) {
        result.push({
          expression_type: item?.expanded?.expression_type,
          expression_value: item?.expanded?.expression_value,
          bid: item?.expanded?.suggested_bid,
          state: item?.expanded?.state,
        });
      }
      return result;
    });

    const newJson = {
      marketplace_id: marketplaceId,
      campaign_data: campaign_data_list,
      ad_groups_data: [
        {
          name: selectedAdgroupList?.[0],
          default_bid: "0",
          state: "ENABLED",
          product_ads: productList,
          product_targeting:
            productTargetingSubType === "individual_products"
              ? productTargetingData
              : categoryTargetingData,
          keyword_targeting: keywordTargetingData,
          negative_keyword_targeting: selectedNegativeKeywords,
          negative_product_targeting: selectedNegativeProductList?.map((d) => {
            return {
              expression_type: d?.expression_type,
              state: d?.state,
              expression_value: d?.expression_value,
            };
          }),
        },
      ],
    };
    if (subTargetingType === "keyword_targeting") {
      delete newJson.ad_groups_data[0].product_targeting;
    } else {
      delete newJson.ad_groups_data[0].keyword_targeting;
    }
    console.log(newJson, "-newJson");

    // set_loading(true);
    // CreateCampaign(newJson);
  };

  return (
    <Wrapper className="mt-5">
      <Row gutter={(16, 16)}>
        <Col xs={24} md={24} xl={5} className="mt-0">
          <SponsoredProductSteps
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            campaignData={campaign_data_list}
          />
        </Col>
        <Col xs={24} md={24} xl={19}>
          {currentStep === 0 && (
            <CreateCampaign
              campaignData={campaign_data_list}
              setCampaignData={set_campaign_data_list}
              subTargetingType={subTargetingType}
              setSubTargetingType={setSubTargetingType}
              setCurrentStep={setCurrentStep}
              marketplaceId={marketplaceId}
            />
          )}
          {currentStep === 1 && (
            <AddItems
              campaignData={campaign_data_list}
              setCampaignData={set_campaign_data_list}
              selectedProductList={selectedProductList}
              setSelectedProductList={setSelectedProductList}
              setCurrentStep={setCurrentStep}
            />
          )}{" "}
          {currentStep === 2 && (
            <AddAdgroup
              campaignData={campaign_data_list}
              setCampaignData={set_campaign_data_list}
              setCurrentStep={setCurrentStep}
              selectedAdgroupList={selectedAdgroupList}
              setSelectedAdgroupList={setSelectedAdgroupList}
              allRadioValue={allRadioValue}
              setAllRadioValue={setAllRadioValue}
              marketplaceId={marketplaceId}
              selectedProductList={selectedProductList}
              defaultBidData={defaultBidData}
              setDefaultBidData={setDefaultBidData}
              defaultSuggestedBid={defaultSuggestedBid}
              setDefaultSuggestedBid={setDefaultSuggestedBid}
              bidsByTargetingGroup={bidsByTargetingGroup}
              setBidsByTargetingGroup={setBidsByTargetingGroup}
              defaultSuggestedBidInput={defaultSuggestedBidInput}
              setDefaultSuggestedBidInput={setDefaultSuggestedBidInput}
            />
          )}{" "}
          {currentStep === 3 && (
            <>
              <NegativeTargeting
                campaignData={campaign_data_list}
                setCampaignData={set_campaign_data_list}
                marketplaceId={marketplaceId}
                subTargetingType={subTargetingType}
                selectedNegativeKeywords={selectedNegativeKeywords}
                setSelectedNegativeKeywords={setSelectedNegativeKeywords}
                selectedNegativeProductList={selectedNegativeProductList}
                setSelectedNegativeProductList={setSelectedNegativeProductList}
              />
              <Row className="mt-5">
                <Col span={24}>
                  <Flex align="start" justify="space-between">
                    <Button
                      color="default"
                      variant="solid"
                      onClick={() => {
                        setCurrentStep(2);
                      }}
                    >
                      Back
                    </Button>
                    {campaign_data_list?.targeting_type === "AUTO" && (
                      <Button
                        type="primary"
                        onClick={() => {
                          SetAutoCampaignJson();
                        }}
                      >
                        Submit
                      </Button>
                    )}
                    {campaign_data_list?.targeting_type === "MANUAL" && (
                      <Button
                        type="primary"
                        onClick={() => {
                          setCurrentStep(4);
                        }}
                      >
                        Next
                      </Button>
                    )}
                  </Flex>
                </Col>
              </Row>
            </>
          )}{" "}
          {currentStep === 4 && (
            <>
              <ManualTargeting
                campaignData={campaign_data_list}
                setCampaignData={set_campaign_data_list}
                marketplaceId={marketplaceId}
                selectedProductList={selectedProductList}
                subTargetingType={subTargetingType}
                defaultSuggestedBid={defaultSuggestedBid}
                selectedManualKeywordList={selectedManualKeywordList}
                setSelectedManualKeywordList={setSelectedManualKeywordList}
                selectedManualProductList={selectedManualProductList}
                setSelectedManualProductList={setSelectedManualProductList}
                productTargetingSubType={productTargetingSubType}
                setProductTargetingSubType={setProductTargetingSubType}
                selectedManualCategoryList={selectedManualCategoryList}
                setSelectedManualCategoryList={setSelectedManualCategoryList}
              />
              <Row className="mt-5">
                <Col span={24}>
                  <Flex align="start" justify="space-between">
                    <Button
                      color="default"
                      variant="solid"
                      onClick={() => {
                        setCurrentStep(3);
                      }}
                    >
                      Back
                    </Button>
                    {campaign_data_list?.targeting_type === "MANUAL" && (
                      <Button
                        type="primary"
                        onClick={() => {
                          SetManualCampaignJson();
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </Flex>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SponsoredProduct;
