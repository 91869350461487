import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Input,
  message,
  Radio,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { DeleteIcon, secondaryColor } from "../../../../../../../../config";
import Table2 from "../../../../../../../../components/tables/table2";
import { MakeApiCall } from "../../../../../../../../apis";
const { Text } = Typography;
const AddAdgroup = (props) => {
  const {
    marketplaceId,
    campaignData,
    setCampaignData,
    setCurrentStep,
    selectedAdgroupList,
    setSelectedAdgroupList,
    allRadioValue,
    setAllRadioValue,
    selectedProductList,
    defaultBidData,
    setDefaultBidData,
    defaultSuggestedBid,
    setDefaultSuggestedBid,
    bidsByTargetingGroup,
    setBidsByTargetingGroup,
    defaultSuggestedBidInput,
    setDefaultSuggestedBidInput,
  } = props;
  const [adgroupInput, setAdgroupInput] = useState("");
  const [topOfSearchVal, setTopOfSearchVal] = useState({});
  const [restOfSearchVal, setRestOfSearchVal] = useState({});
  const [productPagesSearchVal, setProductPagesSearchVal] = useState({});

  useEffect(() => {
    const asinsList = selectedProductList?.map((d) => {
      return d?.asin1;
    });
    const bidData = {
      marketplace_id: marketplaceId,
      bidding_strategy: campaignData?.bidding_strategy,
      asins: asinsList,
    };
    GetSuggestedBid(bidData);
  }, [campaignData?.bidding_strategy]);

  useEffect(() => {
    getTopOfSearchValue();
    getRestOfSearchValue();
    getProductPagesPlacementValue();
  }, [
    defaultSuggestedBidInput,
    campaignData?.top_of_search_placement,
    campaignData?.rest_of_search_placement,
    campaignData?.product_pages_placement,
  ]);

  const GetSuggestedBid = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-bid-suggestions`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      const newData = response?.data?.bids_by_targeting_group?.map((d) => {
        return { ...d, static_bid: d?.suggested_bid };
      });
      setBidsByTargetingGroup(newData || []);
      setDefaultBidData(response?.data?.default_bid || {});
      setDefaultSuggestedBid(response?.data?.default_bid?.suggested_bid || 0);
      setDefaultSuggestedBidInput(
        response?.data?.default_bid?.suggested_bid || 0
      );
    } else {
      message.destroy();
      message.loading("Loading...", 0);
    }
  };

  const bidByTargetingColumns = [
    {
      title: "Targeting Groups",

      render: (record) => {
        return (
          <div>
            <Text strong> {record?.targeting_expression_type}</Text>
          </div>
        );
      },
    },
    {
      title: "Suggested Bid for Regular Days",
      render: (record) => {
        return (
          <div>
            <div>
              <Text strong> {record?.static_bid}</Text>
            </div>
            <div>
              ( {record?.min_bid} - {record?.max_bid} )
            </div>
          </div>
        );
      },
    },
    {
      title: "Bid",
      render: (record) => {
        return (
          <div>
            <Input
              className="w-200px"
              value={record?.suggested_bid}
              onChange={(e) => {
                const newVal = e.target.value.split(".").join("");
                const isNumeric = /^[0-9]+$/.test(newVal);
                if (isNumeric || e.target.value === "") {
                  setBidsByTargetingGroup((prev) => {
                    return prev?.map((a) => {
                      if (
                        a?.targeting_expression_type ===
                        record?.targeting_expression_type
                      ) {
                        return {
                          ...record,
                          suggested_bid: e.target.value,
                        };
                      } else {
                        return a;
                      }
                    });
                  });
                }
              }}
              prefix={
                <span className=" bg-white fw-bolder fs-5">
                  {/* {selected_currency_sign} */}$
                </span>
              }
            />
          </div>
        );
      },
    },
  ];

  const getTopOfSearchValue = () => {
    const ans1 =
      parseFloat(defaultSuggestedBidInput) *
      campaignData?.top_of_search_placement;
    const ans2 = ans1 / 100;
    const ans3 = ans2 + parseFloat(defaultSuggestedBidInput);
    const ans4 = ans3 * 2;
    setTopOfSearchVal({
      val1: Math.round(ans3 * 100) / 100,
      val2: Math.round(ans4 * 100) / 100,
    });
  };

  const getRestOfSearchValue = () => {
    const ans1 =
      parseFloat(defaultSuggestedBidInput) *
      campaignData?.rest_of_search_placement;
    const ans2 = ans1 / 100;
    const ans3 = ans2 + parseFloat(defaultSuggestedBidInput);
    const ans4 = ans3 / 2;
    const ans5 = ans4 + ans3;
    setRestOfSearchVal({
      val1: Math.round(ans3 * 100) / 100,
      val2: Math.round(ans5 * 100) / 100,
    });
  };

  const getProductPagesPlacementValue = () => {
    const ans1 =
      parseFloat(defaultSuggestedBidInput) *
      campaignData?.product_pages_placement;
    const ans2 = ans1 / 100;
    const ans3 = ans2 + parseFloat(defaultSuggestedBidInput);
    const ans4 = ans3 / 2;
    const ans5 = ans4 + ans3;
    setProductPagesSearchVal({
      val1: Math.round(ans3 * 100) / 100,
      val2: Math.round(ans5 * 100) / 100,
    });
  };
  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24} className="">
          <Card
            title={
              <Title level={5} className="mb-0">
                Ad Group
              </Title>
            }
          >
            <Row>
              <Col span={24}>
                <Text>Ad Group Name</Text>
                <br />
                <Input
                  placeholder="Enter Ad Group Name"
                  style={{ width: "300px" }}
                  value={adgroupInput}
                  disabled={selectedAdgroupList?.length > 0}
                  onPressEnter={(e) => {
                    if (e.target.value) {
                      const isExistAdgoup = selectedAdgroupList?.filter(
                        (f) => f === e.target.value
                      );

                      if (isExistAdgoup?.length > 0) {
                        message.destroy();
                        message.info("Ad group already exist");
                      } else {
                        setSelectedAdgroupList([
                          ...selectedAdgroupList,
                          e.target.value,
                        ]);
                        setTimeout(() => {
                          setAdgroupInput("");
                        }, 600);
                      }
                    } else {
                      message.destroy();
                      message.error("Please add ad group");
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value) {
                      const isExistAdgoup = selectedAdgroupList?.filter(
                        (f) => f === e.target.value
                      );
                      console.log(isExistAdgoup, "-isExistAdgoup");
                      if (isExistAdgoup?.length > 0) {
                        message.destroy();
                        message.info("Ad group already exist");
                      } else {
                        setSelectedAdgroupList([
                          ...selectedAdgroupList,
                          e.target.value,
                        ]);
                        setTimeout(() => {
                          setAdgroupInput("");
                        }, 600);
                      }
                    } else {
                      message.destroy();
                      message.error("Please add ad group");
                    }
                  }}
                  onChange={(e) => {
                    setAdgroupInput(e.target.value);
                  }}
                />
              </Col>
            </Row>

            {selectedAdgroupList?.length > 0 && (
              <>
                <Divider variant="dashed" ClassName="my-3" />
                <Row gutter={16} className="mb-2">
                  <Col span={24}>
                    <Text>Ad Group List</Text>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24} className="d-flex">
                    {selectedAdgroupList?.map((d, i) => {
                      return (
                        <div className="d-flex align-items-center">
                          <Tag
                            color="geekblue"
                            className="d-flex align-items-center justify-content-start me-0"
                          >
                            {d}
                            <span
                              className="d-flex align-items-center ms-2"
                              onClick={() => {
                                const removeAdGroup =
                                  selectedAdgroupList?.filter(
                                    (f, index) => index !== i
                                  );

                                setSelectedAdgroupList(removeAdGroup);
                              }}
                            >
                              <DeleteIcon width="18" />
                            </span>
                          </Tag>

                          <Divider orientation="right" type="vertical" />
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Col>
        {campaignData?.targeting_type === "AUTO" && (
          <Col span={24} className="">
            <Card
              title={
                <Title level={5} className="mb-0">
                  Auto Targeting
                </Title>
              }
            >
              <Row>
                <Col>
                  <Radio.Group
                    name="auto_target_radio"
                    onChange={(e) => {
                      setAllRadioValue({
                        ...allRadioValue,
                        auto_target_radio: e.target.value,
                      });
                    }}
                    value={allRadioValue?.auto_target_radio}
                  >
                    <Radio value={"default_bid"}>
                      <div>
                        <Text strong>Set Default Bid</Text>
                      </div>
                    </Radio>
                    <Radio value={"bid_by_targeting"}>
                      <div>
                        <Text strong>Set bids by targeting groups</Text>
                      </div>
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Divider variant="dashed" className="my-2" />
              {allRadioValue?.auto_target_radio === "default_bid" && (
                <Row gutter={16} align={"middle"}>
                  <Col span={8}>
                    <Text strong>Suggested bid for regular days</Text>
                  </Col>
                  <Col span={8}>
                    <Text strong>${defaultBidData?.suggested_bid}</Text>
                    <br />
                    <Text>
                      ( ${defaultBidData?.min_bid} - ${defaultBidData?.max_bid})
                    </Text>
                  </Col>
                  <Col span={8}>
                    <Input
                      className="w-150px"
                      value={defaultSuggestedBidInput}
                      onChange={(e) => {
                        const newVal = e.target.value.split(".").join("");
                        const isNumeric = /^[0-9]+$/.test(newVal);
                        if (isNumeric || e.target.value === "") {
                          setDefaultSuggestedBidInput(e.target.value);
                        }
                      }}
                      prefix={<span> {/* {selected_currency_sign} */}$</span>}
                    />
                  </Col>
                </Row>
              )}

              {allRadioValue?.auto_target_radio === "bid_by_targeting" && (
                <Row gutter={16} className="mt-3">
                  <Col span={24}>
                    <Table2
                      dataSource={bidsByTargetingGroup}
                      loading={false}
                      scroll={{ x: "max-content" }}
                      columns={bidByTargetingColumns}
                    />
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        )}

        <Col span={24} className="">
          <Card
            title={
              <Title level={5} className="mb-0">
                Campaign Bidding Strategy
              </Title>
            }
          >
            <Row>
              <Col>
                <Radio.Group
                  name="bidding_strategy"
                  onChange={(e) => {
                    setCampaignData({
                      ...campaignData,
                      bidding_strategy: e.target.value,
                    });
                  }}
                  value={campaignData?.bidding_strategy}
                >
                  <Space direction="vertical">
                    <Radio value={"LEGACY_FOR_SALES"}>
                      <div>
                        <Text strong>Dynamic bids - down only</Text>
                        <br />
                        <Text type="secondary">
                          Lowers your bids in real time when your ad may be less
                          likely to convert to a sale. Any campaign created
                          before October 2018 uses this setting by default.
                        </Text>
                      </div>
                    </Radio>
                    <Radio value={"AUTO_FOR_SALES"}>
                      <div>
                        <Text strong>Dynamic bids - up and down</Text>
                        <br />
                        <Text type="secondary">
                          Increases your bids (by a maximum of 100%) in real
                          time when your ad may be more likely to convert to a
                          sale, and lowers your bids when less likely to convert
                          to a sale.
                        </Text>
                      </div>
                    </Radio>
                    <Radio value={"MANUAL"}>
                      <div>
                        <Text strong>Fixed bid</Text>
                        <br />
                        <Text type="secondary">
                          Uses your exact bid and any manual adjustments you
                          set, and is not subject to adjusted bidding.
                        </Text>
                      </div>
                    </Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24} className="">
          <Card
            title={
              <Title level={5} className="mb-0">
                Adjust Bids by Placement
              </Title>
            }
          >
            <Row gutter={[16]}>
              <Col span={8}>
                <Text strong>Top of search (First Page)</Text>
              </Col>
              <Col span={4}>
                <Input
                  value={campaignData?.top_of_search_placement}
                  onChange={(e) => {
                    const newVal = e.target.value.split(".").join("");
                    const isNumeric = /^[0-9]+$/.test(newVal);
                    if (isNumeric || e.target.value === "") {
                      setCampaignData({
                        ...campaignData,
                        top_of_search_placement: e.target.value,
                      });
                    }
                  }}
                  suffix={
                    <Icon
                      icon="material-symbols:percent"
                      width={20}
                      style={{ color: secondaryColor }}
                    />
                  }
                />
              </Col>
              <Col span={12}>
                <Text type="secondary">
                  Example: ${defaultSuggestedBidInput} bid will remain $
                  {topOfSearchVal?.val1 || 0}for this placement, Dynamic bidding
                  could increase it up to $ {topOfSearchVal?.val2 || 0}
                </Text>
              </Col>
            </Row>
            <Row gutter={[16]} className="my-3">
              <Col span={8}>
                <Text strong>Rest of search</Text>
              </Col>
              <Col span={4}>
                <Input
                  value={campaignData?.rest_of_search_placement}
                  onChange={(e) => {
                    const newVal = e.target.value.split(".").join("");
                    const isNumeric = /^[0-9]+$/.test(newVal);
                    if (isNumeric || e.target.value === "") {
                      setCampaignData({
                        ...campaignData,
                        rest_of_search_placement: e.target.value,
                      });
                    }
                  }}
                  suffix={
                    <Icon
                      icon="material-symbols:percent"
                      width={20}
                      style={{ color: secondaryColor }}
                    />
                  }
                />
              </Col>
              <Col span={12}>
                <Text type="secondary">
                  Example: ${defaultSuggestedBidInput} bid will remain $
                  {restOfSearchVal?.val1 || 0}for this placement, Dynamic
                  bidding could increase it up to $ {restOfSearchVal?.val2 || 0}
                </Text>
              </Col>
            </Row>
            <Row gutter={[16]}>
              <Col span={8}>
                <Text strong>Product Pages</Text>
              </Col>
              <Col span={4}>
                <Input
                  value={campaignData?.product_pages_placement}
                  onChange={(e) => {
                    const newVal = e.target.value.split(".").join("");
                    const isNumeric = /^[0-9]+$/.test(newVal);
                    if (isNumeric || e.target.value === "") {
                      setCampaignData({
                        ...campaignData,
                        product_pages_placement: e.target.value,
                      });
                    }
                  }}
                  suffix={
                    <Icon
                      icon="material-symbols:percent"
                      width={20}
                      style={{ color: secondaryColor }}
                    />
                  }
                />
              </Col>
              <Col span={12}>
                <Text type="secondary">
                  Example: ${defaultSuggestedBidInput} bid will remain $
                  {productPagesSearchVal?.val1 || 0}for this placement, Dynamic
                  bidding could increase it up to $
                  {productPagesSearchVal?.val2 || 0}
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col span={24}>
          <Flex align="start" justify="space-between">
            <Button
              color="default"
              variant="solid"
              onClick={() => {
                setCurrentStep(1);
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              disabled={selectedAdgroupList?.length === 0}
              onClick={() => {
                setCurrentStep(3);
              }}
            >
              Next
            </Button>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default AddAdgroup;
