import { Button, Col, Flex, Image, Popover, Row, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import {
  PaginationConfig,
  PlusIcon,
} from "../../../../../../../../../../../config";
import Table2 from "../../../../../../../../../../../components/tables/table2";
const { Paragraph, Text } = Typography;
const Suggested = (props) => {
  const {
    suggestedProductList,
    selectedFilterBy,
    selectedBidType,
    selectedBidValue,
    selectedManualProductList,
    setSelectedManualProductList,
    all_filters,
    set_all_filters,
    totalPage,
    GetProductTargeting,
    marketplaceId,
    selectedProductList,
  } = props;
  const columns = [
    {
      title: "ASIN",
      width: 250,
      render: (record) => {
        return (
          <div className="d-flex ">
            <Image
              src={record?.image_url}
              style={{ width: "45px", objectFit: "contain" }}
              className="me-2"
            />
            <div>
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {record?.product_name}
                  </div>
                }
                placement="bottom"
              >
                <div className="singleLineEllipsis" style={{ width: "250px" }}>
                  <Paragraph>{record?.product_name}</Paragraph>
                </div>
              </Popover>
              <div className="mt-1">
                <label>
                  ASIN:{" "}
                  <span className="text-primary">
                    {record?.recommendedAsin}{" "}
                  </span>
                </label>
              </div>{" "}
              <div className="mt-1">
                <label>
                  Price: <Text>{record?.item_price}</Text>
                </label>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Sugg. Bid",
      dataIndex: "suggested_bid",
      key: "suggested_bid",
    },
    {
      title: "Type",
      render: (record) => {
        const isAddProduct = selectedManualProductList?.filter(
          (f) => f.recommendedAsin === record?.recommendedAsin
        );
        return (
          <div>
            <div>
              {selectedFilterBy?.includes("Exact") &&
              !isAddProduct?.[0]?.exact ? (
                <Flex justify={"space-between"} align={"center"}>
                  <Text strong className="me-5">
                    Exact
                  </Text>
                  <span
                    className="d-flex"
                    onClick={() => {
                      const newObj = {
                        recommendedAsin: record?.recommendedAsin,
                        suggested_bid: record?.suggested_bid,
                        image_url: record?.image_url,
                        product_name: record?.product_name,
                        item_price: record?.item_price,
                        exact: {
                          suggested_bid:
                            selectedBidType === "suggested_bid"
                              ? record?.suggested_bid
                              : selectedBidValue,
                          state: "ENABLED",
                          expression_type: "ASIN_SAME_AS",
                          expression_value: record?.recommendedAsin,
                        },
                      };
                      setSelectedManualProductList((prev) => {
                        const existingASIN = prev?.find(
                          (es) =>
                            es?.recommendedAsin === record?.recommendedAsin
                        );
                        if (existingASIN) {
                          return prev.map((sk) =>
                            sk?.recommendedAsin === record?.recommendedAsin
                              ? {
                                  ...sk,
                                  ...newObj,
                                }
                              : sk
                          );
                        } else {
                          return [...prev, newObj];
                        }
                      });
                    }}
                  >
                    <PlusIcon />
                  </span>
                </Flex>
              ) : (
                ""
              )}
            </div>
            <div>
              {selectedFilterBy?.includes("Expanded") &&
              !isAddProduct?.[0]?.expanded ? (
                <Flex justify={"space-between"} align={"center"}>
                  <Text strong className="me-5">
                    Expanded
                  </Text>
                  <span
                    className="d-flex"
                    onClick={() => {
                      const newObj = {
                        recommendedAsin: record?.recommendedAsin,
                        suggested_bid: record?.suggested_bid,
                        image_url: record?.image_url,
                        product_name: record?.product_name,
                        item_price: record?.item_price,
                        expanded: {
                          suggested_bid:
                            selectedBidType === "suggested_bid"
                              ? record?.suggested_bid
                              : selectedBidValue,
                          state: "ENABLED",
                          expression_type: "ASIN_EXPANDED_FROM",
                          expression_value: record?.recommendedAsin,
                        },
                      };

                      setSelectedManualProductList((prev) => {
                        const existingASIN = prev?.find(
                          (es) =>
                            es?.recommendedAsin === record?.recommendedAsin
                        );
                        if (existingASIN) {
                          return prev.map((sk) =>
                            sk?.recommendedAsin === record?.recommendedAsin
                              ? {
                                  ...sk,
                                  ...newObj,
                                }
                              : sk
                          );
                        } else {
                          return [...prev, newObj];
                        }
                      });
                    }}
                  >
                    <PlusIcon />
                  </span>
                </Flex>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24} style={{ overflow: "auto", height: "400px" }}>
          <Table2
            dataSource={suggestedProductList}
            columns={columns}
            scroll={{ x: "max-content" }}
            pagination={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {PaginationConfig({
            table: false,
            pageSize: all_filters?.["per-page"],
            page: all_filters?.page,
            totalPage: totalPage,
            onChange: (e, f) => {
              set_all_filters({
                ...all_filters,
                page: e,
                "per-page": f,
              });
              const asinsList = selectedProductList?.map((d) => {
                return d?.asin1;
              });
              const apiObj = {
                ...all_filters,
                asins: asinsList,
                marketplace_id: marketplaceId,
                page: e,
                "per-page": f,
              };

              GetProductTargeting(apiObj);
            },
          })}
        </Col>
      </Row>
    </>
  );
};

export default Suggested;
