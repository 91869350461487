import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  message,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import Table2 from "../../../../../components/tables/table2";
import {
  ConvertParams,
  DownloadFileUsingApi,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  GetStatusOfCase,
  GetStatusOfCaseColor,
  PaginationConfig,
} from "../../../../../config";
const Text = Typography;
const Disposed = (props) => {
  const { case_status_list, manager_list } = props;
  const [sortFilters, setSortFilters] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [tableData, setTableData] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");

  const [filters, setFilters] = useState({
    type: "disposed",
    search_text: "",
    transaction_status: null,
    case_id: null,
    manager_id: null,
    status_of_the_case: null,
  });

  const [date_range, set_date_range] = useState({
    start_date: dayjs().startOf("year"),
    end_date: dayjs().add(-2, "d"),
  });

  const getTableData = async (data) => {
    try {
      setTableData([]);
      setTableLoading(true);
      const response = await MakeApiCall(
        `rms/reimbursements-detail${ConvertParams(data)}`,
        `get`,
        null,
        true
      );
      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setCurrentPage(response?.data?.pagination?.page || 1);
        setPageSize(response?.data?.pagination?.page_size || 1);
        setTableData(response?.data?.records || []);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
        message.destroy();
        message.error(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
      setTableData([]);
      setTableLoading(false);
    }
  };

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      setFilters({
        ...filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      getTableData({
        ...filters,
        page: currentPage,
        sort: sortFilters,
        "per-page": pageSize,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        start_date: dayjs(date_range?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: dayjs(date_range?.end_date?.$d).format("YYYY-MM-DD"),
      });
    } else {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    setTableLoading(true);
    setTableData([]);
    GetMarketplace();
    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      width: 100,
      ellipsis: true,
      fixed: "left",
      render: (_, __, i) => {
        return (
          <>
            <span>{(currentPage - 1) * pageSize + 1 + i}</span>
          </>
        );
      },
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
      fixed: "left",
      sortOn: true,
      render: (text) => <div className="text-primary">{text}</div>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sortOn: true,
    },
    {
      title: "Potential Price Amount",
      dataIndex: "price",
      key: "price",
      sortOn: true,
      render: (text) => (
        <div>
          {selected_currency_sign}
          {text}
        </div>
      ),
    },
    {
      title: "Reference Id",
      dataIndex: "reference_id",
      key: "reference_id",
      sortOn: true,
      render: (text) => <div className="text-primary">{text}</div>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      sortOn: true,
    },
    {
      title: "Disposition",
      dataIndex: "disposition",
      key: "disposition",
      sortOn: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sortOn: true,
    },
    {
      title: "Unreconciled Quantity",
      dataIndex: "unreconciled_quantity",
      key: "unreconciled_quantity",
      sortOn: true,
    },
    {
      title: "Transaction Status",
      dataIndex: "transaction_status",
      key: "transaction_status",
      sortOn: true,
    },
    {
      title: "Case ID",
      dataIndex: "caseId",
      key: "caseId",
      sortOn: true,
      render: (text) => <div className="text-primary fw-bold">{text}</div>,
    },
    {
      title: "Case Status",
      dataIndex: "status_of_the_case",
      key: "status_of_the_case",
      sortOn: true,
      render: (text) => (
        <Tag color={GetStatusOfCaseColor(text)} className="fw-bold">
          {GetStatusOfCase(text)}
        </Tag>
      ),
    },
    {
      title: "RMS ID",
      dataIndex: "rms_id",
      key: "rms_id",
      sortOn: true,
      render: (text) => <div className="text-primary fw-bold">{text}</div>,
    },
    {
      title: "Quantity Reimbursed by Cash",
      dataIndex: "quantity_reimbursed_cash",
      key: "quantity_reimbursed_cash",
      sortOn: true,
    },
    {
      title: "Quantity Reimbursed by Inventory",
      dataIndex: "quantity_reimbursed_inventory",
      key: "quantity_reimbursed_inventory",
      sortOn: true,
    },
    {
      title: "Quantity Reimbursed Total",
      dataIndex: "quantity_reimbursed_total",
      key: "quantity_reimbursed_total",
      sortOn: true,
      render: (text) => <div className="text-primary fw-bold">{text}</div>,
    },
    {
      title: "Credited Amount",
      dataIndex: "credited_amount",
      key: "credited_amount",
      sortOn: true,
      render: (text) => <div className="text-primary fw-bold">{text}</div>,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sortOn: true,
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      sortOn: true,
      render: (text) => <div className="text-primary fw-bold">{text}</div>,
    },
    {
      title: "Date Submitted",
      dataIndex: "date_submitted",
      key: "date_submitted",
      sortOn: true,
    },
    {
      title: "Date Resolved",
      dataIndex: "date_resolved",
      key: "date_resolved",
      sortOn: true,
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      sortOn: true,
      render: (text) => (
        <div className="text-primary fw-bold">{text?.name}</div>
      ),
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      sortOn: true,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (text) => {
        return (
          <div>
            <button
              class="btn btn-icon btn-bg-light btn-active-color-success btn-sm"
              onClick={() => {
                setIsOpenModal(true);
                setSelectedRowData(text);
              }}
            >
              <i class="ki-outline ki-pencil fs-2 text-success"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Flex alignItems="center">
            <Input
              style={{ width: "250px" }}
              prefix={
                <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
              }
              onChange={(e) => {
                setFilters({ ...filters, search_text: e.target.value });
              }}
              placeholder="Search by FNSKU"
              className="me-2"
            />
            {/* <RangePickerESF
                      className="ant_common_input"
                      id="ivcsr_date_picker"                     
                      allowClear={false}
                      style={{ width: "290px" }}
                      format={DateFormate}
                      value={[date_range?.start_date, date_range?.end_date]}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      onChange={(e) => {
                        const date_range_ = date_range;
                        date_range_.start_date = e?.[0];
                        date_range_.end_date = e?.[1];
                        set_date_range({ ...date_range_ });
                      }}
                    /> */}
            <Select
              style={{ width: "200px" }}
              options={[
                {
                  label: "Not eligible",
                  value: "Not eligible",
                },
                {
                  label: "Reimbursed",
                  value: "Reimbursed",
                },
                {
                  label: "Reimbursable",
                  value: "Reimbursable",
                },
              ]}
              placeholder="Transaction Status"
              onChange={(e) => {
                setFilters({ ...filters, transaction_status: e });
              }}
              className="me-2"
            />
            <Select
              style={{ width: "200px" }}
              options={manager_list?.map((d) => {
                return {
                  label: d?.name,
                  value: d?.id,
                };
              })}
              placeholder="Manager"
              onChange={(e) => {
                setFilters({ ...filters, manager_id: e });
              }}
              className="me-2"
            />

            <Select
              style={{ width: "200px" }}
              options={case_status_list?.map((d) => {
                return {
                  label: d?.status,
                  value: d?.id,
                };
              })}
              placeholder="Case Status"
              onChange={(e) => {
                setFilters({ ...filters, status_of_the_case: e });
              }}
              className="me-2"
            />

            <Select
              className="w-200px me-2"
              placeholder="Select Marketplace"
              options={GetMarketplaceList(marketplace_list)}
              value={selected_marketplace}
              onChange={(e) => {
                set_selected_marketplace(e);
                set_selected_currency_sign(currency_list[e]);
                const apiObj = {
                  ...filters,
                  marketplace_id: e,
                };
                setFilters(apiObj);
              }}
            />
          </Flex>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col span={24}>
          <Card
            title={<Text strong>Disposed</Text>}
            extra={
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    const data = {
                      ...filters,
                      page: 1,
                      sort: sortFilters,
                      "per-page": pageSize,
                      start_date: dayjs(date_range?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: dayjs(date_range?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      isExport: 1,
                    };
                    message.loading("Loading...", 0);
                    DownloadFileUsingApi(
                      `https://api.aalacart.com/api/v1/rms/reimbursements-detail${ConvertParams(
                        data
                      )}`,
                      "disposed"
                    );
                  }}
                >
                  Cases Export
                </Button>
              </>
            }
          >
            <Table2
              columns={columns}
              setSortFilters={setSortFilters}
              sortFilters={sortFilters}
              dataSource={tableData}
              loading={tableLoading}
              scroll={{ x: "max-content" }}
              rowKey="sku"
              onSortChange={(sort, currentPage, pageSize) => {
                setPageSize(pageSize);
                setCurrentPage(currentPage);
                getTableData({
                  page: currentPage,
                  "per-page": pageSize,
                  ...filters,
                  sort,
                  start_date: dayjs(date_range?.start_date?.$d).format(
                    "YYYY-MM-DD"
                  ),
                  end_date: dayjs(date_range?.end_date?.$d).format(
                    "YYYY-MM-DD"
                  ),
                });
              }}
              pagination={PaginationConfig({
                pageSize,
                currentPage,
                totalPage,
              })}
            />
          </Card>
        </Col>
      </Row>

      {/* {isOpenModal && (
        <DisposedModal
          show={setIsOpenModal}
          onHide={() => {
            setIsOpenModal(false);
          }}
          onSuccess={() => {
            setIsOpenModal(false);
            setSelectedRowData({});
            setTableLoading(true);
            setTableData([]);
            setCurrentPage(1);
            getTableData({
              ...filters,
              page: 1,
              sort: sortFilters,
              "per-page": pageSize,
              start_date: moment(date_range?.start_date?.$d).format(
                "YYYY-MM-DD"
              ),
              end_date: moment(date_range?.end_date?.$d).format("YYYY-MM-DD"),
            });
          }}
          data={selectedRowData}
          manager_list={manager_list}
          case_status_list={case_status_list}
        />
      )} */}
    </>
  );
};
export default Disposed;
