import { Affix, Card, Steps } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { StepsWrapper } from "../style";

const SponsoredProductSteps = (props) => {
  const { currentStep, setCurrentStep, campaignData } = props;

  const manual_type = [
    {
      title: "Step 1",
      description: "Create Campaign",
    },
    {
      title: "Step 2",
      description: "Add Items",
    },
    {
      title: "Step 3",
      description: "Add Ad Group",
    },
    {
      title: "Step 4",
      description: "Negative Targeting",
    },
    ...(campaignData?.targeting_type === "MANUAL"
      ? [
          {
            title: "Step 5",
            description: "Manual Targeting",
          },
        ]
      : []),
  ];
  return (
    <StepsWrapper>
      <Card
        title={
          <Title level={5} className="mb-0">
            Overview
          </Title>
        }
      >
        <Steps
          direction="vertical"
          current={currentStep}
          items={manual_type}
          onChange={(value) => {
            setCurrentStep(value);
          }}
        />
      </Card>
    </StepsWrapper>
  );
};

export default SponsoredProductSteps;
