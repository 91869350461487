import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Col, Flex, Row, Select, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import Table2 from "../../../../../components/tables/table2";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  EditIcon,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  PaginationConfig,
  PlusIcon,
  ViewIcon,
} from "../../../../../config";
import CampaignListModal from "./lib/campaign-list-modal";
const { Text } = Typography;
const DayPartingList = (props) => {
  const {
    setIsDayparting,
    setUpdateData,
    setFlag,
    selected_marketplace,
    set_selected_marketplace,
  } = props;

  const [loading, setLoading] = useState(true);
  const [daypartingList, setDaypartingList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    marketplace_id: null,
  });

  useEffect(() => {
    GetMarketplace();
  }, []);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetDaypartingList({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
    } else {
      setLoading(false);
    }
  };

  const GetDaypartingList = async (filters) => {
    const response = await MakeApiCall(
      `day-parting/get-day-parting-list${ConvertParams(filters)}`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setDaypartingList(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const UpdateDaypartingStatus = async (dayStatus, id) => {
    const response = await MakeApiCall(
      `day-parting/update-status/${id}`,
      `PUT`,
      { status: dayStatus },
      true
    );
    if (response?.status) {
      setLoading(true);
      GetDaypartingList(all_filters);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const columns = [
    {
      title: "Profile Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <label className="fw-bold ">{text}</label>,
    },
    {
      title: "Campaigns",
      dataIndex: "campaign_list",
      key: "campaign_list",
      render: (text) => (
        <Flex align="center">
          <Text strong>{text?.length || 0}</Text>
          <span
            className="d-flex ms-3"
            onClick={() => {
              setSelectedCampaign(text);
              setCampaignView(true);
            }}
          >
            <ViewIcon width={18} />
          </span>
        </Flex>
      ),
    },
    {
      title: "Status",
      render: (text) => (
        <div>
          <Switch
            size="small"
            checked={text?.status == 1 ? true : false}
            onChange={(e) => {
              console.log(e, "-e");
              const dayStatus = e ? 1 : 0;
              UpdateDaypartingStatus(dayStatus, text?.id);
              setDaypartingList((prev) => {
                return prev.map((item) => {
                  if (item?.id === text?.id) {
                    return {
                      ...item,
                      status: text?.status == 1 ? 0 : 1,
                    };
                  }
                  return item;
                });
              });
            }}
          />
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Flex align="center">
          <span
            className="me-4"
            onClick={() => {
              setUpdateData(record);
              setFlag("update");
              setIsDayparting("view");
            }}
          >
            <EditIcon width={18} />
          </span>{" "}
          <span
            className="me-4"
            onClick={() => {
              setUpdateData(record);
              setFlag("clone");
              setIsDayparting("view");
            }}
          >
            <Icon icon="iconoir:copy" style={{ color: "#126f15" }} width={18} />
          </span>
          <span
            className=""
            onClick={() => {
              setUpdateData(record);
              setFlag("view");
              setIsDayparting("view");
            }}
          >
            <ViewIcon width={18} />
          </span>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <div className="d-flex flex-wrap gap-4 align-items-center mb-3 justify-content-end">
              <div className="position-relative"></div>

              <div className="position-relative ">
                <Select
                  className="w-200px"
                  placeholder="Select Marketplace"
                  options={GetMarketplaceList(marketplace_list)}
                  value={selected_marketplace}
                  onChange={(e) => {
                    set_selected_marketplace(e);
                    set_selected_currency_sign(currency_list[e]);
                    const apiObj = {
                      ...all_filters,
                      page: 1,
                      "per-page": DefaultPerPage,
                      marketplace_id: e,
                    };
                    set_all_filters(apiObj);
                    setLoading(true);
                    GetDaypartingList(apiObj);
                  }}
                />
              </div>
              <Button
                variant="solid"
                color="primary"
                onClick={() => {
                  setIsDayparting("view");
                }}
              >
                <PlusIcon color={"#fff"} width={20} />
                Add Dayparting
              </Button>
            </div>
          </Col>
          <Col span={24}>
            <div className="card card-shadow  mb-5 mb-xl-8">
              <div className="card-body py-2 px-4 main-box">
                <Table2
                  columns={columns}
                  sticky={{
                    offsetHeader: "10px",
                  }}
                  scroll={{
                    x: "max-content",
                    y: "calc(100vh - 320px)",
                  }}
                  fixed={true}
                  dataSource={daypartingList}
                  rowKey="key"
                  loading={loading}
                  pagination={PaginationConfig({
                    pageSize: all_filters?.["per-page"],
                    page: all_filters?.page,
                    totalPage,
                    onChange: (e, f) => {
                      set_all_filters({
                        ...all_filters,
                        page: e,
                        "per-page": f,
                      });
                      setLoading(true);
                      const apiObj = {
                        ...all_filters,
                        page: e,
                        "per-page": f,
                      };
                      GetDaypartingList(apiObj);
                    },
                  })}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {campaignView && (
        <CampaignListModal
          campaignList={selectedCampaign}
          show={campaignView}
          onHide={() => setCampaignView(false)}
        />
      )}
    </>
  );
};

export default DayPartingList;
