import {
  Button,
  Col,
  Divider,
  Flex,
  message,
  Row,
  Skeleton,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../../../../../../apis";
import {
  PaginationConfig,
  PlusIcon,
} from "../../../../../../../../../../config";
const { Text } = Typography;

const SearchItem = (props) => {
  const {
    marketplaceId,
    selectedManualKeywordList,
    setSelectedManualKeywordList,
    selectedFilterBy,
    campaignData,
    selectedProductList,
    selectedBidType,
    selectedBidValue,
  } = props;
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [suggKeywordList, setSuggKeywordList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": 20,
  });
  useEffect(() => {
    const asinsList = selectedProductList?.map((d) => {
      return d?.asin1;
    });
    const data = {
      ...all_filters,
      marketplace_id: marketplaceId,
      bidding_strategy: campaignData?.bidding_strategy,
      asins: asinsList,
      sort_dimension: "DEFAULT",
    };
    setKeywordLoading(true);
    GetSuggestedKeyword(data);
  }, []);

  const GetSuggestedKeyword = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-keyword-targeting-suggestions-new`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      setKeywordLoading(false);
      setTotalPage(response?.data?.pagination?.totalCount);
      const transformedData = response?.data.records?.map((item) => {
        const { bid_info, ...rest } = item;
        return { ...rest, ...bid_info };
      });
      setSuggKeywordList(transformedData);
    } else {
      setKeywordLoading(false);
      setSuggKeywordList([]);
      message.destroy();
      message.error(response?.message);
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Flex justify="end">
            <Button type="primary">Add All Keywords</Button>
          </Flex>
        </Col>
      </Row>
      {keywordLoading ? (
        <Skeleton />
      ) : (
        <Row style={{ overflow: "auto", height: "300px" }} className="mt-4">
          {suggKeywordList.length > 0 &&
            suggKeywordList?.map((item) => {
              const isAdd = selectedManualKeywordList?.filter(
                (f) => f.keyword_name === item?.keyword
              );
              return (
                <Col span={24} className="mt-5">
                  <Row>
                    <Col span={24}>
                      <div className="d-flex">
                        <Text type="secondary" className="me-1">
                          Keyword:
                        </Text>
                        <Text>{item?.keyword}</Text>
                      </div>
                    </Col>
                  </Row>
                  <Divider className="my-1" />
                  <Row>
                    <Col span={9}>
                      <Text type="secondary">Match Type:</Text>
                      <br />
                      <Text type="secondary">Sugg. Bid:</Text>
                    </Col>
                    <Col span={5}>
                      <Text>Broad</Text> <br />
                      {selectedFilterBy?.includes("BROAD") ? (
                        <div className="d-flex align-items-center">
                          <Text strong>{item?.BROAD?.suggested_bid || 0}</Text>
                          {!isAdd?.[0]?.broad?.name && (
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                const newObj = {
                                  keyword_name: item?.keyword,
                                  broad: {
                                    keyword_name: item?.keyword,
                                    name: "BROAD",
                                    old_sugg_bid:
                                      item?.BROAD?.suggested_bid || 0,
                                    sugg_bid:
                                      selectedBidType === "suggested_bid"
                                        ? item?.BROAD?.suggested_bid
                                        : selectedBidValue,
                                    state: "ENABLED",
                                  },
                                };
                                setSelectedManualKeywordList((prev) => {
                                  const existingKeyword = prev?.find(
                                    (sk) => sk?.keyword_name === item?.keyword
                                  );
                                  if (existingKeyword) {
                                    // Update existing keyword
                                    return prev.map((sk) =>
                                      sk?.keyword_name === item?.keyword
                                        ? {
                                            ...sk,
                                            ...newObj,
                                          }
                                        : sk
                                    );
                                  } else {
                                    return [...prev, newObj];
                                  }
                                });
                              }}
                            >
                              <PlusIcon width="17" />
                            </span>
                          )}
                        </div>
                      ) : (
                        "-"
                      )}
                    </Col>
                    <Col span={5}>
                      <Text>Phrase</Text> <br />
                      {selectedFilterBy?.includes("PHRASE") ? (
                        <div className="d-flex align-items-center">
                          <Text strong>{item?.PHRASE?.suggested_bid || 0}</Text>
                          {!isAdd?.[0]?.phrase?.name && (
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                const newObj = {
                                  keyword_name: item?.keyword,
                                  phrase: {
                                    keyword_name: item?.keyword,
                                    name: "PHRASE",
                                    old_sugg_bid:
                                      item?.PHRASE?.suggested_bid || 0,
                                    sugg_bid:
                                      selectedBidType === "suggested_bid"
                                        ? item?.PHRASE?.suggested_bid
                                        : selectedBidValue,
                                    state: "ENABLED",
                                  },
                                };

                                setSelectedManualKeywordList((prev) => {
                                  const existingKeyword = prev?.find(
                                    (sk) => sk?.keyword_name === item?.keyword
                                  );
                                  if (existingKeyword) {
                                    // Update existing keyword
                                    return prev.map((sk) =>
                                      sk?.keyword_name === item?.keyword
                                        ? {
                                            ...sk,
                                            ...newObj,
                                          }
                                        : sk
                                    );
                                  } else {
                                    // Add new keyword
                                    return [...prev, newObj];
                                  }
                                });
                              }}
                            >
                              <PlusIcon width="17" />
                            </span>
                          )}
                        </div>
                      ) : (
                        "-"
                      )}
                    </Col>
                    <Col span={5}>
                      <Text>Exact</Text> <br />
                      {selectedFilterBy?.includes("EXACT") ? (
                        <div className="d-flex align-items-center">
                          <Text strong>{item?.EXACT?.suggested_bid || 0}</Text>
                          {!isAdd?.[0]?.exact?.name && (
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                const newObj = {
                                  keyword_name: item?.keyword,
                                  exact: {
                                    keyword_name: item?.keyword,
                                    name: "EXACT",
                                    old_sugg_bid:
                                      item?.EXACT?.suggested_bid || 0,
                                    sugg_bid:
                                      selectedBidType === "suggested_bid"
                                        ? item?.EXACT?.suggested_bid
                                        : selectedBidValue,
                                    state: "ENABLED",
                                  },
                                };

                                setSelectedManualKeywordList((prev) => {
                                  const existingKeyword = prev?.find(
                                    (sk) => sk?.keyword_name === item?.keyword
                                  );
                                  if (existingKeyword) {
                                    // Update existing keyword
                                    return prev.map((sk) =>
                                      sk?.keyword_name === item?.keyword
                                        ? {
                                            ...sk,
                                            ...newObj,
                                          }
                                        : sk
                                    );
                                  } else {
                                    // Add new keyword
                                    return [...prev, newObj];
                                  }
                                });
                              }}
                            >
                              <PlusIcon width="17" />
                            </span>
                          )}
                        </div>
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                </Col>
              );
            })}
        </Row>
      )}
      <Row>
        <Col span={24}>
          {/* <PaginationConfig
            table={true}
            pageSize={all_filters?.["per-page"]}
            page={all_filters?.page}
            totalPage={totalPage}
            onChange={() => {}}
          /> */}
          {PaginationConfig({
            table: false,
            pageSize: all_filters?.["per-page"],
            page: all_filters?.page,
            totalPage,
            onChange: (e, f) => {
              const asinsList = selectedProductList?.map((d) => {
                return d?.asin1;
              });
              set_all_filters({
                ...all_filters,
                page: e,
                "per-page": f,
              });
              setKeywordLoading(true);
              const apiObj = {
                ...all_filters,
                page: e,
                "per-page": f,
                marketplace_id: marketplaceId,
                bidding_strategy: campaignData?.bidding_strategy,
                asins: asinsList,
                sort_dimension: "DEFAULT",
              };
              setKeywordLoading(true);
              GetSuggestedKeyword(apiObj);
            },
          })}
        </Col>
      </Row>
    </>
  );
};

export default SearchItem;
