import { Col, Divider, Flex, Row, Skeleton, Typography } from "antd";
import React from "react";
const { Text } = Typography;

const CreateRuleSkeleton = () => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Skeleton.Button block active />
        </Col>
        <Col span={12}>
          <Skeleton.Button block active />
        </Col>
      </Row>{" "}
      <Row gutter={[16, 16]} className="mt-5">
        <Col span={12}>
          <Skeleton.Button block active />
        </Col>
        <Col span={12}>
          <Skeleton.Button block active />
        </Col>
      </Row>{" "}
      <Col span={24}>
        <Text strong>Schedule</Text>
        <Divider className="mt-2 mb-0" />
      </Col>
      <Row gutter={[16, 16]} className="mt-5">
        <Col span={6}>
          <Skeleton.Button block active />
        </Col>
        <Col span={6}>
          <Skeleton.Button block active />
        </Col>{" "}
        <Col span={6}>
          <Skeleton.Button block active />
        </Col>{" "}
        <Col span={6}>
          <Skeleton.Button block active />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text strong>Criteria</Text>
          <Divider className="mt-2 mb-0" />
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          border: "1px dashed #c7c7c7",
          background: "#fbfbfb",
          position: "relative",
        }}
        className="p-4 mb-5"
      >
        <Col span={4}>
          <Text strong>If</Text>
        </Col>
        <Col span={6}>
          <Skeleton.Button block active />
        </Col>
        <Col span={6}>
          <Skeleton.Button block active />
        </Col>
        <Col span={6}>
          <Skeleton.Button block active />
        </Col>
        <Col span={4} className="mt-2">
          <Text strong>Then</Text>
        </Col>
        <Col span={6} className="mt-2">
          <Skeleton.Button block active />
        </Col>
        <Col span={6} className="mt-2">
          <Skeleton.Button block active />
        </Col>
        <Col span={6} className="mt-2">
          <Skeleton.Button block active />
        </Col>
      </Row>
    </>
  );
};

export default CreateRuleSkeleton;
