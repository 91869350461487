import { Button, Col, Modal, Row } from "antd";
import React from "react";

export default function (props) {
  const { show, onHide, campaignList } = props;
  return (
    <>
      <Modal
        open={show}
        title={"Campaigns List"}
        onCancel={onHide}
        width={500}
        footer={[
          <Button key="back" onClick={onHide}>
            Cancel
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            {campaignList?.map((d, i) => {
              return (
                <>
                  <div className="">
                    <label className="fw-bold fs-6">{d}</label>
                  </div>
                  <div className="separator separator-dashed my-3" />
                </>
              );
            })}
          </Col>
        </Row>
      </Modal>
    </>
  );
}
