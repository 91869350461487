import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  message,
  Popover,
  Radio,
  Row,
  Table,
  Typography,
} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import {
  DeleteIcon,
  PlusIcon,
  primaryColor,
} from "../../../../../../../../../config";
import { Icon } from "@iconify/react/dist/iconify.js";
import TextArea from "antd/es/input/TextArea";
import Table2 from "../../../../../../../../../components/tables/table2";
import { MakeApiCall } from "../../../../../../../../../apis";
const { Text } = Typography;

const NegativeProductTargeting = (props) => {
  const {
    marketplaceId,
    selectedNegativeProductList,
    setSelectedNegativeProductList,
  } = props;
  const [excludeBy, setExcludeBy] = useState("exclude_brands");
  const [negativeBrandList, setNegativeBrandList] = useState([]);
  const [negativeProductList, setNegativeProductList] = useState([]);
  const [negativeProductLoading, setNegativeProductLoading] = useState(false);
  const dataSource = [
    {
      key: "1",
      keyword: "example keyword 1",
      matchType: "Exact",
      action: "Edit",
    },
    {
      key: "2",
      keyword: "example keyword 2",
      matchType: "Broad",
      action: "Delete",
    },
  ];

  const columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "Match Type",
      dataIndex: "matchType",
      key: "matchType",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => <DeleteIcon />, // Customize action with buttons
    },
  ];

  useEffect(() => {
    GetNegativeBrandList();
  }, []);

  const GetNegativeBrandList = async () => {
    const response = await MakeApiCall(
      `advertising/get-negative-targeting-brand-suggestions`,
      `POST`,
      {
        marketplace_id: marketplaceId,
      },
      true
    );
    if (response?.status) {
      setNegativeBrandList(response?.data);
    } else {
      message.destroy();
      message.error(response?.message);
    }
  };

  const GetNegativeBrandSearchList = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-negative-targeting-brand-search`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      setNegativeProductLoading(false);
      setNegativeBrandList(response?.data);
    } else {
      message.destroy();
      message.error(response?.message);
    }
  };

  const GetNegativeProduct = async (data) => {
    const response = await MakeApiCall(
      `advertising/check-product-targeting-by-asin`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      setNegativeProductLoading(false);
      setNegativeProductList(response?.data?.records);
    } else {
      setNegativeProductLoading(false);
      message.destroy();
      message.error(response?.message);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "400px" }}
          title={
            <Title level={5} className="mb-0">
              Negative Product targeting (Optional)
            </Title>
          }
        >
          <Row>
            <Col span={24}>
              <Radio.Group
                name="exclude_by"
                defaultValue={excludeBy}
                className="ms-3"
                onChange={(e) => {
                  setExcludeBy(e.target.value);
                }}
              >
                <Radio value={"exclude_brands"}>Exclude Brand</Radio>
                <Radio value={"exclude_products"}>Exclude Products</Radio>
              </Radio.Group>
              <Divider variant="dashed" className="my-2" />
              {excludeBy === "exclude_brands" && (
                <>
                  <Row className="mt-2">
                    <Col span={24}>
                      <Input
                        placeholder="Search by brand name"
                        onPressEnter={(e) => {
                          GetNegativeBrandSearchList({
                            marketplace_id: marketplaceId,
                            keyword: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col span={24} className="mt-3">
                      <Text style={{ color: primaryColor }} strong>
                        Your Brands
                      </Text>
                    </Col>
                  </Row>
                  <Divider className="my-2" />
                  <Row>
                    {negativeBrandList?.map((d, i) => {
                      const isBrandAdded = selectedNegativeProductList?.filter(
                        (f) => f?.id === d?.id
                      );
                      return (
                        <>
                          <Col span={24}>
                            <div className="d-flex justify-content-between">
                              <Text strong>{d?.name}</Text>
                              <div>
                                {isBrandAdded?.length === 0 && (
                                  <div
                                    onClick={() => {
                                      setSelectedNegativeProductList([
                                        ...selectedNegativeProductList,
                                        {
                                          id: d?.id,
                                          expression_type: "ASIN_BRAND_SAME_AS",
                                          state: "ENABLED",
                                          expression_value: d?.id,
                                          name: d?.name,
                                        },
                                      ]);
                                    }}
                                  >
                                    <PlusIcon />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Divider className="my-2" />
                        </>
                      );
                    })}
                  </Row>
                </>
              )}
              {excludeBy === "exclude_products" && (
                <>
                  <Row className="mt-2">
                    <Col span={24}>
                      <Input
                        placeholder="Search by ASIN"
                        onPressEnter={(e) => {
                          const data = {
                            marketplace_id: marketplaceId,
                            product_asins: [],
                            asins: [e.target.value?.trim()],
                          };
                          setNegativeProductLoading(true);
                          GetNegativeProduct(data);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {negativeProductList?.map((d) => {
                      const isProductAdded =
                        selectedNegativeProductList?.filter(
                          (f) => f?.expression_value === d?.asin
                        );
                      return (
                        <Col span={24} className="mt-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <Image
                                src={d?.image_url}
                                style={{ width: "45px" }}
                              />
                              <div className="ms-2">
                                <Popover
                                  content={
                                    <div
                                      style={{
                                        height: "max-content",
                                        maxWidth: "400px",
                                      }}
                                    >
                                      {d?.product_name}
                                    </div>
                                  }
                                  placement="bottomLeft"
                                >
                                  <div className="singleLineEllipsis">
                                    <Paragraph>{d?.product_name}</Paragraph>
                                  </div>
                                </Popover>

                                <div className="mt-1">
                                  <label>
                                    ASIN:
                                    <span className="text-primary ms-1">
                                      {d?.asin}
                                    </span>
                                  </label>
                                  {/* <span className="mx-1">/</span>
                              <label>
                                SKU:
                                <span className="text-primary">
                                  0X-Z2SM-EVK3
                                </span>
                              </label> */}
                                </div>
                              </div>
                            </div>
                            <div>
                              {isProductAdded?.length == 0 && (
                                <span
                                  className="btn btn-light bg-white btn-sm btn-icon"
                                  onClick={() => {
                                    setSelectedNegativeProductList([
                                      ...selectedNegativeProductList,
                                      {
                                        id: d?.id,
                                        expression_type: "ASIN_SAME_AS",
                                        state: "ENABLED",
                                        expression_value: d?.asin,
                                        name: d?.product_name,
                                        image_url: d?.image_url,
                                        type: "products",
                                      },
                                    ]);
                                  }}
                                >
                                  <PlusIcon />
                                </span>
                              )}
                            </div>
                          </div>
                          <Divider variant="dashed" className="my-2" />
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "400px" }}
          title={
            <Title level={5} className="mb-0">
              Selected Brand or Products
            </Title>
          }
          extra={
            <>
              <Button
                type="primary"
                danger
                onClick={() => {
                  setSelectedNegativeProductList([]);
                }}
              >
                Remove All
              </Button>
            </>
          }
        >
          <Row>
            {selectedNegativeProductList?.map((d, i) => {
              return d?.type === "products" ? (
                <>
                  <Col span={24}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <Image src={d?.image_url} style={{ width: "45px" }} />
                        <div className="ms-2">
                          <Popover
                            content={
                              <div
                                style={{
                                  height: "max-content",
                                  maxWidth: "400px",
                                }}
                              >
                                {d?.name}
                              </div>
                            }
                            placement="bottomLeft"
                          >
                            <div className="singleLineEllipsis">
                              <Paragraph>{d?.name}</Paragraph>
                            </div>
                          </Popover>

                          <div className="mt-1">
                            <label>
                              ASIN:
                              <span className="text-primary">
                                {d?.expression_value}{" "}
                              </span>
                            </label>
                            {/* <span className="mx-1">/</span>
                              <label>
                                SKU:
                                <span className="text-primary">
                                  0X-Z2SM-EVK3
                                </span>
                              </label> */}
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          const updatedList =
                            selectedNegativeProductList.filter(
                              (item, index) => index !== i
                            );
                          setSelectedNegativeProductList(updatedList);
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </Col>
                  <Divider variant="dashed" className="my-2" />
                </>
              ) : (
                <>
                  <Col span={24}>
                    <div className="d-flex justify-content-between">
                      <Text>{d?.name}</Text>
                      <div
                        onClick={() => {
                          const updatedList =
                            selectedNegativeProductList.filter(
                              (item, index) => index !== i
                            );
                          setSelectedNegativeProductList(updatedList);
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </Col>{" "}
                  <Divider variant="dashed" className="my-2" />
                </>
              );
            })}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default NegativeProductTargeting;
