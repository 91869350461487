import React, { useState } from "react";
import { Wrapper } from "./style";
import DayPartingList from "./day-parting-list";
import DaypartingConfig from "./day-parting-config";

const DayParting = () => {
  const [isDayparting, setIsDayparting] = useState("list");
  const [updateData, setUpdateData] = useState({});
  const [flag, setFlag] = useState("create");
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  return (
    <Wrapper>
      {isDayparting === "list" ? (
        <DayPartingList
          setIsDayparting={setIsDayparting}
          setUpdateData={setUpdateData}
          setFlag={setFlag}
          selected_marketplace={selected_marketplace}
          set_selected_marketplace={set_selected_marketplace}
        />
      ) : (
        <DaypartingConfig
          setIsDayparting={setIsDayparting}
          setUpdateData={setUpdateData}
          flag={flag}
          setFlag={setFlag}
          selected_marketplace={selected_marketplace}
          updateData={updateData}
        />
      )}
    </Wrapper>
  );
};

export default DayParting;
