import { Typography } from "antd";
import React, { useState } from "react";
import AutomaticNegativeTargeting from "./auto-negative-targeting/automatic-negative-targeting";
import NegativeKeywordTargeting from "./negative-keyword-targeting";
import NegativeProductTargeting from "./negative-product-targeting";
const { Text } = Typography;

const NegativeTargeting = (props) => {
  const { campaignData, subTargetingType } = props;

  return (
    <>
      {campaignData?.targeting_type === "MANUAL" &&
      subTargetingType === "keyword_targeting" ? (
        <NegativeKeywordTargeting {...props} />
      ) : (
        ""
      )}
      {campaignData?.targeting_type === "MANUAL" &&
      subTargetingType === "product_targeting" ? (
        <NegativeProductTargeting {...props} />
      ) : (
        ""
      )}
      {campaignData?.targeting_type === "AUTO" && (
        <AutomaticNegativeTargeting {...props} />
      )}
    </>
  );
};

export default NegativeTargeting;
