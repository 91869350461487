import { Tabs } from "antd";
import React, { useState } from "react";
import AdvertisementCampaignList from "./campaign-list";
import { Wrapper } from "./style";
import AdvertisementCampaignCreation from "./campaign-creation";

const AdvertisementCampaignManagement = (props) => {
  const [mode, setMode] = useState("campaign_list");
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  return (
    <Wrapper>
      <>
        <Tabs
          tabBarStyle={{ marginBottom: 0, padding: "0px 15px" }}
          type="card"
          items={[
            {
              key: "campaign_list",
              label: "Campaign List",
            },
            {
              key: "campaign_creation",
              label: "Campaign Creation",
            },
          ]}
          onChange={(key) => {
            setMode(key);
          }}
          defaultActiveKey="campaign_list"
        />

        {mode === "campaign_list" && (
          <AdvertisementCampaignList
            selected_marketplace={selected_marketplace}
            set_selected_marketplace={set_selected_marketplace}
            marketplace_list={marketplace_list}
            set_marketplace_list={set_marketplace_list}
            currency_list={currency_list}
            set_currency_list={set_currency_list}
            selected_currency_sign={selected_currency_sign}
            set_selected_currency_sign={set_selected_currency_sign}
          />
        )}
        {mode === "campaign_creation" && (
          <AdvertisementCampaignCreation
            marketplaceId={selected_marketplace}
            {...props}
          />
        )}
      </>
    </Wrapper>
  );
};

export default AdvertisementCampaignManagement;
