import { Button, Card, Col, Divider, Radio, Row, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import Table2 from "../../../../../../../../../components/tables/table2";
import { DeleteIcon } from "../../../../../../../../../config";
import ManualKeywordTargeting from "./manual-keyword-targeting/manual-keyword-targeting";
import ManualProductTargeting from "./manual-product-targeting/manual-product-targeting";
const { Text } = Typography;

const ManualTargeting = (props) => {
  const { subTargetingType } = props;

  return (
    <>
      {subTargetingType === "keyword_targeting" && (
        <ManualKeywordTargeting {...props} />
      )}
      {subTargetingType === "product_targeting" && (
        <ManualProductTargeting {...props} />
      )}
    </>
  );
};

export default ManualTargeting;
