import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Col,
  Divider,
  Image,
  Input,
  Popover,
  Row,
  Skeleton,
  Typography,
} from "antd";
import React from "react";
import {
  DefaultPerPage,
  PaginationConfig,
  PlusIcon,
  primaryColor,
} from "../../../../../../../../../config";
const { Paragraph } = Typography;
const SearchItem = (props) => {
  const {
    productLoading,
    productList,
    setProductFilter,
    productFilter,
    GetProductList,
    selectedProductList,
    setSelectedProductList,
    productTotalPage,
    setProductLoading,
  } = props;
  return (
    <Row>
      <Col span={24} className="mb-5">
        <Row>
          <Col span={24} className="d-flex">
            <Input
              onChange={(e) => {
                setProductFilter({
                  ...productFilter,
                  search: e.target.value,
                });
              }}
              onPressEnter={(e) => {
                const newObj = {
                  ...productFilter,
                  search: e.target.value,
                  page: 1,
                  "per-page": DefaultPerPage,
                };
                setProductFilter(newObj);
                GetProductList(newObj);
              }}
            />
            <Button
              type="primary"
              className="ms-3"
              onClick={() => {
                setSelectedProductList(
                  productList?.map((d) => {
                    return { ...d, state: "ENABLED" };
                  })
                );
              }}
            >
              Add All
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row style={{ height: "400px", overflow: "auto" }} gutter={16}>
          {productLoading
            ? [...Array(9)]?.map((d) => {
                return (
                  <Col span={24} className="mb-2">
                    <Skeleton.Button block active />
                  </Col>
                );
              })
            : productList?.map((d) => {
                const isProductAdded = selectedProductList?.filter(
                  (f) => f?.asin1 === d?.asin1
                );
                return (
                  <Col span={24}>
                    <div className="d-flex justify-content-between">
                      {" "}
                      <div className="d-flex align-items-center">
                        <Image src={d?.image_url} style={{ width: "45px" }} />
                        <div className="ms-2">
                          <Popover
                            content={
                              <div
                                style={{
                                  height: "max-content",
                                  maxWidth: "400px",
                                }}
                              >
                                {d?.item_name}
                              </div>
                            }
                            placement="bottomLeft"
                          >
                            <div className="singleLineEllipsis">
                              <Paragraph className="mb-0">
                                {d?.item_name}
                              </Paragraph>
                            </div>
                          </Popover>

                          <div className="mt-1">
                            <label>
                              ASIN:{" "}
                              <span className="text-primary">{d?.asin1} </span>
                            </label>
                            <span className="mx-1">/</span>
                            <label>
                              SKU:{" "}
                              <span className="text-primary">
                                {d?.seller_sku}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        {isProductAdded?.length == 0 && (
                          <div
                            onClick={() => {
                              setSelectedProductList([
                                ...selectedProductList,
                                { ...d, state: "ENABLED" },
                              ]);
                            }}
                          >
                            <PlusIcon />
                          </div>
                        )}
                      </div>
                    </div>

                    <Divider variant="dashed" className="my-2" />
                  </Col>
                );
              })}
        </Row>
        <Row>
          <Col span={24}>
            {PaginationConfig({
              table: false,
              pageSize: productFilter?.["per-page"],
              page: productFilter?.page,
              totalPage: productTotalPage,
              onChange: (e, f) => {
                setProductFilter({
                  ...productFilter,
                  page: e,
                  "per-page": f,
                });
                const apiObj = {
                  ...productFilter,
                  page: e,
                  "per-page": f,
                };
                setProductLoading(true);
                GetProductList(apiObj);
              },
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SearchItem;
