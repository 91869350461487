import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  message,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { DeleteIcon } from "../../../../../../../../../../config";
import EnterList from "./enter-list";
import SearchItem from "./search-item";
const { Text } = Typography;

const ManualKeywordTargeting = (props) => {
  const {
    marketplaceId,
    campaignData,
    defaultSuggestedBid,
    selectedManualKeywordList,
    setSelectedManualKeywordList,
  } = props;

  const [selectedTab, setSelectedTab] = useState("suggested");
  const [selectedBidType, setSelectedBidType] = useState("suggested_bid");
  const [selectedBidValue, setSeletedBidValue] = useState(defaultSuggestedBid);
  const [selectedFilterBy, setSelectedFilterBy] = useState([
    "BROAD",
    "PHRASE",
    "EXACT",
  ]);

  const items = [
    {
      key: "suggested",
      label: "Suggested",
    },
    {
      key: "enter_list",
      label: "Enter List",
    },
    {
      key: "upload",
      label: "Upload",
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} md={24} lg={24} xl={12} className="">
        <Card
          style={{ minHeight: "690px" }}
          title={
            <Title level={5} className="mb-0">
              Keywords
            </Title>
          }
        >
          <Row className="mb-4">
            <Col span={24}>
              <Tabs
                defaultActiveKey="suggested"
                items={items}
                onChange={(key) => {
                  setSelectedTab(key);
                }}
              />
            </Col>
          </Row>
          {selectedTab !== "upload" && (
            <>
              <Row className="mb-4">
                <Col span={24}>
                  <div className="d-flex align-items-center">
                    <Text strong>Bid</Text>
                    <Select
                      className="mx-3"
                      style={{ width: "150px" }}
                      value={selectedBidType}
                      onChange={(e) => {
                        setSelectedBidType(e);
                      }}
                      options={[
                        {
                          label: "Suggested Bid",
                          value: "suggested_bid",
                        },
                        {
                          label: "Custom Bid",
                          value: "custom_bid",
                        },
                        {
                          label: "Default Bid",
                          value: "default_bid",
                        },
                      ]}
                    />
                    {selectedBidType !== "suggested_bid" && (
                      <Input
                        style={{ width: "100px" }}
                        value={selectedBidValue}
                        onChange={(e) => {
                          setSeletedBidValue(e.target.value);
                        }}
                      />
                    )}
                  </div>
                </Col>
                <Col span={24} className="mt-4">
                  <div className="d-flex align-items-center">
                    <Text strong className="me-3">
                      Filter by
                    </Text>
                    <Checkbox.Group
                      options={[
                        {
                          label: "Broad",
                          value: "BROAD",
                        },
                        {
                          label: "Phrase",
                          value: "PHRASE",
                        },
                        {
                          label: "Exact",
                          value: "EXACT",
                        },
                      ]}
                      value={selectedFilterBy}
                      onChange={(e) => {
                        if (e?.length > 0) {
                          setSelectedFilterBy(e);
                        } else {
                          message.destroy();
                          message.error("Please select at least one filter");
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          {selectedTab === "suggested" && (
            <SearchItem
              selectedManualKeywordList={selectedManualKeywordList}
              setSelectedManualKeywordList={setSelectedManualKeywordList}
              selectedFilterBy={selectedFilterBy}
              selectedBidType={selectedBidType}
              selectedBidValue={selectedBidValue}
              {...props}
            />
          )}
          {selectedTab === "enter_list" && <EnterList />}
        </Card>
      </Col>
      <Col sm={24} md={24} lg={24} xl={12} className="">
        <Card
          style={{ minHeight: "500px" }}
          title={
            <Title level={5} className="mb-0">
              Selected Keywords
            </Title>
          }
          extra={
            <>
              <Button
                type="primary"
                danger
                onClick={() => {
                  setSelectedManualKeywordList([]);
                }}
              >
                Remove All
              </Button>
            </>
          }
        >
          <div style={{ overflow: "auto", height: "570px" }} className="mt-4">
            <Row>
              {console.log(
                selectedManualKeywordList,
                " selected keyword   list"
              )}
              {selectedManualKeywordList.length > 0 &&
                selectedManualKeywordList?.map((item, i) => {
                  return (
                    <Col span={24} className="">
                      <Row className="mb-2">
                        <Col span={24}>
                          <div className="d-flex">
                            <Text type="secondary" className="me-1">
                              Keyword:
                            </Text>
                            <Text strong>{item?.keyword_name}</Text>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <Text type="secondary">Match Type:</Text>
                          <br />
                          <Text type="secondary">Sugg. Bid:</Text>
                        </Col>
                        <Col span={6}>
                          <Text>Broad</Text> <br />
                          {item?.broad?.old_sugg_bid ||
                          item?.broad?.old_sugg_bid == 0 ? (
                            <div>
                              {" "}
                              <div className="d-flex align-items-center">
                                <Text>{item?.broad?.old_sugg_bid || 0}</Text>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    const after_deleted_list =
                                      selectedManualKeywordList.map((d) => {
                                        if (
                                          d?.keyword_name === item?.keyword_name
                                        ) {
                                          const { broad, ...rest } = d;
                                          return rest;
                                        }
                                        return d;
                                      });
                                    const filteredArray =
                                      after_deleted_list.filter(
                                        (d) =>
                                          d?.hasOwnProperty("broad") ||
                                          d?.hasOwnProperty("phrase") ||
                                          d?.hasOwnProperty("exact")
                                      );
                                    setSelectedManualKeywordList(filteredArray);
                                  }}
                                >
                                  <DeleteIcon width="17" />
                                </span>
                              </div>
                              <Input
                                className="w-100px"
                                value={item?.broad?.sugg_bid}
                                onChange={(e) => {
                                  setSelectedManualKeywordList((prev) => {
                                    return prev?.map((sk, j) => {
                                      if (j == i) {
                                        return {
                                          ...sk,
                                          ...(sk.broad.sugg_bid =
                                            e.target.value),
                                        };
                                      } else {
                                        return { ...sk };
                                      }
                                    });
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            "-"
                          )}
                        </Col>
                        <Col span={6}>
                          <Text>Phrase</Text> <br />
                          {item?.phrase?.old_sugg_bid ||
                          item?.phrase?.old_sugg_bid == 0 ? (
                            <>
                              <div className="d-flex align-items-center">
                                <Text>{item?.phrase?.old_sugg_bid || 0}</Text>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    const after_deleted_list =
                                      selectedManualKeywordList.map((d) => {
                                        if (
                                          d?.keyword_name === item?.keyword_name
                                        ) {
                                          const { phrase, ...rest } = d;
                                          return rest;
                                        }
                                        return d;
                                      });
                                    const filteredArray =
                                      after_deleted_list.filter(
                                        (d) =>
                                          d?.hasOwnProperty("broad") ||
                                          d?.hasOwnProperty("phrase") ||
                                          d?.hasOwnProperty("exact")
                                      );
                                    setSelectedManualKeywordList(filteredArray);
                                  }}
                                >
                                  <DeleteIcon width="17" />
                                </span>
                              </div>
                              <Input
                                className="w-100px"
                                value={item?.phrase?.sugg_bid}
                                onChange={(e) => {
                                  setSelectedManualKeywordList((prev) => {
                                    return prev?.map((sk, j) => {
                                      if (j == i) {
                                        return {
                                          ...sk,
                                          ...(sk.phrase.sugg_bid =
                                            e.target.value),
                                        };
                                      } else {
                                        return { ...sk };
                                      }
                                    });
                                  });
                                }}
                              />
                            </>
                          ) : (
                            "-"
                          )}
                        </Col>
                        <Col span={6}>
                          <Text>Exact</Text> <br />
                          {item?.exact?.old_sugg_bid ||
                          item?.exact?.old_sugg_bid == 0 ? (
                            <>
                              {" "}
                              <div className="d-flex align-items-center">
                                <Text>{item?.exact?.old_sugg_bid || 0}</Text>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    const after_deleted_list =
                                      selectedManualKeywordList.map((d) => {
                                        if (
                                          d?.keyword_name === item?.keyword_name
                                        ) {
                                          const { exact, ...rest } = d;
                                          return rest;
                                        }
                                        return d;
                                      });
                                    const filteredArray =
                                      after_deleted_list.filter(
                                        (d) =>
                                          d?.hasOwnProperty("broad") ||
                                          d?.hasOwnProperty("phrase") ||
                                          d?.hasOwnProperty("exact")
                                      );

                                    setSelectedManualKeywordList(filteredArray);
                                  }}
                                >
                                  <DeleteIcon width="17" />
                                </span>
                              </div>
                              <Input
                                className="w-100px"
                                value={item?.exact?.sugg_bid}
                                onChange={(e) => {
                                  setSelectedManualKeywordList((prev) => {
                                    return prev?.map((sk, j) => {
                                      if (j == i) {
                                        return {
                                          ...sk,
                                          ...(sk.exact.sugg_bid =
                                            e.target.value),
                                        };
                                      } else {
                                        return { ...sk };
                                      }
                                    });
                                  });
                                }}
                              />
                            </>
                          ) : (
                            "-"
                          )}
                        </Col>
                      </Row>
                      <Divider className="my-3" />
                    </Col>
                  );
                })}
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default ManualKeywordTargeting;
