import { Col, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../../apis";
import NoDataTable from "../../../../../../components/no-data-table";
import { TableLoading } from "../../../../../../components/table-animation";
import Table1 from "../../../../../../components/tables/table1";
import {
  ConvertParams,
  DisplayNotification,
  PaginationConfig,
} from "../../../../../../config";
import DateWiseActionlogSubModal from "./date-wise-actionlog-sub-modal";

export default function (props) {
  const { show, onHide, selectedActionLog, currencySign } = props;

  const [loading, setLoading] = useState(true);
  const [main_log_list, setMain_log_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": 20,
    master_rule_id: selectedActionLog?.id,
  });

  const [isOpenSubModal, setIsOpenSubModal] = useState(false);
  const [subLoading, setSubLoading] = useState(true);
  const [sublogList, setSubLogList] = useState([]);
  const [subTotalPage, setSubTotalPage] = useState(1);
  const [success_failed_all_filters, set_success_failed_all_filters] = useState(
    {
      page: 1,
      "per-page": 20,
      master_rule_id: selectedActionLog?.id,
      date: "",
      type: "",
    }
  );

  useEffect(() => {
    GetActionLogList(all_filters);
  }, []);

  const GetActionLogList = async (filters) => {
    const response = await MakeApiCall(
      `rule-management/get-action-logs${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setMain_log_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetSuccessFailedCount = async (filter) => {
    const response = await MakeApiCall(
      `rule-management/get-applied-rule-logs${ConvertParams(filter)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      message.destroy();
      if (response?.data?.records?.length > 0) {
        setIsOpenSubModal(true);
        setSubLoading(false);
        setSubLogList(response?.data?.records);
        setSubTotalPage(response?.data?.pagination?.totalCount);
      } else {
        message.destroy();
        DisplayNotification("error", "Error", "No Data Found");
      }
    } else {
      message.destroy();
      setSubLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const mainColumns = [
    {
      title: "#",
      width: 80,
      fixed: "left",
      render: (text, __, i) => {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>
              {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + i}
            </span>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Success Count",
      render: (text) => {
        return (
          <span
            className="text-success fw-bolder cursor-pointer"
            onClick={() => {
              message.destroy();
              message.loading("Loading...", 0);
              GetSuccessFailedCount({
                ...success_failed_all_filters,
                date: text?.date,
                type: "success",
              });
            }}
          >
            {text?.success_log || 0}
          </span>
        );
      },
    },
    {
      title: "Failed Count",
      render: (text) => {
        return (
          <span
            className="text-danger fw-bolder cursor-pointer"
            onClick={() => {
              message.destroy();
              message.loading("Loading...", 0);
              GetSuccessFailedCount({
                ...success_failed_all_filters,
                date: text?.date,
                type: "failed",
              });
            }}
          >
            {text?.failed_log || 0}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title={`Action Logs - ${selectedActionLog?.name}`}
        open={show}
        onCancel={onHide}
        centered
        width="800px"
        maskClosable={false}
        footer={[]}
      >
        <Row className="row mt-3 px-3">
          <Col span={24}>
            {loading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                row={4}
                actions={[1, 2]}
                style={{ height: "290px", overflow: "hidden" }}
              />
            ) : main_log_list?.length > 0 ? (
              <Table1
                columns={mainColumns}
                dataSource={main_log_list}
                rowKey="key"
                scroll={{
                  x: "max-content",
                  y: "calc(100vh - 270px)",
                }}
                loading={loading}
                pagination={PaginationConfig({
                  pageSize: all_filters?.["per-page"],
                  page: all_filters?.page,
                  totalPage,
                  onChange: (e, f) => {
                    set_all_filters({ ...all_filters, page: e, "per-page": f });
                    setLoading(true);
                    const apiObj = {
                      ...all_filters,
                      page: e,
                      "per-page": f,
                    };
                    GetActionLogList(apiObj);
                  },
                })}
              />
            ) : (
              <NoDataTable columns={mainColumns} type="no-data" />
            )}
          </Col>
        </Row>
      </Modal>

      {isOpenSubModal && (
        <DateWiseActionlogSubModal
          show={isOpenSubModal}
          onHide={() => {
            setSubLoading(false);
            setSubLogList([]);
            set_success_failed_all_filters({
              page: 1,
              "per-page": 20,
              master_rule_id: selectedActionLog?.id,
              date: "",
              type: "",
            });
            setSubTotalPage(0);
            setIsOpenSubModal(false);
          }}
          data={sublogList}
          subLoading={subLoading}
          currencySign={currencySign}
          setSubLoading={setSubLoading}
          subTotalPage={subTotalPage}
          success_failed_all_filters={success_failed_all_filters}
          set_success_failed_all_filters={set_success_failed_all_filters}
          GetSuccessFailedCount={GetSuccessFailedCount}
        />
      )}
    </>
  );
}
