import {
  Button,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
} from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { DateFormate } from "../../../../../../../../config";
import { MakeApiCall } from "../../../../../../../../apis";

const CreateCampaign = (props) => {
  const {
    campaignData,
    setCampaignData,
    subTargetingType,
    setSubTargetingType,
    setCurrentStep,
    marketplaceId,
  } = props;

  const [portfolioList, setPortfolioList] = useState([]);

  useEffect(() => {
    GetPortfolioList(marketplaceId);
  }, []);
  const GetPortfolioList = async (marketplace_id) => {
    const response = await MakeApiCall(
      `advertising/all-portfolios?marketplace_id=${marketplace_id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setPortfolioList(response?.data);
    } else {
      setPortfolioList([]);
      message.destroy();
      message.error(response?.message);
    }
  };

  return (
    <>
      <Row>
        <Col span={24} className="">
          <Form
            layout={"vertical"}
            name="basic"
            initialValues={{
              campaign_ad_type: "SP",
              name: campaignData?.name,
              targeting_type: campaignData?.targeting_type,
              start_date: dayjs(campaignData?.start_date),
              end_date: campaignData?.end_date
                ? dayjs(campaignData?.end_date)
                : "",
              daily_budget: campaignData?.daily_budget,
              portfolio_id: campaignData?.portfolio_id,
              bidding_strategy: campaignData?.bidding_strategy,
              top_of_search_placement: campaignData?.top_of_search_placement,
              product_pages_placement: campaignData?.product_pages_placement,
              rest_of_search_placement: campaignData?.rest_of_search_placement,
              state: "ENABLED",
              targeting_type_manual: subTargetingType,
            }}
            onFinish={(e) => {
              setCampaignData({ ...campaignData, ...e });
              setSubTargetingType(e.targeting_type_manual);
              setCurrentStep(1);
            }}
            //   onFinishFailed={onFinishFailed}
            autoComplete="new-value"
          >
            <Card
              title={
                <Title level={5} className="mb-0">
                  Create Campaign
                </Title>
              }
            >
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    label="Campaign Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter campaign name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Portfolio"
                    name="portfolio_id"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input your username!",
                    //   },
                    // ]}
                  >
                    <Select
                      allowClear
                      options={portfolioList?.map((d) => {
                        return {
                          label: d?.name,
                          value: d?.portfolioId,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Select Targeting Tactic"
                    name="targeting_type"
                    rules={[
                      {
                        required: true,
                        message: "Please select a targeting tactic!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="AUTO"> Automatic </Radio>
                      <Radio value="MANUAL"> Manual </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.targeting_type !== currentValues.targeting_type
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("targeting_type") === "MANUAL" && (
                        <Form.Item
                          label="Manual Targeting"
                          name="targeting_type_manual"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please select a manual targeting option!",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="keyword_targeting">
                              Keyword Targeting
                            </Radio>
                            <Radio value="product_targeting">
                              Product Targeting
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      )
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card
              className="mt-5"
              title={
                <Title level={5} className="mb-0">
                  Scheduling & Budget
                </Title>
              }
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Start Date"
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        message: "Please enter start date!",
                      },
                    ]}
                  >
                    <DatePicker
                      format={DateFormate}
                      className="w-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="End Date" name="end_date">
                    <DatePicker format={DateFormate} className="w-100" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Daily Budget"
                    name="daily_budget"
                    rules={[
                      {
                        required: true,
                        message: "Please enter daily budget!",
                      },
                    ]}
                  >
                    <InputNumber className="w-100" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row className="mt-5">
              <Col span={24}>
                <Flex justify="end">
                  <Form.Item label={null}>
                    <Button type="primary" htmlType="submit">
                      Next
                    </Button>
                  </Form.Item>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateCampaign;
