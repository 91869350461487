import { Icon } from "@iconify/react/dist/iconify.js";
import { message, notification, Skeleton } from "antd";
import { MakeApiCall } from "../apis";
import Pagination from "../components/pagination";
import axios from "axios";
import { store } from "../redux/store";

export const ProjectName = "AalaCart";
export const DefaultAdminPage = `/manage-user`;
export const DefaultUserPage = `/dashboard`;
export const DateFormate = "MMM DD, YYYY";
export const ORIGIN = `${
  process.env.NODE_ENV === "development"
    ? "https://api.aalacart.com"
    : "https://api.aalacart.com"
}`;
export const ENDPOINT = `${ORIGIN}/api/v1/`;
export const Types = {
  1: "admin",
  3: "user",
};

export const primaryColor = "#3699ff"; // medium
export const secondaryColor = "#3e3e3e"; // dark
export const thirdColor = "#3699ff"; // light
export const forthColor = "#cfe3f7"; // light

export const BorderFilter = `1px solid ${primaryColor}`;
export const FormatUserName = (userName) => {
  // Replace hyphens with spaces
  let formattedName = userName?.replace(/-/g, " ");

  // Capitalize the first letter of each word
  formattedName = formattedName?.replace(/\b\w/g, function (char) {
    return char?.toUpperCase();
  });

  return formattedName === "Sku" ? "SKU" : formattedName;
};
export const DefaultPerPage = 20;
export const pageDropdown = [10, 20, 50, 100];
export const RenderTable = (props, row, index, d, width) =>
  typeof d?.render === "function" ? (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        {d?.render(props, row, index)}
      </div>
    </>
  ) : (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        <span>{props || "-"}</span>
      </div>
    </>
  );
export const ConvertParams = (values) => {
  return Object.entries(
    Object.entries(values)
      .filter(
        ([_, value]) =>
          value &&
          value !== "" &&
          value !== undefined &&
          value !== null &&
          value?.length !== 0
      )
      .reduce((acc, [key, value]) => {
        acc[key] = encodeURIComponent(value);

        return acc;
      }, {})
  )
    ?.map((d, i) => {
      return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
    })
    ?.join("");
};
export const AddCommas = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const PaginationConfig = (props) => {
  const {
    table = true,
    pageSize,
    currentPage,
    page,
    totalPage,
    onChange = () => {},
  } = props;
  console.log(page, "currentPage");

  if (!table) {
    return (
      <Pagination
        pageSize={pageSize}
        page={page}
        totalPage={totalPage}
        onPerPage={(e, r) => {
          onChange(r, e);
        }}
        onPageNo={(e, t) => {
          onChange(e, t);
        }}
      />
    );
  }
  return {
    pageSize: pageSize,
    current: page,
    total: totalPage,
    onChange: (e, f) => {
      onChange(e, f);
    },
    showSizeChanger: true, // To show page size options
    pageSizeOptions: [10, 20, 50, 100], // Page size options
    showTotal: (total, range) =>
      `${AddCommas(range[0] || 0)}-${AddCommas(range[1] || 0)} of ${AddCommas(
        total || 0
      )} items`,
  };
};
export const DisplayNotification = (
  type,
  title,
  message,
  placement,
  closeIcon
) => {
  notification.destroy();
  setTimeout(() => {
    if (type === "error") {
      notification.error({
        message: title || "Error",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#ffb7b7", // Change the background color
          borderRadius: "7px",
        },
      });
    } else if (type === "success") {
      notification.success({
        message: title || "Error",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#cdefcb", // Change the background color
          borderRadius: "7px",
        },
      });
    } else if (type === "warning") {
      notification.warning({
        message: title || "Warning",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#fef5e6", // Change the background color
          borderRadius: "7px",
        },
      });
    } else if (type === "info") {
      notification.info({
        message: title || "Info",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#d8e7ff", // Change the background color
          borderRadius: "7px",
        },
      });
    }
  }, 500);
};
export const getTableLoading = (numberOfColumns) => {
  const numberOfRows = 10;
  const renderedRows = [...Array(numberOfRows)].map((e, i) => (
    <tr key={i}>
      {[...Array(numberOfColumns)]?.map((d) => {
        return (
          <td>
            <Skeleton.Button active block />
          </td>
        );
      })}
    </tr>
  ));
  return renderedRows;
};
export const isNot2000SeriesYear = (date) => {
  const year = new Date(date).getFullYear();
  return year < 2000 || year > 2099;
};
export const EditIcon = (props) => {
  const { width = 24, color = primaryColor } = props;
  return (
    <Icon
      icon="basil:edit-outline"
      style={{ color: color }}
      width={width || 24}
    />
  );
};
export const DeleteIcon = (props) => {
  const { width = 24, color = "ff5454" } = props;
  return (
    <Icon
      icon="fluent:delete-20-filled"
      style={{ color: color }}
      width={width || 24}
    />
  );
};
export const PlusIcon = (props) => {
  const { width = 24, color = primaryColor } = props;
  return (
    <Icon
      icon="ic:baseline-plus"
      style={{ color: color }}
      width={width || 24}
    />
  );
};
export const ViewIcon = (props) => {
  const { width = 24, color = "#3e3e3e" } = props;
  return (
    <Icon
      icon="solar:eye-line-duotone"
      style={{ color: color }}
      width={width || 24}
    />
  );
};
export const GetMarketplaceList = (marketplaceList) =>
  marketplaceList.map((d, i) => {
    return {
      label: (
        <>
          <img
            src={`/assets/media/domainImage/${d?.marketplace_id}.png`}
            style={{ height: "20px" }}
            loading="lazy"
            className="me-3"
            onError={(e) => {
              e.target.src = "/assets/media/domainImage/red-flag.png";
            }}
          />
          {d?.marketplace}({d?.sales_channel})
        </>
      ),
      value: d?.marketplace_id,
    };
  });
export const GetMarketplaceDropdown = async () => {
  try {
    const response = await MakeApiCall(
      "marketplaces/get-marketplace-by-credential",
      "get",
      null,
      true
    );

    if (response?.status) {
      return {
        marketplace_list: response?.data?.marketplace_list,
        currency_list: response?.data?.currency_symbols,
      };
    } else {
      // Handle error case and notify
      message.destroy();
      message.error(response?.message || "Unknown error occurred");
      return null; // Use null to signify no data
    }
  } catch (error) {
    // Catch any unexpected errors
    message.destroy();
    message.error(error?.message || "An error occurred while fetching data");

    return null; // Use null to signify no data
  }
};

export const NumberWithCommas = (n) => {
  const num_ = parseFloat(n || 0);
  if (num_) {
    if (Number(num_) === num_ && num_ % 1 !== 0) {
      return parseFloat(num_)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseInt(num_)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return;
};

export const GetStatusOfCaseColor = (status) => {
  const data = {
    1: "magenta",
    2: "geekblue",
    3: "blue",
    4: "volcano",
    5: "purple",
    6: "red",
    7: "red",
    9: "green",
    10: "gold",
  };
  return data?.[status];
};

export const GetStatusOfCase = (status) => {
  const data = {
    1: "Pending",
    2: "Potential Reimbursement",
    3: "In progress",
    4: "Reconciled",
    5: "Reconciled Reimbursed",
    6: "Closed Reimbursed",
    7: "Closed",
    9: "Automatically Reimbursed",
    10: "Reconciled by Amazon",
  };
  return data?.[status];
};

export const DownloadFileUsingApi = (url, filename) => {
  axios({
    url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${store?.getState()?.user?.data?.token}`,
    },
  })
    .then((response) => {
      const blob = response.data;
      // setIsFileDownload(false);
      console.log(blob, "-----------------");
      if (validateBlobFile(blob)) {
        // Create a temporary URL for the blob
        const fileURL = URL.createObjectURL(blob);
        console.log(fileURL, "blob");
        // Create a link element
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${filename}.xlsx`; // Specify the desired filename

        // Simulate a click on the link to initiate the download
        message.destroy();
        message.success("File downloaded successfully");
        link.click();
        // Clean up the temporary URL
        URL.revokeObjectURL(fileURL);
      } else {
        message.destroy();
        handleBlobError(blob);
      }
    })
    .catch((error) => {
      // setIsFileDownload(false);
      console.log(error, "error");
      message.destroy();
      message.error(error?.message || "Error in file");
    });
};

function validateBlobFile(blob) {
  if (
    blob.type !==
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return false;
  }
  return true;
}

const handleBlobError = async (blob) => {
  try {
    const newBlob = await blob.text();
    console.log(newBlob, "blob");
    message.destroy();
    message.error(newBlob.split(".")?.[0]);
  } catch (error) {
    return "Invalid File";
    // Handle the error accordingly
  }
};
