import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Image,
  Input,
  message,
  Popover,
  Row,
  Switch,
  Tabs,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import SearchItem from "./search-item";
import Paragraph from "antd/es/typography/Paragraph";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  ConvertParams,
  DefaultPerPage,
  DeleteIcon,
  PaginationConfig,
  primaryColor,
} from "../../../../../../../../../config";
import EnterList from "./enter-list";
import { MakeApiCall } from "../../../../../../../../../apis";

const AddItems = (props) => {
  const { selectedProductList, setSelectedProductList, setCurrentStep } = props;
  const [selectedTab, setSelectedTab] = useState("search");
  const [productLoading, setProductLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [productTotalPage, setProductTotalPage] = useState(0);

  const [productFilter, setProductFilter] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search: "",
  });

  useEffect(() => {
    GetProductList(productFilter);
  }, []);

  const GetProductList = async (filters) => {
    setProductLoading(true);
    const response = await MakeApiCall(
      `report/product-list${ConvertParams(filters || {})}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setProductLoading(false);
      setProductList(response?.data?.records);
      setProductTotalPage(response?.data?.pagination?.totalCount);
    } else {
      message.destroy();
      message.error(response?.message);
    }
  };

  const items = [
    {
      key: "search",
      label: "Search",
    },
    {
      key: "enter_list",
      label: "Enter List",
    },
    {
      key: "upload",
      label: "Upload",
    },
  ];
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={12} xl={12} className="">
          <Card
            style={{ minHeight: "400px" }}
            title={
              <Title level={5} className="mb-0">
                Products
              </Title>
            }
          >
            <Row className="mb-4">
              <Col span={24}>
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  onChange={(key) => {
                    setSelectedTab(key);
                  }}
                />
              </Col>
            </Row>
            {selectedTab === "search" && (
              <SearchItem
                productLoading={productLoading}
                productList={productList}
                setProductFilter={setProductFilter}
                productFilter={productFilter}
                GetProductList={(filter) => {
                  GetProductList(filter);
                }}
                selectedProductList={selectedProductList}
                setSelectedProductList={setSelectedProductList}
                productTotalPage={productTotalPage}
                setProductLoading={setProductLoading}
              />
            )}
            {selectedTab === "enter_list" && <EnterList />}
          </Card>
        </Col>
        <Col sm={24} md={12} xl={12} className="">
          <Card
            style={{ minHeight: "400px" }}
            title={
              <Title level={5} className="mb-0">
                Selected Products - {selectedProductList?.length || 0}
              </Title>
            }
            extra={
              <Button
                disabled={selectedProductList?.length === 0}
                type="primary"
                onClick={() => {
                  setSelectedProductList([]);
                }}
              >
                Remove All
              </Button>
            }
          >
            <div style={{ height: "605px", overflowY: "auto" }}>
              <Row gutter={16} className="px-0 mx-0">
                {selectedProductList?.map((d) => {
                  return (
                    <Col span={24} className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <Image src={d?.image_url} style={{ width: "45px" }} />
                          <div className="ms-2">
                            <Popover
                              content={
                                <div
                                  style={{
                                    height: "max-content",
                                    maxWidth: "400px",
                                  }}
                                >
                                  {d?.item_name}
                                </div>
                              }
                              placement="bottomLeft"
                            >
                              <div className="singleLineEllipsis">
                                <Paragraph className="mb-0">
                                  {d?.item_name}
                                </Paragraph>
                              </div>
                            </Popover>

                            <div className="mt-1">
                              <Switch
                                size="small"
                                className="me-2"
                                checked={d?.state === "ENABLED" ? true : false}
                                onChange={(e) => {
                                  setSelectedProductList((prev) => {
                                    return prev?.map((a) => {
                                      if (a?.asin1 === d?.asin1) {
                                        return {
                                          ...d,
                                          state: e ? "ENABLED" : "DISABLED",
                                        };
                                      } else {
                                        return a;
                                      }
                                    });
                                  });
                                }}
                              />
                              <label>
                                ASIN:
                                <span className="text-primary">
                                  {d?.asin1}{" "}
                                </span>
                              </label>
                              <span className="mx-1">/</span>
                              <label>
                                SKU:
                                <span className="text-primary">
                                  {d?.seller_sku}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            onClick={() => {
                              const isDeleted = selectedProductList?.filter(
                                (f) => f?.asin1 !== d?.asin1
                              );
                              console.log(isDeleted, "-isDeleted");
                              setSelectedProductList(isDeleted);
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      </div>

                      <Divider variant="dashed" className="my-3" />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col span={24}>
          <Flex align="start" justify="space-between">
            <Button
              color="default"
              variant="solid"
              onClick={() => {
                setCurrentStep(0);
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              disabled={selectedProductList?.length === 0}
              onClick={() => {
                setCurrentStep(2);
              }}
            >
              Next
            </Button>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default AddItems;
