import {
  Collapse,
  DatePicker,
  Input,
  message,
  Radio,
  Select,
  Modal,
  Space,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

// import { MakeApiCall } from "../../../../apis/make-api-call";
import { DisplayNotification } from "../../../../../../config";
import {
  ruleCampaignDD,
  ruleKeywordDD,
  rulePortfolioDD,
} from "../../../../../../config/select-dropdown-list";
import { MakeApiCall } from "../../../../../../apis";
// import {
//   ruleCampaignDD,
//   ruleKeywordDD,
//   rulePortfolioDD,
// } from "../../../../config/select-dropdown-list";

function AddRuleBookModal(props) {
  const {
    show,
    onHide,
    campaign_list,
    dropdownList,
    currencySign,
    selectedMarketplace,
    rule_details,
    onSuccess,
  } = props;
  const { Panel } = Collapse;
  const [sending, setSending] = useState(false);
  const [CollapseKey, setCollapseKeyChange] = useState("");
  const [adGroupList, setAdGroupList] = useState([]);
  const [delete_sub_rules, set_delete_sub_rules] = useState([]);

  const defaultData = {
    id: 0,
    rule_name: "",
    rule_status: "ACTIVE",
    rule_type: null,
    filtered_by: {},
    bid_change_type: null, // then section
    bid_value: "", // then section
    max_bid: "", // then section
    criteria: [
      {
        attribute: "clicks",
        measurement_type: "in_total", // aveg, total
        operator: ">=",
        value: "1",
      },
    ],
    time_periods: {
      type: "date_range", // date_range, lookback_period, month_to_date, week_to_date
      days: null,
      delay_days: null,
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().add(30, "day").format("YYYY-MM-DD"),
    },
    exclude_campaigns: [],
  };
  const [details, setDetails] = useState({
    marketplace_id: selectedMarketplace,
    name: "",
    rule_mode: null, // auto-run, manual
    schedule_type: null,
    schedule_day: null,
    schedule_day_name: null,
    schedule_week_days: null,
    rule_status: "INACTIVE",
    campaign_list: [],
  });
  const [basicInfo, setBasicInfo] = useState([defaultData]);

  useEffect(() => {
    if (rule_details?.id) {
      console.log("rule_details", rule_details);
      const newData = {
        marketplace_id: selectedMarketplace,
        name: rule_details?.name,
        rule_mode: rule_details?.rule_mode, // auto-run, manual
        schedule_type: rule_details?.schedule_type,
        schedule_day: rule_details?.schedule_day,
        schedule_day_name: rule_details?.schedule_day_name,
        schedule_week_days: rule_details?.schedule_week_days,
        rule_status: rule_details?.rule_status,
        campaign_list: JSON.parse(rule_details?.campaign_list),
      };
      const newArray = JSON.parse(rule_details?.campaign_list)?.map((d) => {
        return {
          campaign_type: d?.campaign_type,
          campaign_name: d?.campaign_name,
          campaign_id: d?.campaign_id,
        };
      });
      setDetails({
        ...newData,
        campaign_list: newArray,
      });
      GetAdGroupList(newArray);

      const data = rule_details?.sub_rule_list?.map((d) => {
        return {
          id: d?.id,
          rule_name: d?.rule_name,
          rule_status: d?.rule_status,
          rule_type: d?.rule_type,
          filtered_by: d?.filtered_by ? JSON.parse(d?.filtered_by || {}) : {},
          bid_change_type: d?.bid_change_type, // then section
          bid_value: d?.bid_value, // then section
          max_bid: d?.max_bid, // then section
          criteria: JSON.parse(d?.criteria),
          time_periods: JSON.parse(d?.time_periods),
          exclude_campaigns: JSON.parse(d?.exclude_campaigns),
        };
      });
      if (data?.length > 0) {
        setBasicInfo(data);
      } else {
        setBasicInfo([defaultData]);
      }
    } else {
      const newArray = campaign_list?.map((d) => {
        return {
          campaign_type: d?.campaign_type,
          campaign_name: d?.campaign_name,
          campaign_id: d?.campaign_id,
        };
      });
      setDetails({
        ...details,
        campaign_list: newArray,
      });

      GetAdGroupList(newArray);
    }
  }, [rule_details]);

  const updateRuleNameByIndex = (index, newRuleName) => {
    // Create a copy of the basicInfo array to avoid mutating state directly
    const updatedBasicInfo = [...basicInfo];

    // Check if the index is within bounds
    if (index >= 0 && index < updatedBasicInfo.length) {
      updatedBasicInfo[index].rule_name = newRuleName;

      // Update the state with the new array
      setBasicInfo(updatedBasicInfo);
    } else {
      console.error(`Invalid index: ${index}`);
    }
  };

  const updateRuleTypeByIndex = (index, newRuleName) => {
    // Create a copy of the basicInfo array to avoid mutating state directly
    const updatedBasicInfo = [...basicInfo];

    // Check if the index is within bounds
    if (index >= 0 && index < updatedBasicInfo.length) {
      updatedBasicInfo[index].rule_type = newRuleName;

      // Update the state with the new array
      setBasicInfo(updatedBasicInfo);
    } else {
      console.error(`Invalid index: ${index}`);
    }
  };
  const updateFilterByIndex = (index, list) => {
    // Create a copy of the basicInfo array to avoid mutating state directly
    const updatedBasicInfo = [...basicInfo];

    // Check if the index is within bounds
    if (index >= 0 && index < updatedBasicInfo.length) {
      updatedBasicInfo[index].filtered_by = list;

      // Update the state with the new array
      setBasicInfo(updatedBasicInfo);
    } else {
      console.error(`Invalid index: ${index}`);
    }
  };

  const updateAttribute = (gIndex, index, newValue) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Update the 'attribute' field in the first criteria object
    updatedBasicInfo[gIndex].criteria[index].attribute = newValue;

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };

  const updateMeasurementType = (gIndex, index, newValue) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Update the 'attribute' field in the first criteria object
    updatedBasicInfo[gIndex].criteria[index].measurement_type = newValue;

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };

  const updateOperator = (gIndex, index, newValue) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Update the 'operator' field in the first criteria object
    updatedBasicInfo[gIndex].criteria[index].operator = newValue;

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };

  const updateValue = (gIndex, index, newValue) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Update the 'value' field in the first criteria object
    updatedBasicInfo[gIndex].criteria[index].value = newValue;

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };

  const addCriteria = (index) => {
    const newCriteria = {
      attribute: null,
      operator: null,
      value: null,
    };

    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Add the new criteria object to the cloned array
    updatedBasicInfo[index].criteria.push(newCriteria);

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };

  const removeCriteria = (gIndex, index) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Remove the criteria object at the specified index
    updatedBasicInfo[gIndex].criteria.splice(index, 1);

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };

  const updateBidChangeType = (gIndex, change_type) => {
    setBasicInfo((prevBasicInfo) => {
      // Create a copy of the previous state
      const updatedBasicInfo = [...prevBasicInfo];

      // Update the max_bid in the first element of the array (assuming you want to update the first element)
      updatedBasicInfo[gIndex].bid_change_type = change_type;

      // Return the updated state
      return updatedBasicInfo;
    });
  };
  const updateBidChangeValue = (gIndex, value) => {
    setBasicInfo((prevBasicInfo) => {
      // Create a copy of the previous state
      const updatedBasicInfo = [...prevBasicInfo];

      // Update the max_bid in the first element of the array (assuming you want to update the first element)
      updatedBasicInfo[gIndex].bid_value = value;

      // Return the updated state
      return updatedBasicInfo;
    });
  };
  const updateBidMinMaxValue = (gIndex, value) => {
    setBasicInfo((prevBasicInfo) => {
      // Create a copy of the previous state
      const updatedBasicInfo = [...prevBasicInfo];

      // Update the max_bid in the first element of the array (assuming you want to update the first element)
      updatedBasicInfo[gIndex].max_bid = value;

      // Return the updated state
      return updatedBasicInfo;
    });
  };

  const updateTimePeriodsStartDate = (gIndex, date) => {
    setBasicInfo((prevBasicInfo) => {
      const updatedBasicInfo = [...prevBasicInfo];
      updatedBasicInfo[gIndex].time_periods.start_date = date;
      return updatedBasicInfo;
    });
  };
  const updateTimePeriodsEndDate = (gIndex, date) => {
    setBasicInfo((prevBasicInfo) => {
      const updatedBasicInfo = [...prevBasicInfo];
      updatedBasicInfo[gIndex].time_periods.end_date = date;
      return updatedBasicInfo;
    });
  };

  const updateRunEvery = (gIndex, type, value) => {
    setBasicInfo((prev) => {
      return prev?.map((d, i) =>
        i === gIndex
          ? { ...d, frequency: { ...d?.frequency, [type]: value } }
          : d
      );
    });
  };

  const CreateRuleAction = async (data) => {
    setSending(true);
    const method = rule_details?.id ? "put" : "post";
    const path = rule_details?.id
      ? `rule-management/update-rule/${rule_details?.id}`
      : `rule-management/create-rule`;
    const response = await MakeApiCall(path, method, data, true);
    if (response?.status) {
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
      onSuccess();
      onHide();
    } else {
      message.destroy();
      setSending(false);
      if (Object.keys(response?.error || {})?.length > 0) {
        if (typeof Object.values(response?.error)?.[0]?.[0] === "string") {
          message.error(`${Object.values(response?.error)?.[0] || ""}`);
        } else {
          message.error(
            `Sub rule - ${
              Object?.values(Object.values(response?.error)?.[0])?.[0]?.[0] ||
              ""
            }`
          );
        }
      } else {
        DisplayNotification("error", "Error", response?.message);
      }
    }
  };

  const GetAdGroupList = async (campaign_list) => {
    const data = {
      marketplace_id: selectedMarketplace,
      listing_type: "manual_campaign", // all_campaign, manual_campaign
      campaign_list: campaign_list?.map((d) => {
        return {
          campaign_type: d?.campaign_type,
          campaign_id: d?.campaign_id,
        };
      }),
    };
    const response = await MakeApiCall(
      `advertising/get-ad-group-list`,
      `post`,
      data,
      true
    );
    if (response?.status) {
      const data = response?.data?.map((d) => {
        const newObj = d?.ad_group_list?.map((al) => {
          return { ...al, campaign_name: d?.campaign_name };
        });
        return newObj;
      });
      setAdGroupList(data?.flat() || []);
    } else {
      // DisplayNotification("error", "Error", response?.message);
    }
  };

  const checkValidation = (details, basicInfo) => {
    const showError = (msg) => {
      message.destroy();
      message.error(msg);
      return false;
    };

    // Validate details object
    if (!details?.name) return showError("Please enter master rule name");
    if (details?.campaign_list?.length === 0)
      return showError("Please enter at least one campaign");
    if (!details?.rule_mode) return showError("Please enter rule mode");
    if (!details?.schedule_type) return showError("Please enter schedule type");

    if (details?.schedule_type === "custom_schedule") {
      if (!details?.schedule_day) return showError("Please enter schedule day");
      if (!details?.schedule_day_name)
        return showError("Please enter schedule day name");
      if (
        details?.schedule_day_name === "weeks" &&
        !details?.schedule_week_days
      ) {
        return showError("Please enter day list");
      }
    }

    // Validate basicInfo array
    if (basicInfo?.length > 0) {
      for (const bi of basicInfo) {
        if (!bi?.rule_name) return showError("Please enter sub rule name");
        if (!bi?.rule_type) return showError(`Please enter sub rule type`);

        // Validate criteria inside basicInfo
        if (bi?.criteria?.length > 0) {
          for (const c of bi.criteria) {
            if (!c?.attribute)
              return showError(
                `Please select criteria metrics of sub rule - ${bi?.rule_name}`
              );
            if (!c?.measurement_type)
              return showError(
                `Please select measurement type of sub rule - ${bi?.rule_name}`
              );
            if (!c?.operator)
              return showError(
                `Please select criteria condition of sub rule - ${bi?.rule_name}`
              );
            if (!c?.value)
              return showError(
                `Please enter criteria value of sub rule - ${bi?.rule_name}`
              );
          }
        }

        if (!bi?.bid_change_type)
          return showError(
            `Please select action of sub rule - ${bi?.rule_name}`
          );
        if (!bi?.bid_value)
          return showError(
            `Please enter action value of sub rule - ${bi?.rule_name}`
          );
        if (!bi?.max_bid)
          return showError(
            `Please enter action max value of sub rule - ${bi?.rule_name}`
          );

        // Validate time_periods
        const { type, start_date, end_date, days, delay_days } =
          bi?.time_periods || {};
        if (type === "date_range" && (!start_date || !end_date))
          return showError(
            `Please select date range of sub rule - ${bi?.rule_name}`
          );
        if (type === "lookback_period" && (!days || !delay_days))
          return showError(
            `Please select lookback period of sub rule - ${bi?.rule_name}`
          );
      }
    }

    // If everything is valid
    return true;
  };

  return (
    <Modal open={show} onCancel={onHide} size="lg" centered backdrop="static">
      <div style={{ minHeight: "600px" }}>
        <div className="row mb-5">
          <div className="col-12 col-md-4">
            <div className="position-relative mr-4">
              <label className="fw-bolder mb-1 text-primary">
                Master Rule Name
              </label>
              <Input
                placeholder="Enter Master Rule Name"
                type="text"
                size="large"
                className="fs-7"
                value={details?.name}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    name: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="position-relative mr-4">
              <label className="fw-bolder mb-1 text-primary">Campaign</label>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                //   loading={allCampaignListLoading}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                size="large"
                allowClear
                value={
                  details?.campaign_list &&
                  details?.campaign_list?.map((d) => d?.campaign_id)
                }
                onChange={(e, _) => {
                  const newArray = [];
                  _.forEach((item) => {
                    newArray.push({
                      campaign_type: item?.campaign_type,
                      campaign_name: item.label,
                      campaign_id: item.value,
                    });
                  });
                  console.log(newArray, "newArray");
                  setDetails({
                    ...details,
                    campaign_list: newArray,
                  });
                }}
                onBlur={() => {
                  GetAdGroupList(details?.campaign_list);
                }}
                placeholder="Select Campaigns"
                options={campaign_list?.map((d) => {
                  return {
                    label: d?.campaign_name,
                    value: d?.campaign_id,
                    ...d,
                  };
                })}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="position-relative mr-4">
              <label className="fw-bolder mb-1 text-primary">Rule Mode</label>
              <Select
                style={{ width: "100%" }}
                placeholder="Select Rule Mode"
                allowClear
                size="large"
                options={[
                  { label: "Auto Run", value: "auto-run" },
                  { label: "Manual", value: "manual" },
                ]}
                value={details?.rule_mode}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    rule_mode: e,
                  });
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </div>
          </div>
        </div>
        {/* <div className="row mb-5"></div> */}
        <div className="row mb-5">
          <div className="col-12">
            <div style={{ borderBottom: "1px solid #80808036" }}>
              <label className="fw-bolder mb-1 text-primary">Schedule</label>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-4">
            <div className="position-relative mr-4">
              <label className="fw-bolder mb-1">Run this rulebook</label>
              <Select
                style={{ width: "100%" }}
                placeholder="Select Rule Mode"
                size="large"
                allowClear
                options={[
                  { label: "Daily", value: "daily" },
                  {
                    label: "On a custom schedule",
                    value: "custom_schedule",
                  },
                ]}
                value={details?.schedule_type}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    schedule_type: e,
                    schedule_day: null,
                    schedule_day_name: null,
                    schedule_week_days: null,
                  });
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </div>
          </div>
          {details?.schedule_type !== "daily" && (
            <>
              <div className="col-4">
                <div className="position-relative mr-4">
                  <label className="fw-bolder mb-1">Every</label>
                  <div className="d-flex justify-content-between">
                    <Select
                      style={{ width: "45%" }}
                      placeholder="Select"
                      size="large"
                      allowClear
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                        { label: "6", value: "6" },
                        { label: "7", value: "7" },
                        { label: "8", value: "8" },
                        { label: "9", value: "9" },
                        { label: "10", value: "10" },
                      ]}
                      value={details?.schedule_day}
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          schedule_day: e,
                        });
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    />
                    <Select
                      style={{ width: "45%" }}
                      placeholder="Select"
                      size="large"
                      allowClear
                      options={[
                        { label: "Days", value: "days" },
                        { label: "Weeks", value: "weeks" },
                      ]}
                      value={details?.schedule_day_name}
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          schedule_day_name: e,
                        });
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    />
                  </div>
                </div>
              </div>
              {details?.schedule_day_name === "weeks" && (
                <div className="col-4">
                  <div className="position-relative mr-4">
                    <label className="fw-bolder mb-1">Day list</label>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Days"
                      mode="multiple"
                      maxTagCount="responsive"
                      size="large"
                      options={[
                        { label: "Sunday", value: "sunday" },
                        { label: "Monday", value: "monday" },
                        { label: "Tuesday", value: "tuesday" },
                        { label: "Wednesday", value: "wednesday" },
                        { label: "Thursday", value: "thursday" },
                        { label: "Friday", value: "friday" },
                        { label: "Saturday", value: "saturday" },
                      ]}
                      value={details?.schedule_week_days}
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          schedule_week_days: e,
                        });
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="separator separator-dashed my-4" />

        <div style={{ height: "500px", overflow: "auto" }}>
          {basicInfo?.map((d, i) => {
            return (
              <div
                style={{
                  padding: "10px",
                  borderRadius: "7px",
                  border: "1px dashed #b3b3b3",
                  marginBottom: "10px",
                }}
              >
                <div className="row">
                  <div className="col-12 d-flex justify-content-end align-items-end">
                    {basicInfo?.length < 10 && (
                      <span
                        onClick={() => {
                          if (basicInfo?.length < 11) {
                            let basicInfo_ = basicInfo;
                            basicInfo_?.push(defaultData);
                            setBasicInfo([...basicInfo_]);
                          }
                        }}
                        className="btn btn-light-info btn-sm btn-icon me-3"
                      >
                        <i className="fa fa-plus" />
                      </span>
                    )}
                    {basicInfo?.length > 1 && (
                      <span
                        href
                        className="btn btn-light-danger btn-sm btn-icon"
                        onClick={() => {
                          if (d?.id) {
                            set_delete_sub_rules([...delete_sub_rules, d?.id]);
                            setBasicInfo(basicInfo?.filter((d, o) => o !== i));
                          } else {
                            setBasicInfo(basicInfo?.filter((d, o) => o !== i));
                          }
                        }}
                      >
                        <i className="fa fa-minus" />
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="position-relative">
                      <label className="fw-bolder mb-1 text-primary">
                        Sub Rule Name&nbsp;{i + 1}
                      </label>

                      <Input
                        placeholder="Enter text"
                        size="large"
                        style={{ width: "100%" }}
                        value={d?.rule_name || null}
                        onChange={(e) => {
                          updateRuleNameByIndex(i, e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="position-relative">
                      <label className="fw-bolder mb-1 text-primary">
                        Rule Type
                      </label>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Targeting Type"
                        size="large"
                        options={[
                          {
                            label: "Campaigns",
                            value: "campaigns",
                          },
                          { label: "Portfolios", value: "portfolios" },
                          { label: "Keywords", value: "keywords" },
                          { label: "Ad Groups", value: "ad_gorups" },
                          { label: "Targets", value: "targets" },
                        ]}
                        value={d?.rule_type}
                        allowClear
                        onChange={(e) => {
                          updateRuleTypeByIndex(i, e);
                          if (
                            e === "ad_gorups" &&
                            details?.campaign_list?.length > 0
                          ) {
                            GetAdGroupList(details?.campaign_list);
                          } else {
                            updateFilterByIndex(i, []);
                          }
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                      />
                    </div>
                  </div>

                  {d?.rule_type === "ad_gorups" && (
                    <div className="col-4">
                      <div className="position-relative">
                        <label className="fw-bolder mb-1 text-primary">
                          Select adgroup
                        </label>
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Targeting Type"
                          size="large"
                          mode="multiple"
                          maxTagCount={"responsive"}
                          options={adGroupList?.map((al) => {
                            console.log(al, "al");
                            return {
                              ...al,
                              label: al?.name,
                              value: al?.ad_group_id,
                            };
                          })}
                          value={d?.filtered_by?.ad_gorups?.map(
                            (fb) => fb?.adgroup_id
                          )}
                          onChange={(e, _) => {
                            const data = _?.map((list) => {
                              return {
                                adgroup_id: list?.ad_group_id,
                                adgroup_name: list?.name,
                                campaign_id: list?.campaign_id,
                                campaign_name: list?.campaign_name,
                              };
                            });
                            const myData = { ad_group: data };
                            updateFilterByIndex(i, myData);
                          }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="separator separator-dashed my-4" />
                <Collapse
                  activeKey={[CollapseKey]}
                  accordion={true}
                  onChange={(key) => {
                    setCollapseKeyChange(key);
                  }}
                  className="border border-gray-300 bg-gray-100 border-dashed rounded mb-2 commonCollapseDiv"
                >
                  <Panel
                    header={
                      <>
                        <h3 className="fs-4 fw-semibold mb-0 ms-4">Criteria</h3>
                      </>
                    }
                    key={`criteria_${i}`}
                  >
                    <>
                      <div className="mt-3">
                        {d?.criteria?.map((s, j) => (
                          <div className="row g-9 mb-3 align-items-center ">
                            <div className="col-md-1 text-center">
                              <label className=" fs-5 fw-bold mb-0 d-flex">
                                {j !== 0 && <div>And</div>}&nbsp;
                                <div>If</div>
                              </label>
                            </div>
                            <div className="col-md-3">
                              <Select
                                allowClear
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                value={s?.attribute}
                                options={Object?.entries(
                                  dropdownList?.attributes_dd_data || {}
                                )?.map(([key, value], i) => ({
                                  label: value,
                                  value: key,
                                }))}
                                onChange={(e) => updateAttribute(i, j, e)}
                                style={{ width: "100%" }}
                              />
                            </div>
                            <div className="col-md-2 px-0">
                              <Select
                                allowClear
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                value={s?.measurement_type}
                                options={[
                                  { label: "In total", value: "in_total" },
                                  {
                                    label: "On average per day",
                                    value: "aveg",
                                  },
                                ]}
                                onChange={(e) => updateMeasurementType(i, j, e)}
                                style={{ width: "100%" }}
                              />
                            </div>
                            <div className="col-md-2">
                              <Select
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                allowClear
                                value={s?.operator}
                                onChange={(e) => updateOperator(i, j, e)}
                                options={Object?.entries(
                                  dropdownList?.operators_dd_data || {}
                                )?.map(([key, value], i) => ({
                                  label: value,
                                  value: key,
                                }))}
                                style={{ width: "100%" }}
                              />
                            </div>
                            <div className="col-md-2 ps-0">
                              <Input
                                value={s?.value}
                                onChange={(e) => {
                                  const inputValue = e.target.value;

                                  const isNonNegativeNumber =
                                    /^(\d*\.?\d+|\d+\.?\d*)$/.test(inputValue);

                                  if (
                                    isNonNegativeNumber ||
                                    inputValue === ""
                                  ) {
                                    updateValue(i, j, inputValue);
                                  }
                                }}
                              />
                            </div>
                            <div className="col-md-2 px-0 ">
                              <div className="d-flex">
                                {d?.criteria?.length < 5 && (
                                  <span
                                    onClick={() => {
                                      addCriteria(i);
                                    }}
                                    className="btn btn-light-info btn-sm btn-icon me-3"
                                  >
                                    <i className="fa fa-plus" />
                                  </span>
                                )}
                                {d?.criteria?.length > 1 && (
                                  <span
                                    className="btn btn-light-danger btn-sm btn-icon"
                                    onClick={() => {
                                      removeCriteria(i, j);
                                    }}
                                  >
                                    <i className="fa fa-minus" />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  </Panel>
                </Collapse>
                <Collapse
                  activeKey={[CollapseKey]}
                  accordion={true}
                  onChange={(key) => {
                    setCollapseKeyChange(key);
                  }}
                  className="border border-gray-300 bg-gray-100 border-dashed rounded mb-2 commonCollapseDiv"
                >
                  <Panel
                    header={
                      <>
                        <h3 className="fs-4 fw-semibold mb-0 ms-4">Action</h3>
                      </>
                    }
                    key={`action_${i}`}
                  >
                    <>
                      <div className="row">
                        <div className="col-4">
                          <Select
                            placeholder="Select Action"
                            onChange={(e) => {
                              updateBidChangeType(i, e);
                            }}
                            value={d?.bid_change_type}
                            allowClear
                            options={
                              d?.rule_type === "campaigns"
                                ? ruleCampaignDD
                                : d?.rule_type === "portfolios"
                                ? rulePortfolioDD
                                : ruleKeywordDD
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            style={{ width: "100%" }}
                          />
                        </div>
                        {d?.bid_change_type !== "paused" &&
                        d?.bid_change_type !== "enabled" &&
                        d?.bid_change_type !== "do_nothing" ? (
                          <>
                            <div className="col-4">
                              <Input
                                placeholder="Enter value"
                                onChange={(e) => {
                                  updateBidChangeValue(i, e.target.value);
                                }}
                                value={d?.bid_value}
                                style={{ width: "100%" }}
                                size="medium"
                              />
                            </div>
                            <div className="col-4">
                              <Input
                                placeholder={`Enter ${
                                  d?.bid_change_type ===
                                    "decrease_bids_percent" ||
                                  d?.bid_change_type === "decrease_bids_value"
                                    ? "min"
                                    : "max"
                                } value`}
                                onChange={(e) => {
                                  updateBidMinMaxValue(i, e.target.value);
                                }}
                                value={d?.max_bid}
                                style={{ width: "100%" }}
                                size="medium"
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  </Panel>
                </Collapse>
                <Collapse
                  activeKey={[CollapseKey]}
                  accordion={true}
                  onChange={(key) => {
                    setCollapseKeyChange(key);
                  }}
                  className="border border-gray-300 bg-gray-100 border-dashed rounded mb-2 commonCollapseDiv"
                >
                  <Panel
                    header={
                      <>
                        <h3 className="fs-4 fw-semibold mb-0 ms-4">
                          Time Periods
                        </h3>
                      </>
                    }
                    key={`time_period_${i}`}
                  >
                    <>
                      <div className="mt-5">
                        <Radio.Group
                          value={d?.time_periods?.type}
                          onChange={(e) => {
                            if (e.target.value === "date_range") {
                              setBasicInfo((prevBasicInfo) => {
                                const newObj = {
                                  type: "date_range", // date_range, lookback_period, month_to_date, week_to_date
                                  days: 0,
                                  delay_days: 0,
                                  start_date: dayjs().format("YYYY-MM-DD"),
                                  end_date: dayjs()
                                    .add(30, "d")
                                    .format("YYYY-MM-DD"),
                                };
                                const updatedBasicInfo = [...prevBasicInfo];
                                updatedBasicInfo[i].time_periods = newObj;
                                return updatedBasicInfo;
                              });
                            } else if (e.target.value === "lookback_period") {
                              setBasicInfo((prevBasicInfo) => {
                                const newObj = {
                                  type: "lookback_period", // date_range, lookback_period, month_to_date, week_to_date
                                  days: 0,
                                  delay_days: 0,
                                  start_date: null,
                                  end_date: null,
                                };
                                const updatedBasicInfo = [...prevBasicInfo];
                                updatedBasicInfo[i].time_periods = newObj;
                                return updatedBasicInfo;
                              });
                              updateTimePeriodsStartDate(i, "");
                              updateTimePeriodsEndDate(i, "");
                            } else if (
                              e.target.value === "month_to_date" ||
                              e.target.value === "week_to_date"
                            ) {
                              setBasicInfo((prevBasicInfo) => {
                                const newObj = {
                                  type: e.target.value, // date_range, lookback_period, month_to_date, week_to_date
                                  days: 0,
                                  delay_days: 0,
                                  start_date: null,
                                  end_date: null,
                                };
                                const updatedBasicInfo = [...prevBasicInfo];
                                updatedBasicInfo[i].time_periods = newObj;
                                return updatedBasicInfo;
                              });
                              updateTimePeriodsStartDate(i, "");
                              updateTimePeriodsEndDate(i, "");
                            }
                          }}
                          name="Time_Periods"
                        >
                          <Space direction="vertical">
                            <Radio value={"date_range"}>
                              <div className="row align-items-center">
                                <div className="col-12 px-5">
                                  <div className="">
                                    <label className="fw-bolder mb-0 fs-6 me-3">
                                      Date Range
                                    </label>
                                    <div className="position-relative">
                                      <DatePicker.RangePicker
                                        disabled={
                                          d?.time_periods?.type !== "date_range"
                                        }
                                        value={
                                          d?.time_periods?.start_date &&
                                          d?.time_periods?.end_date
                                            ? [
                                                dayjs(
                                                  d?.time_periods?.start_date,
                                                  "YYYY-MM-DD"
                                                ),
                                                dayjs(
                                                  d?.time_periods?.end_date,
                                                  "YYYY-MM-DD"
                                                ),
                                              ]
                                            : []
                                        }
                                        onChange={(e) => {
                                          updateTimePeriodsStartDate(
                                            i,
                                            e
                                              ? dayjs(e?.[0]).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : ""
                                          );
                                          updateTimePeriodsEndDate(
                                            i,
                                            e
                                              ? dayjs(e?.[1]).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : ""
                                          );
                                        }}
                                        getPopupContainer={(triggerNode) =>
                                          triggerNode.parentNode
                                        }
                                        suffixIcon={
                                          <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                                            <span className="path1" />
                                            <span className="path2" />
                                            <span className="path3" />
                                            <span className="path4" />
                                            <span className="path5" />
                                            <span className="path6" />
                                          </i>
                                        }
                                        className=" text-gray-600 fw-bold"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Radio>
                            <div className="separator separator-dashed"></div>
                            <Radio value={"lookback_period"}>
                              <>
                                <div className="row  align-items-center">
                                  <div className="col-12 px-5">
                                    <div className="">
                                      <label className="fw-bolder mb-0 fs-6 me-3">
                                        Lookback period
                                      </label>
                                      <div className="d-flex ">
                                        <div>
                                          <label className="fw-bolder mb-0 fs-6 me-3">
                                            Days
                                          </label>
                                          <Select
                                            onClick={(e) => {
                                              e.preventDefault();
                                            }}
                                            disabled={
                                              d?.time_periods?.type !==
                                              "lookback_period"
                                            }
                                            onChange={(e) => {
                                              setBasicInfo((prevBasicInfo) => {
                                                const updatedBasicInfo = [
                                                  ...prevBasicInfo,
                                                ];
                                                updatedBasicInfo[
                                                  i
                                                ].time_periods.days = e;

                                                return updatedBasicInfo;
                                              });
                                            }}
                                            value={
                                              d?.time_periods?.days || null
                                            }
                                            style={{ width: "200px" }}
                                            placeholder="Select"
                                            options={Array(90)
                                              .fill(null)
                                              .map((d, i) => {
                                                return {
                                                  label: i + 1,
                                                  value: i + 1,
                                                };
                                              })}
                                            allowClear
                                            getPopupContainer={(triggerNode) =>
                                              triggerNode.parentNode
                                            }
                                          />
                                        </div>
                                        <div className="ms-4">
                                          <label className="fw-bolder mb-0 fs-6 me-3">
                                            Delay days
                                          </label>
                                          <Select
                                            onClick={(e) => {
                                              e.preventDefault();
                                            }}
                                            disabled={
                                              d?.time_periods?.type !==
                                              "lookback_period"
                                            }
                                            onChange={(e) => {
                                              setBasicInfo((prevBasicInfo) => {
                                                const updatedBasicInfo = [
                                                  ...prevBasicInfo,
                                                ];
                                                updatedBasicInfo[
                                                  i
                                                ].time_periods.delay_days = e;

                                                return updatedBasicInfo;
                                              });
                                            }}
                                            value={
                                              d?.time_periods?.delay_days ||
                                              null
                                            }
                                            style={{ width: "200px" }}
                                            placeholder="Select"
                                            options={Array(90)
                                              .fill(null)
                                              .map((d, i) => {
                                                return {
                                                  label: i + 1,
                                                  value: i + 1,
                                                };
                                              })}
                                            allowClear
                                            getPopupContainer={(triggerNode) =>
                                              triggerNode.parentNode
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            </Radio>
                            <div className="separator separator-dashed"></div>
                            <Radio value={"month_to_date"}>
                              <>
                                <div className="row">
                                  <div className="col-12 px-5">
                                    <label className="fw-bolder mb-0 fs-6 me-3">
                                      Month to date
                                    </label>
                                  </div>
                                </div>
                              </>
                            </Radio>
                            <div className="separator separator-dashed"></div>
                            <Radio value={"week_to_date"}>
                              <>
                                <div className="row">
                                  <div className="col-12 px-5">
                                    <label className="fw-bolder mb-0 fs-6 me-3">
                                      Week to date
                                    </label>
                                  </div>
                                </div>
                              </>
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </div>
                    </>
                  </Panel>
                </Collapse>
                <Collapse
                  activeKey={[CollapseKey]}
                  accordion={true}
                  onChange={(key) => {
                    setCollapseKeyChange(key);
                  }}
                  className="border border-gray-300 bg-gray-100 border-dashed rounded mb-2 commonCollapseDiv"
                >
                  <Panel
                    header={
                      <>
                        <h3 className="fs-4 fw-semibold mb-0 ms-4">
                          Exclude Campaign
                        </h3>
                      </>
                    }
                    key={`exclude_campaign_${i}`}
                  >
                    <>
                      <div className="row">
                        <div className="col-6">
                          <div className="position-relative mr-4">
                            <label className="fw-bolder mb-1 text-primary">
                              Campaign
                            </label>
                            <Select
                              mode="multiple"
                              maxTagCount="responsive"
                              //   loading={allCampaignListLoading}
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: "100%" }}
                              size="large"
                              value={d?.exclude_campaigns?.map(
                                (d) => d?.campaign_id
                              )}
                              onChange={(e, _) => {
                                const newArray = [];
                                _.forEach((item) => {
                                  newArray.push({
                                    campaign_type: item?.campaign_type,
                                    campaign_name: item.label,
                                    campaign_id: item.value,
                                  });
                                });
                                setBasicInfo((prevBasicInfo) => {
                                  // Create a copy of the previous state
                                  const updatedBasicInfo = [...prevBasicInfo];
                                  // Update the max_bid in the first element of the array (assuming you want to update the first element)
                                  updatedBasicInfo[i].exclude_campaigns =
                                    newArray;
                                  // Return the updated state
                                  return updatedBasicInfo;
                                });
                              }}
                              placeholder="Select Campaigns"
                              options={campaign_list?.map((d) => {
                                return {
                                  label: d?.campaign_name,
                                  value: d?.campaign_id,
                                  ...d,
                                };
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  </Panel>
                </Collapse>
              </div>
            );
          })}
        </div>
      </div>

      <button type="button" class="btn btn-light-danger fs-7" onClick={onHide}>
        Close
      </button>
      <button
        type="button"
        class="btn btn-primary fs-7"
        disabled={sending}
        onClick={() => {
          console.log(basicInfo, "-basicInfo");
          console.log(
            {
              ...details,
              marketplace_id: selectedMarketplace,
              sub_rules: basicInfo,
              delete_sub_rules: delete_sub_rules,
            },
            "finalJson"
          );
          const data = {
            ...details,
            marketplace_id: selectedMarketplace,
            sub_rules: basicInfo,
            delete_sub_rules: delete_sub_rules,
          };

          const isValid = checkValidation(details, basicInfo);
          // console.log(isValid, "isValid");
          if (isValid) {
            setSending(true);
            CreateRuleAction(data);
          }
        }}
      >
        {sending ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          </span>
        ) : (
          <span className="indicator-label">Save Rule</span>
        )}
      </button>
    </Modal>
  );
}

export default AddRuleBookModal;
