import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  Card,
  Col,
  Flex,
  message,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import {
  ConvertParams,
  DefaultPerPage,
  GetMarketplaceDropdown,
  GetMarketplaceList,
} from "../../../../config";
import { MakeApiCall } from "../../../../apis";
const Text = Typography;
const RecommendationAlerts = () => {
  const [alert_list, set_alert_list] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allProductList, setAllProductList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isProductListLoading, setIsProductListLoading] = useState(false);
  const [selectedType, setselectedType] = useState("");
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");

  const [product_list_filter, set_product_list_filter] = useState({
    // marketplace_id: "ATVPDKIKX0DER",
    page: 1,
    "per-page": DefaultPerPage,
    type: "",
    marketplace_id: null,
  });

  useEffect(() => {
    GetMarketplace();
  }, []);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_product_list_filter({
        ...product_list_filter,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetAlertsList(marketplace?.marketplace_list[0]?.marketplace_id);
    } else {
      setLoading(false);
    }
  };

  const GetAlertsList = async (marketplace_id) => {
    setLoading(true);
    const response = await MakeApiCall(
      `recommendation-alerts?marketplace_id=${marketplace_id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_alert_list(response?.data);
    } else {
      setLoading(false);
      message.destroy();
      message.error(response?.message);
    }
  };

  const GetProductList = async (product_list_filter) => {
    const response = await MakeApiCall(
      `recommendation-alerts/product-list${ConvertParams(product_list_filter)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      message.destroy();
      setAllProductList(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
      setIsProductListLoading(false);
      setIsOpenModal(true);
    } else {
      setIsProductListLoading(false);
      message.destroy();
      message.error(response?.message);
    }
  };

  const data = [
    {
      type: "Out of Stock",
      key_name: "out_of_stock",
      icon: (
        <img src="/assets/media/out-of-stock.png" style={{ width: "30px" }} />
      ),
      color: "#8d2ce1",
    },
    {
      type: "Non-Advertised",
      key_name: "non_advertised",
      icon: <img src="/assets/media/megaphone.png" style={{ width: "30px" }} />,
      color: "#f2235f",
    },
    {
      type: "BuyBox Affected Products",
      key_name: "buyBox_affected_products",
      icon: <img src="/assets/media/box.png" style={{ width: "30px" }} />,
      color: "#11b391",
    },
    {
      type: "Different SKUs of Same ASINs Not Being Advertised",
      key_name: "diff_sku_of_same_asin",
      icon: <img src="/assets/media/no-ads.png" style={{ width: "30px" }} />,
      color: "#f7981e",
    },
    {
      type: "Ineligible Ads",
      key_name: "ineligible_ads",
      icon: (
        <img src="/assets/media/advertisement.png" style={{ width: "30px" }} />
      ),
      color: "#84c64a",
    },
    {
      type: "Campaigns Getting Rejected",
      key_name: "campaigns_rejected",
      icon: <img src="/assets/media/file.png" style={{ width: "30px" }} />,
      color: "#f7981e",
    },
    {
      type: "Payment/CC Issue in Advertised Account",
      key_name: "payment_billing_notifications",
      icon: (
        <img src="/assets/media/credit-card.png" style={{ width: "30px" }} />
      ),
      color: "#527ca9",
    },
    {
      type: "ACoS Increasing by Higher Margin",
      key_name: "acos_increasing_by_higher_margin",
      icon: <img src="/assets/media/increase.png" style={{ width: "30px" }} />,
      color: "#f2235f",
    },
    {
      type: "Getting Negative Product Reviews",
      key_name: "negative_product_review",
      icon: <img src="/assets/media/criticism.png" style={{ width: "30px" }} />,
      color: "#4b01e0",
    },
    {
      type: "Negative Seller Feedbacks",
      key_name: "negative_seller_feedbacks",
      icon: <img src="/assets/media/sad.png" style={{ width: "30px" }} />,
      color: "#fc5a15",
    },
    {
      type: "Advertising Performance Reduced (Trend)",
      key_name: "adv_performance_reduced",
      icon: (
        <img
          src="/assets/media/increase_decrease.png"
          style={{ width: "30px", transform: "rotate(90deg)" }}
        />
      ),
      color: "#956aa9",
    },
    {
      type: "Product Issue Duration (OOS, Suppressed, Blocked, Stranded)",
      key_name: "product_issue_duration",
      icon: <img src="/assets/media/time.png" style={{ width: "30px" }} />,
      color: "#84c64a",
    },
    {
      type: "Sales Price Changes",
      key_name: "sales_price_changes",
      icon: <img src="/assets/media/coin.png" style={{ width: "30px" }} />,
      color: "#f2235f",
    },
  ];

  return (
    <Wrapper>
      <Row>
        <Col span={24}>
          <Card
            title={<Text strong>Recommendation & Alerts</Text>}
            extra={
              <Select
                className="w-200px"
                placeholder="Select Marketplace"
                options={GetMarketplaceList(marketplace_list)}
                value={selected_marketplace}
                onChange={(e) => {
                  set_selected_marketplace(e);
                  set_selected_currency_sign(currency_list[e]);
                  set_product_list_filter({
                    ...product_list_filter,
                    marketplace_id: e,
                  });
                  setLoading(true);
                  GetAlertsList(e);
                }}
              />
            }
          >
            {loading ? (
              <Row gutter={[16, 16]}>
                {[...Array(11)].map((d) => {
                  return (
                    <>
                      {" "}
                      <Col span={2}>
                        {" "}
                        <Skeleton.Button
                          active
                          block
                          // style={{ height: "50px" }}
                        />
                      </Col>
                      <Col span={18}>
                        {" "}
                        <Skeleton.Button
                          active
                          block
                          // style={{ height: "50px" }}
                        />
                      </Col>
                      <Col span={4}>
                        {" "}
                        <Skeleton.Button
                          active
                          block
                          // style={{ height: "50px" }}
                        />
                      </Col>
                    </>
                  );
                })}
              </Row>
            ) : (
              data?.map((d) => {
                return (
                  <Row
                    onClick={() => {
                      if (alert_list?.[d?.key_name] > 0) {
                        const apiObj = {
                          ...product_list_filter,
                          type: d?.key_name,
                        };
                        setselectedType(d?.key_name);
                        set_product_list_filter(apiObj);
                        setIsProductListLoading(true);
                        message.destroy();
                        message.loading("Loading...", 0);
                        GetProductList(apiObj);
                      } else {
                        message.destroy();
                        message.info("No Products Found");
                      }
                    }}
                    style={{
                      pointerEvents: isProductListLoading ? "none" : "auto",
                    }}
                    className="my-2"
                  >
                    <Col span={18}>
                      <Flex align="center">
                        <div
                          className="p-2"
                          style={{
                            border: `1px dashed ${d?.color || "#e6332a"}`,
                            borderRadius: "8px",
                          }}
                        >
                          {d?.icon}
                        </div>
                        <div
                          style={{
                            color: "#575757",
                            textTransform: "capitalize",
                          }}
                          className="ms-4"
                        >
                          <Text strong>{d?.type}</Text>
                        </div>
                      </Flex>
                    </Col>
                    <Col span={6}>
                      <div
                      // className="fs-4"
                      // style={{
                      //   background: alert_list?.[d?.key_name]
                      //     ? "#e7e3e3"
                      //     : "",
                      //   padding: alert_list?.[d?.key_name] ? "4px 10px" : "",
                      //   borderRadius: alert_list?.[d?.key_name] ? "4px" : "",
                      // }}
                      >
                        <Text strong> {alert_list?.[d?.key_name] || 0}</Text>
                      </div>
                    </Col>
                  </Row>
                );
              })
            )}
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default RecommendationAlerts;
