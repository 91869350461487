import {
  Card,
  Checkbox,
  Col,
  Divider,
  Flex,
  Image,
  Input,
  message,
  Popover,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import {
  DefaultPerPage,
  DeleteIcon,
  PlusIcon,
} from "../../../../../../../../../../../config";
import Paragraph from "antd/es/typography/Paragraph";
import EnterList from "./enter-list";
import Suggested from "./suggested";
import Table2 from "../../../../../../../../../../../components/tables/table2";
import { MakeApiCall } from "../../../../../../../../../../../apis";
const { Text } = Typography;

const IndividualProductTargeting = (props) => {
  const {
    marketplaceId,
    selectedProductList,
    selectedManualProductList,
    setSelectedManualProductList,
    defaultSuggestedBid,
  } = props;
  const [selectedTab, setSelectedTab] = useState("suggested");
  const [selectedBidType, setSelectedBidType] = useState("suggested_bid");
  const [selectedBidValue, setSeletedBidValue] = useState(defaultSuggestedBid);
  const [selectedFilterBy, setSelectedFilterBy] = useState([
    "Exact",
    "Expanded",
  ]);
  const [productLoading, setProductLoading] = useState(false);
  const [suggestedProductList, setSuggestedProductList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
  });
  useEffect(() => {
    const asinsList = selectedProductList?.map((d) => {
      return d?.asin1;
    });
    const data = {
      marketplace_id: marketplaceId,
      asins: asinsList,
      ...all_filters,
    };
    GetProductTargeting(data);
  }, []);

  const GetProductTargeting = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-product-targeting-suggestions`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      setProductLoading(false);
      setTotalPage(response?.data?.pagination?.totalCount);
      setSuggestedProductList(response?.data?.records);
    } else {
      setProductLoading(false);
      setSuggestedProductList([]);
      message.destroy();
      message.error(response?.message);
    }
  };
  const items = [
    {
      key: "suggested",
      label: "Suggested",
    },
    {
      key: "enter_list",
      label: "Enter List",
    },
    {
      key: "upload",
      label: "Upload",
    },
  ];

  const columns = [
    {
      title: "ASIN",
      width: "250",
      render: (record) => {
        return (
          <div className="d-flex ">
            <div style={{ width: "45px" }}>
              <Image
                src={record?.image_url}
                style={{ width: "45px", objectFit: "contain" }}
                className="me-2"
              />
            </div>
            <div>
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {record?.product_name}
                  </div>
                }
                placement="bottom"
              >
                <div className="singleLineEllipsis" style={{ width: "200px" }}>
                  <Paragraph>{record?.product_name}</Paragraph>
                </div>
              </Popover>
              <div className="mt-1">
                <label>
                  ASIN:
                  <span className="text-primary">
                    {record?.recommendedAsin}
                  </span>
                </label>
              </div>
              <div className="mt-1">
                <label>
                  Price: <Text>{record?.item_price}</Text>
                </label>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Sugg. Bid",
      render: (record) => {
        return (
          <div>
            <div>2.0</div>
            {Object.entries(record?.exact || {})?.length > 0 && (
              <div className="my-2 d-flex justify-content-between align-items-center">
                <div className="me-4">
                  <Input
                    style={{ width: "100px" }}
                    className="me-2"
                    value={record?.exact?.suggested_bid}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedManualProductList((prev) => {
                        return prev?.map((a) => {
                          if (a?.recommendedAsin === record?.recommendedAsin) {
                            return {
                              ...record,
                              exact: {
                                ...record?.exact,
                                suggested_bid: value,
                              },
                            };
                          } else {
                            return a;
                          }
                        });
                      });
                    }}
                  />
                  <Text strong>Exact</Text>
                </div>
                <div
                  className="d-flex align-items-center"
                  onClick={() => {
                    const after_deleted_list = selectedManualProductList.map(
                      (item) => {
                        if (item.recommendedAsin === record?.recommendedAsin) {
                          const { exact, ...rest } = item;
                          return rest;
                        }
                        return item;
                      }
                    );
                    const filteredArray = after_deleted_list.filter(
                      (d) =>
                        d?.hasOwnProperty("exact") ||
                        d?.hasOwnProperty("expanded")
                    );
                    setSelectedManualProductList(filteredArray);
                  }}
                >
                  <DeleteIcon />
                </div>
              </div>
            )}

            {Object.entries(record?.expanded || {})?.length > 0 && (
              <div className="my-2 d-flex justify-content-between align-items-center">
                <div className="me-4">
                  <Input
                    style={{ width: "100px" }}
                    className="me-2"
                    value={record?.expanded?.suggested_bid}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedManualProductList((prev) => {
                        return prev?.map((a) => {
                          if (a?.recommendedAsin === record?.recommendedAsin) {
                            return {
                              ...record,
                              expanded: {
                                ...record?.expanded,
                                suggested_bid: value,
                              },
                            };
                          } else {
                            return a;
                          }
                        });
                      });
                    }}
                  />
                  <Text strong>Expanded</Text>
                </div>
                <div
                  className="d-flex align-items-center"
                  onClick={() => {
                    const after_deleted_list = selectedManualProductList.map(
                      (item) => {
                        if (item.recommendedAsin === record?.recommendedAsin) {
                          const { expanded, ...rest } = item;
                          return rest;
                        }
                        return item;
                      }
                    );
                    const filteredArray = after_deleted_list.filter(
                      (d) =>
                        d?.hasOwnProperty("exact") ||
                        d?.hasOwnProperty("expanded")
                    );
                    setSelectedManualProductList(filteredArray);
                  }}
                >
                  <DeleteIcon />
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: () => {
    //     return (
    //       <div>
    //         <div>
    //         </div>
    //         <div>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: () => {
    //     return (
    //       <div>
    //         <div>
    //         </div>
    //         <div>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "740px" }}
          title={
            <Title level={5} className="mb-0">
              Products
            </Title>
          }
        >
          <Row className="mb-4">
            <Col span={24}>
              <Tabs
                defaultActiveKey="suggested"
                items={items}
                onChange={(key) => {
                  setSelectedTab(key);
                }}
              />
            </Col>
          </Row>

          {selectedTab !== "upload" && (
            <>
              <Row align={"middle"}>
                <Col span={24}>
                  <Flex justify="start">
                    <Text strong>Bid</Text>
                    <Select
                      value={selectedBidType}
                      onChange={(e) => {
                        setSelectedBidType(e);
                      }}
                      className="mx-3"
                      style={{ width: "150px" }}
                      options={[
                        {
                          label: "Suggested Bid",
                          value: "suggested_bid",
                        },
                        {
                          label: "Custom Bid",
                          value: "custom_bid",
                        },
                        {
                          label: "Default Bid",
                          value: "default_bid",
                        },
                      ]}
                    />
                    {selectedBidType !== "suggested_bid" && (
                      <Input
                        style={{ width: "100px" }}
                        value={selectedBidValue}
                        onChange={(e) => {
                          setSeletedBidValue(e.target.value);
                        }}
                      />
                    )}
                  </Flex>
                </Col>
              </Row>
              <Row align={"middle"} className="my-3">
                <Col sm={6}>
                  <Text strong>Filter By</Text>
                </Col>
                <Col sm={18}>
                  <Checkbox.Group
                    options={["Exact", "Expanded"]}
                    value={selectedFilterBy}
                    onChange={(e) => {
                      e?.length !== 0 && setSelectedFilterBy(e);
                    }}
                  />
                </Col>
              </Row>
            </>
          )}

          {selectedTab === "suggested" && (
            <Suggested
              suggestedProductList={suggestedProductList}
              selectedFilterBy={selectedFilterBy}
              selectedBidType={selectedBidType}
              selectedBidValue={selectedBidValue}
              selectedManualProductList={selectedManualProductList}
              setSelectedManualProductList={setSelectedManualProductList}
              all_filters={all_filters}
              set_all_filters={set_all_filters}
              totalPage={totalPage}
              GetProductTargeting={GetProductTargeting}
              marketplaceId={marketplaceId}
              selectedProductList={selectedProductList}
            />
          )}
          {selectedTab === "enter_list" && <EnterList />}
        </Card>
      </Col>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "400px" }}
          title={
            <Title level={5} className="mb-0">
              Selected Products
            </Title>
          }
        >
          <Row>
            <Col span={24} style={{ overflowX: "auto", height: "635px" }}>
              <Table2
                dataSource={selectedManualProductList}
                columns={columns}
                pagination={false}
                scroll={{ x: "max-content" }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default IndividualProductTargeting;
