import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  message,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import {
  DefaultPerPage,
  DeleteIcon,
  PlusIcon,
} from "../../../../../../../../../../config";
import Table2 from "../../../../../../../../../../components/tables/table2";
import { MakeApiCall } from "../../../../../../../../../../apis";
const { Text } = Typography;
const CategoryTargeting = (props) => {
  const {
    marketplaceId,
    selectedProductList,
    defaultSuggestedBid,
    selectedManualCategoryList,
    setSelectedManualCategoryList,
  } = props;
  const [categoyLoading, setCategoryLoading] = useState(true);
  const [suggCategoryList, setSuggCategoryList] = useState([]);
  const [selectedBidType, setSelectedBidType] = useState("suggested_bid");
  const [selectedBidValue, setSeletedBidValue] = useState(defaultSuggestedBid);
  useEffect(() => {
    const asinsList = selectedProductList?.map((d) => {
      return d?.asin1;
    });
    const data = {
      marketplace_id: marketplaceId,
      asins: asinsList,
    };
    GetCategoryTargeting(data);
  }, []);

  const GetCategoryTargeting = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-categories-targeting-suggestions`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      setCategoryLoading(false);
      setSuggCategoryList(response?.data);
    } else {
      setCategoryLoading(false);
      setSuggCategoryList([]);
      message.destroy();
      message.error(response?.message);
    }
  };

  const columns = [
    {
      title: "Suggestion",
      dataIndex: "category_name",
      key: "category_name",
      width: 300,
    },
    {
      title: "Sugg. Bid",
      dataIndex: "suggested_bid",
      key: "suggested_bid",
      width: 100,
    },
    {
      title: "Action",
      width: 100,
      render: (record) => {
        const isAddCategory = selectedManualCategoryList?.filter(
          (f) => f?.category_id === record?.category_id
        );
        return isAddCategory?.length == 0 ? (
          <span
            onClick={() => {
              const newObj = {
                category_id: record?.category_id,
                suggested_bid: record?.suggested_bid,
                new_suggested_bid:
                  selectedBidType === "suggested_bid"
                    ? record?.suggested_bid
                    : selectedBidValue,
                category_name: record?.category_name,
              };

              setSelectedManualCategoryList((prev) => {
                return [...prev, newObj];
              });
            }}
          >
            <PlusIcon />
          </span>
        ) : (
          ""
        );
      },
    },
  ];

  const selectedCatColumns = [
    {
      title: "Suggestion",
      dataIndex: "category_name",
      key: "category_name",
      width: 300,
    },
    {
      title: "Sugg. Bid",
      align: "left",
      render: (record) => {
        return (
          <Flex vertical={true} justify="center">
            <Text strong>{record?.suggested_bid}</Text>
            <Input
              value={record?.new_suggested_bid}
              onChange={(e) => {
                setSelectedManualCategoryList((prev) => {
                  const existing_cat = prev?.find(
                    (es) => es?.category_id === record?.category_id
                  );
                  if (existing_cat) {
                    return prev.map((sc) =>
                      sc?.category_id === record?.category_id
                        ? {
                            ...sc,
                            new_suggested_bid: e.target.value,
                          }
                        : sc
                    );
                  } else {
                    return [...prev];
                  }
                });
              }}
              className="w-100px"
            />
          </Flex>
        );
      },
    },
    {
      title: "Action",
      key: "addAll",
      render: (_, record) => {
        return (
          <span
            onClick={() => {
              const afterDeletingCat = selectedManualCategoryList?.filter(
                (cl) => cl?.category_id !== record?.category_id
              );
              setSelectedManualCategoryList(afterDeletingCat);
            }}
          >
            <DeleteIcon />
          </span>
        );
      },
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "400px" }}
          title={
            <Title level={5} className="mb-0">
              All Category
            </Title>
          }
        >
          <Row className="mb-4">
            <Col span={24}>
              <div className="d-flex justify-content-start align-items-center">
                <Text strong>Bid</Text>
                <Select
                  style={{ width: "150px" }}
                  options={[
                    {
                      label: "Suggested Bid",
                      value: "suggested_bid",
                    },
                    {
                      label: "Custom Bid",
                      value: "custom_bid",
                    },
                    {
                      label: "Default Bid",
                      value: "default_bid",
                    },
                  ]}
                  className="mx-3"
                  value={selectedBidType}
                  onChange={(e) => {
                    setSelectedBidType(e);
                  }}
                />
                {selectedBidType !== "suggested_bid" && (
                  <Input
                    style={{ width: "100px" }}
                    value={selectedBidValue}
                    onChange={(e) => {
                      setSeletedBidValue(e.target.value);
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table2
                dataSource={suggCategoryList}
                columns={columns}
                pagination={false}
                loading={categoyLoading}
                scroll={{ y: 300 }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "490px" }}
          title={
            <Title level={5} className="mb-0">
              Selected Category
            </Title>
          }
          extra={
            <Button
              type="primary"
              danger
              onClick={() => {
                setSelectedManualCategoryList([]);
              }}
            >
              Remove All
            </Button>
          }
        >
          {" "}
          <Row>
            <Col span={24}>
              <Table2
                dataSource={selectedManualCategoryList}
                columns={selectedCatColumns}
                pagination={false}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default CategoryTargeting;
