import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Skeleton,
  TimePicker,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../../apis";
import { DateFormate, DeleteIcon, PlusIcon } from "../../../../../../config";
import CreateRuleSkeleton from "./create-rule-skeleton";
import {
  ruleCampaignDD,
  ruleKeywordDD,
  rulePortfolioDD,
} from "../../../../../../config/select-dropdown-list";
const { Text } = Typography;
const { Panel } = Collapse;
const CreateRuleModal = (props) => {
  const { visible, onCancel, isViewRule, campaign_list, dropdownList } = props;
  const [form] = Form.useForm();

  const [CollapseKey, setCollapseKeyChange] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  console.log(campaign_list, "-campaign_list");

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      name: "",
      rule_mode: null, // auto-run, manual
      schedule_type: null,
      schedule_day: null,
      schedule_day_name: null,
      schedule_week_days: null,
      campaign_list: [],
      sub_rules: [
        {
          rule_name: "",
          rule_status: "ACTIVE",
          rule_type: null,
          filtered_by: {},
          bid_change_type: null, // then section
          bid_value: "", // then section
          max_bid: "", // then section
          criteria: [{ attribute: null }],
          time_periods: {
            type: "date_range",
            start_date: dayjs(),
            end_date: dayjs().add(30, "d"),
            days: null,
            delay_days: null,
          },
        },
      ],
    });
    // GetAllCampaign();
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const newSubRules = values?.sub_rules?.map((d) => {
          return {
            ...d,
            time_periods: {
              type: d?.time_periods?.type,
              start_date: d?.time_periods?.start_date
                ? dayjs(d?.time_periods?.start_date).format("YYYY-MM-DD")
                : null,
              end_date: d?.time_periods?.end_date
                ? dayjs(d?.time_periods?.end_date).format("YYYY-MM-DD")
                : null,
              days: d?.time_periods?.days || null,
              delay_days: d?.time_periods?.delay_days || null,
            },
          };
        });
        const newJson = { ...values, sub_rules: newSubRules, filtered_by: {} };
        console.log(values, newJson, "-values");
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <Modal
      open={visible}
      title={"Create Rule"}
      maskClosable={false}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      width={700}
      footer={[
        <Button
          key="back"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
        >
          Cancel
        </Button>,
        !isViewRule && (
          <Button
            key="submit"
            type="primary"
            //   loading={loading}
            onClick={handleOk}
          >
            {"Submit"}
          </Button>
        ),
      ]}
    >
      {isListLoading ? (
        "loading"
      ) : (
        <Form layout="vertical" form={form}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const schedule_type = getFieldValue("schedule_type");
              const schedule_day_name = getFieldValue("schedule_day_name");
              const schedule_time = getFieldValue("schedule_time");
              return (
                <>
                  <Row className="mt-5" gutter={[16, 16]}>
                    <Col span={8}>
                      {/* <Text strong>Master Rule Name</Text> */}
                      <Form.Item
                        label="Master Rule Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the master rule name!",
                          },
                        ]}
                        className="mb-0"
                      >
                        <Input
                          placeholder="Enter master rule name"
                          className="w-100"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        label="Campaign"
                        name="campaign_list"
                        rules={[
                          {
                            required: true,
                            message: "Please seelct the campaign!",
                          },
                        ]}
                        className="mb-0"
                      >
                        <Select
                          className="w-100"
                          placeholder="Select campaign"
                          // disabled={campaignLoading}
                          // loading={campaignLoading}
                          options={campaign_list?.map((d) => {
                            return {
                              label: d?.campaign_name,
                              value: d?.campaign_id,
                              ...d,
                            };
                          })}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          mode="multiple"
                          maxTagCount={"responsive"}
                          // onChange={(e, campaigns) => {
                          //   console.log(campaigns, "-campaigns");
                          //   const getFieldVal = form.getFieldValue();
                          //   form.setFieldsValue({
                          //     ...getFieldVal,
                          //     campaign_list: e, // Assuming "campaign_list" is the name of your Select field
                          //   });
                          // }}
                          // dropdownRender={(menu) => (
                          //   <>
                          //     {menu}
                          //     <Divider className="my-2" />
                          //     <Button
                          //       onClick={() => {
                          //         const allValues = campaignList.map((d) => {
                          //           return {
                          //             label: d?.campaign_name,
                          //             value: d?.campaign_id,
                          //             ...d,
                          //           };
                          //         });
                          //         // Set the "campaign_list" field with all values selected
                          //         const getFieldVal = form.getFieldValue();
                          //         form.setFieldsValue({
                          //           ...getFieldVal,
                          //           campaign_list: allValues, // Assuming "campaign_list" is the name of your Select field
                          //         });
                          //       }}
                          //       type="primary"
                          //     >
                          //       Select All
                          //     </Button>
                          //   </>
                          // )}
                          // onBlur={(e) => {
                          //   const allData = form.getFieldValue();
                          //   // const selectedCampaign = allData?.campaign_list?.map(
                          //   //   (d) => {
                          //   //     return d?.campaign_id;
                          //   //   }
                          //   // );
                          //   setAdGroupLoading(true);
                          //   GetAllAdGroupList(allData?.campaign_list);
                          // }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Rule Mode"
                        name="rule_mode"
                        rules={[
                          {
                            required: true,
                            message: "Please select the rule mode!",
                          },
                        ]}
                        className="mb-0"
                      >
                        <Select
                          className="w-100"
                          placeholder="Select rule mode"
                          // disabled={campaignLoading}
                          // loading={campaignLoading}
                          options={[
                            { label: "Auto Run", value: "auto-run" },
                            { label: "Manual", value: "manual" },
                          ]}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className="mt-5">
                    <Col span={24}>
                      <Text strong>Schedule</Text>
                      <Divider className="mt-2 mb-0" />
                    </Col>
                    <Col span={8}>
                      {/* <Text strong>Run this rulebook</Text> */}
                      <Form.Item
                        label="Run this rulebook"
                        name="schedule_type"
                        rules={[
                          {
                            required: true,
                            message: "Please select the rulebook option!",
                          },
                        ]}
                        className="mb-0"
                      >
                        <Select
                          className="w-100"
                          placeholder="Select schedule type"
                          options={[
                            { label: "Daily", value: "daily" },
                            {
                              label: "On a custom schedule",
                              value: "custom_schedule",
                            },
                          ]}
                          // onChange={(e) => {
                          //   setScheduleType(e);
                          // }}
                        />
                      </Form.Item>
                    </Col>
                    {schedule_type === "custom_schedule" && (
                      <>
                        <Col span={4}>
                          {/* <Text strong>Every</Text> */}
                          <Form.Item
                            label="Every"
                            name="schedule_day"
                            rules={[
                              {
                                required: true,
                                message: "Please select the frequency!",
                              },
                            ]}
                            className="mb-0"
                          >
                            <Select
                              className="w-100"
                              options={[
                                { label: "1", value: "1" },
                                { label: "2", value: "2" },
                                { label: "3", value: "3" },
                                { label: "4", value: "4" },
                                { label: "5", value: "5" },
                                { label: "6", value: "6" },
                                { label: "7", value: "7" },
                                { label: "8", value: "8" },
                                { label: "9", value: "9" },
                                { label: "10", value: "10" },
                              ]}
                              placeholder="Seelct schedule day"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          {/* <Text strong>For</Text> */}
                          <Form.Item
                            label="Days/Week"
                            name="schedule_day_name"
                            rules={[
                              {
                                required: true,
                                message: "Please select the time!",
                              },
                            ]}
                            className="mb-0"
                          >
                            <Select
                              className="w-100"
                              options={[
                                { label: "Days", value: "days" },
                                { label: "Weeks", value: "weeks" },
                              ]}
                              placeholder="Select"
                            />
                          </Form.Item>
                        </Col>
                        {schedule_day_name === "weeks" && (
                          <Col span={6}>
                            <Form.Item
                              label="On"
                              name="schedule_week_days"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select week days!",
                                },
                              ]}
                              className="mb-0"
                            >
                              <Select
                                mode="multiple"
                                maxTagCount={"responsive"}
                                className="w-100"
                                options={[
                                  { label: "Sunday", value: "sunday" },
                                  { label: "Monday", value: "monday" },
                                  { label: "Tuesday", value: "tuesday" },
                                  { label: "Wednesday", value: "wednesday" },
                                  { label: "Thursday", value: "thursday" },
                                  { label: "Friday", value: "friday" },
                                  { label: "Saturday", value: "saturday" },
                                ]}
                                placeholder="Select week days"
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </>
                    )}
                    <Col span={6}>
                      {/* <Text strong>Run this rulebook</Text> */}
                      <Form.Item
                        label="Schedule Time"
                        name="schedule_time"
                        rules={[
                          {
                            required: true,
                            message: "Please select the schedule time !",
                          },
                        ]}
                        className="mb-0"
                      >
                        <TimePicker
                          className="w-100"
                          format={"HH:mm"}
                          value={schedule_time}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Divider className="mt-3 mb-5" />
                    <Col span={24}>
                      <Form.List name="sub_rules">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Row
                                gutter={[10, 10]}
                                style={{
                                  border: "1px dashed #c7c7c7",
                                  background: "#fbfbfb",
                                  position: "relative",
                                }}
                                className="p-4 mb-5"
                              >
                                <Col
                                  span={10}
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "-12px",
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    className="px-1"
                                    onClick={() => {
                                      add();
                                    }}
                                  >
                                    <PlusIcon color="#8282e9" />
                                  </Button>
                                  <Button
                                    type="dashed"
                                    className="px-1 ms-2"
                                    onClick={() => {
                                      remove(name);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Col>
                                <Col span={24}>
                                  <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                      {/* <Text strong>Master Rule Name</Text> */}
                                      <Form.Item
                                        {...restField}
                                        name={[name, "rule_name"]}
                                        label="Rule Name"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please enter the rule name!",
                                          },
                                        ]}
                                        className="mb-0"
                                      >
                                        <Input
                                          placeholder="Enter rule name"
                                          className="w-100"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "rule_type"]}
                                        label="Rule Type"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please select the rule type!",
                                          },
                                        ]}
                                        className="mb-0"
                                      >
                                        <Select
                                          className="w-100"
                                          placeholder="Select rule type"
                                          options={[
                                            {
                                              label: "Campaigns",
                                              value: "campaigns",
                                            },
                                            {
                                              label: "Portfolios",
                                              value: "portfolios",
                                            },
                                            {
                                              label: "Keywords",
                                              value: "keywords",
                                            },
                                            {
                                              label: "Ad Groups",
                                              value: "ad_gorups",
                                            },
                                            {
                                              label: "Targets",
                                              value: "targets",
                                            },
                                          ]}
                                          showSearch
                                          filterOption={(input, option) =>
                                            (option?.label ?? "")
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row className="mt-5">
                                    <Col span={24}>
                                      <Collapse
                                        activeKey={[CollapseKey]}
                                        accordion={true}
                                        onChange={(key) => {
                                          setCollapseKeyChange(key);
                                        }}
                                        size="small"
                                      >
                                        <Panel
                                          header={
                                            <>
                                              <h3 className="fs-4 fw-semibold mb-0 ms-4">
                                                Criteria
                                              </h3>
                                            </>
                                          }
                                          key={`criteria_${name}`}
                                        >
                                          <Form.Item className="mb-0">
                                            <Form.List
                                              name={[name, "criteria"]}
                                            >
                                              {(subfields, { add, remove }) => (
                                                <>
                                                  {subfields.map(
                                                    ({
                                                      key,
                                                      name,
                                                      ...restField
                                                    }) => (
                                                      <>
                                                        <Row
                                                          gutter={[10, 10]}
                                                          className="mb-5"
                                                        >
                                                          <Col span={2}>
                                                            <Text>If</Text>
                                                          </Col>
                                                          <Col span={5}>
                                                            <Form.Item
                                                              {...restField}
                                                              name={[
                                                                name,
                                                                "attribute",
                                                              ]}
                                                              label=""
                                                              rules={[
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "Please enter the attribute!",
                                                                },
                                                              ]}
                                                              className="mb-0"
                                                            >
                                                              <Select
                                                                className="w-100"
                                                                options={Object?.entries(
                                                                  dropdownList?.attributes_dd_data ||
                                                                    {}
                                                                )?.map(
                                                                  (
                                                                    [
                                                                      key,
                                                                      value,
                                                                    ],
                                                                    i
                                                                  ) => ({
                                                                    label:
                                                                      value,
                                                                    value: key,
                                                                  })
                                                                )}
                                                              />
                                                            </Form.Item>
                                                          </Col>
                                                          <Col span={5}>
                                                            <Form.Item
                                                              {...restField}
                                                              name={[
                                                                name,
                                                                "measurement_type",
                                                              ]}
                                                              label=""
                                                              rules={[
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "Please select measurement type!",
                                                                },
                                                              ]}
                                                              className="mb-0"
                                                            >
                                                              <Select
                                                                className="w-100"
                                                                options={[
                                                                  {
                                                                    label:
                                                                      "In total",
                                                                    value:
                                                                      "in_total",
                                                                  },
                                                                  {
                                                                    label:
                                                                      "On average per day",
                                                                    value:
                                                                      "aveg",
                                                                  },
                                                                ]}
                                                              />
                                                            </Form.Item>
                                                          </Col>
                                                          <Col span={3}>
                                                            <Form.Item
                                                              {...restField}
                                                              name={[
                                                                name,
                                                                "operator",
                                                              ]}
                                                              label=""
                                                              rules={[
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "Please select operator!",
                                                                },
                                                              ]}
                                                              className="mb-0"
                                                            >
                                                              <Select
                                                                className="w-100"
                                                                options={Object?.entries(
                                                                  dropdownList?.operators_dd_data ||
                                                                    {}
                                                                )?.map(
                                                                  (
                                                                    [
                                                                      key,
                                                                      value,
                                                                    ],
                                                                    i
                                                                  ) => ({
                                                                    label:
                                                                      value,
                                                                    value: key,
                                                                  })
                                                                )}
                                                              />
                                                            </Form.Item>
                                                          </Col>
                                                          <Col span={5}>
                                                            <Form.Item
                                                              {...restField}
                                                              name={[
                                                                name,
                                                                "value",
                                                              ]}
                                                              label=""
                                                              rules={[
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "Please Enter Value!",
                                                                },
                                                              ]}
                                                              className="mb-0"
                                                            >
                                                              <Input className="w-100" />
                                                            </Form.Item>
                                                          </Col>
                                                          <Col
                                                            span={4}
                                                            // style={{
                                                            //   position:
                                                            //     "absolute",
                                                            //   right: "5px",
                                                            //   top: "-12px",
                                                            // }}
                                                          >
                                                            <Button
                                                              type="dashed"
                                                              className="px-1"
                                                              onClick={() => {
                                                                add();
                                                              }}
                                                            >
                                                              <PlusIcon color="#8282e9" />
                                                            </Button>
                                                            <Button
                                                              type="dashed"
                                                              className="px-1 ms-2"
                                                              onClick={() => {
                                                                remove(name);
                                                              }}
                                                            >
                                                              <DeleteIcon />
                                                            </Button>
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            </Form.List>
                                          </Form.Item>
                                        </Panel>
                                      </Collapse>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col span={24}>
                                      <Collapse
                                        activeKey={[CollapseKey]}
                                        accordion={true}
                                        onChange={(key) => {
                                          setCollapseKeyChange(key);
                                        }}
                                        size="small"
                                      >
                                        <Panel
                                          header={
                                            <>
                                              <h3 className="fs-4 fw-semibold mb-0 ms-4">
                                                Action
                                              </h3>
                                            </>
                                          }
                                          key={`action_${name}`}
                                        >
                                          <Row gutter={[16, 16]}>
                                            <Col span={8}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "bid_change_type"]}
                                                label=""
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Please Action!",
                                                  },
                                                ]}
                                                className="mb-0"
                                              >
                                                <Select
                                                  className="w-100"
                                                  placeholder="Select select action"
                                                  options={
                                                    getFieldValue([
                                                      "sub_rules",
                                                      name,
                                                      "rule_type",
                                                    ]) === "campaigns"
                                                      ? ruleCampaignDD
                                                      : getFieldValue([
                                                          "sub_rules",
                                                          name,
                                                          "rule_type",
                                                        ]) === "portfolios"
                                                      ? rulePortfolioDD
                                                      : ruleKeywordDD
                                                  }
                                                  showSearch
                                                  filterOption={(
                                                    input,
                                                    option
                                                  ) =>
                                                    (option?.label ?? "")
                                                      .toLowerCase()
                                                      .includes(
                                                        input.toLowerCase()
                                                      )
                                                  }
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "bid_value"]}
                                                label=""
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Please enter value!",
                                                  },
                                                ]}
                                                className="mb-0"
                                              >
                                                <Input
                                                  className="w-100"
                                                  placeholder="Enter bid value"
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "max_bid"]}
                                                label=""
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Please enter max bid value!",
                                                  },
                                                ]}
                                                className="mb-0"
                                              >
                                                <Input
                                                  className="w-100"
                                                  placeholder="Enter max bid value"
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        </Panel>
                                      </Collapse>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col span={24}>
                                      <Collapse
                                        activeKey={[CollapseKey]}
                                        accordion={true}
                                        onChange={(key) => {
                                          setCollapseKeyChange(key);
                                        }}
                                        size="small"
                                      >
                                        <Panel
                                          header={
                                            <>
                                              <h3 className="fs-4 fw-semibold mb-0 ms-4">
                                                Time Periods
                                              </h3>
                                            </>
                                          }
                                          key={`time_period_${name}`}
                                        >
                                          <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                              <Form.Item
                                                {...restField}
                                                name={[
                                                  name,
                                                  "time_periods",
                                                  "type",
                                                ]}
                                                initialValue="date_range"
                                                label={
                                                  <Text strong>
                                                    Time Period Type
                                                  </Text>
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Time Period Type is required",
                                                  },
                                                ]}
                                              >
                                                <Radio.Group defaultValue="date_range">
                                                  <Radio value="date_range">
                                                    Date Range
                                                  </Radio>
                                                  <Radio value="lookback_period">
                                                    Lookback Period
                                                  </Radio>
                                                  <Radio value="month_to_date">
                                                    Month to Date
                                                  </Radio>
                                                  <Radio value="week_to_date">
                                                    Week to Date
                                                  </Radio>
                                                </Radio.Group>
                                              </Form.Item>

                                              {getFieldValue([
                                                "sub_rules",
                                                name,
                                                "time_periods",
                                                "type",
                                              ]) === "date_range" && (
                                                <Flex>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[
                                                      name,
                                                      "time_periods",
                                                      "start_date",
                                                    ]}
                                                    label="Start Date"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Start Date is required",
                                                      },
                                                    ]}
                                                    className="me-5"
                                                  >
                                                    <DatePicker
                                                      className="w-150px"
                                                      format={DateFormate}
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[
                                                      name,
                                                      "time_periods",
                                                      "end_date",
                                                    ]}
                                                    label="End Date"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "End Date is required",
                                                      },
                                                    ]}
                                                  >
                                                    <DatePicker
                                                      className="w-150px"
                                                      format={DateFormate}
                                                    />
                                                  </Form.Item>
                                                </Flex>
                                              )}

                                              {form.getFieldValue([
                                                "sub_rules",
                                                name,
                                                "time_periods",
                                                "type",
                                              ]) === "lookback_period" && (
                                                <Flex>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[
                                                      name,
                                                      "time_periods",
                                                      "days",
                                                    ]}
                                                    label="Days"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Days is required",
                                                      },
                                                    ]}
                                                  >
                                                    <Select
                                                      placeholder="Days"
                                                      style={{
                                                        width: "150px",
                                                        marginRight: "10px",
                                                      }}
                                                      options={Array(90)
                                                        .fill(null)
                                                        .map((d, i) => {
                                                          return {
                                                            label: i + 1,
                                                            value: i + 1,
                                                          };
                                                        })}
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[
                                                      name,
                                                      "time_periods",
                                                      "delay_days",
                                                    ]}
                                                    label="Delay Days"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Delay Days is required",
                                                      },
                                                    ]}
                                                  >
                                                    <Select
                                                      placeholder="Delay Days"
                                                      style={{
                                                        width: "150px",
                                                        marginRight: "10px",
                                                      }}
                                                      options={Array(90)
                                                        .fill(null)
                                                        .map((d, i) => {
                                                          return {
                                                            label: i + 1,
                                                            value: i + 1,
                                                          };
                                                        })}
                                                    />
                                                  </Form.Item>
                                                </Flex>
                                              )}
                                            </Col>
                                          </Row>
                                        </Panel>
                                      </Collapse>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default CreateRuleModal;
